
// サーバー側StreamedJSON::compactRowsで送信された情報をで展開する
export function decompactRows(rows) {
  if(rows.length === 0) {
    return []
  }
  const header = rows[0]
  if(!Array.isArray(header)) {
    return rows
  }
  const template = header.reduce((a, v) => ({...a, [v]: null}), {})
  return rows.slice(1).map(row => row.reduce((a, v, i) => (a[header[i]] = v, a), {...template}))
}
