
<template lang="pug">
div
  DatabaseEdit(
    module="size_templates"

    bulk-deletable
    filter-props="keyword"
    root="size_templates"
    :settings="settings"
    :model="model"

    csvImport csvExport
  )
    //- 詳細フォームの編集項目
    el-form-item(prop="keyword" label="キーワード")
      el-input(v-model="model.keyword" autofocus)

    el-form-item(prop="text" label="メーカーサイズ定型文")
      el-input(v-model="model.text" type="textarea" autosize)
</template>

<script>
import DatabaseEdit from 'lib/DatabaseEdit'

export default {
  components: {DatabaseEdit},

  data() {
    return {

      settings: {
        // 一覧テーブルのカラム
        columns: [{
          title: 'キーワード',
          data: 'keyword',
          width: 500,
        }, {
          title: 'メーカーサイズ定型文',
          data: 'text',
          width: 500,
        }],
      },

      // 詳細フォームのプロパティ
      model: {
        keyword: '',
        text: '',
      },
    }
  },

  mounted() {
    this.$store.commit('setTitle', 'マスター/メーカーサイズ定型文')
  },
}
</script>
