
import Axios from 'axios'

import './console-override'
import notify from './ui-notify'
// import store from '@/store'

// Laravelバリデーション違反応答ハンドラ
function onValidationFailDefault(e) {
  notify.error(Object.values(e))
}

// 例外ハンドラ
export async function handler(e, onValidationFail = onValidationFailDefault) {

  // nullの場合はスルー
  if(e == null) {
    return
  }

  // Quasarのdialogなどのキャンセルの場合はスルー
  if(e.name === 'Error' && e.message === '') {
    return
  }

  // axiosのキャンセルの場合はスルー
  if(Axios.isCancel(e)) {
    return
  }

  // axiosのエラーの場合
  if(e.name === 'Error' && e.request != null) {
    console.former.error(e) // eslint-disable-line no-console

    // 接続できない場合
    if(e.response == null) {
      notify.error('通信エラーが発生しました')
      return
    }

    // Laravelバリデーション違反応答の場合
    if(e.response.status === 422 && e.response.data != null) {
      if(e.response.data.errors) {
        onValidationFail(e.response.data.errors)
        return
      }
      if(e.response.data.message) {
        notify.error(e.response.data.message)
        return
      }
    }

    // それ以外のサーバーエラーコード
    // if(!store.getters['config/isProduction']) {
    //   notify.error('サーバーエラー status=' + e.response.status + ' content=' + JSON.stringify(e.response.data).substring(0, 1000))
    //   return
    // }

    notify.error('サーバーエラー' + e.response.status + 'が発生しました')
    return
  }

  // それ以外のエラー
  console.error(e) // eslint-disable-line no-console
}

// プラグイン
export const HandlerPlugin = {
  install(Vue, options) {
    Vue.prototype.$handler = handler
  },
}
