
<template lang="pug">
div
  DatabaseEdit(
    module="rules"

    bulk-deletable
    filter-props="name"
    root="rules"
    size="full"
    :settings="settings"
    :model="model"
    @change="onChange"
    @select-row="onSelectRow"
  )
    //- 詳細フォームの編集項目
    el-row
      el-form-item(prop="name" label="名前")
        el-input(v-model="model.name" autofocus)

    el-row
      el-form-item(prop="body" label="ルール")
        RulesTree(v-model="model.body.input")

    //- 説明文1
    el-row
      el-form-item(
        prop="description"
        label="説明文1"
      )
        .desc-btn
          AppTemplatePopover.full(
            :data="this.description_select1.options"
            prop="keyword"
            width="700"
            @select="row => {model.body.output.description = row.id}"
            @show="onShowDescriptionTemplate"
          )
            el-button.desc-btn1(slot="reference") {{this.description_select1.disp}}

          el-button.desc-btn2(
            icon="circle-cross"
            @click="() => {model.body.output.description = null}"
          )

    //- 説明文2
    el-row
      el-form-item(
        prop="description2"
        label="説明文2"
      )
        .desc-btn
          AppTemplatePopover.full(
            :data="this.description_select2.options"
            prop="keyword"
            width="700"
            @select="row => {model.body.output.description2 = row.id}"
            @show="onShowDescriptionTemplate"
          )
            el-button.desc-btn1(slot="reference") {{this.description_select2.disp}}

          el-button.desc-btn2(
            icon="circle-cross"
            @click="() => {model.body.output.description2 = null}"
          )

    //- 説明文3
    el-row
      el-form-item(
        prop="description3"
        label="説明文3"
      )
        .desc-btn
          AppTemplatePopover.full(
            :data="this.description_select3.options"
            prop="keyword"
            width="700"
            @select="row => {model.body.output.description3 = row.id}"
            @show="onShowDescriptionTemplate"
          )
            el-button.desc-btn1(slot="reference") {{this.description_select3.disp}}

          el-button.desc-btn2(
            icon="circle-cross"
            @click="() => {model.body.output.description3 = null}"
          )

    //- 説明文4
    el-row
      el-form-item(
        prop="description4"
        label="説明文4"
      )
        .desc-btn
          AppTemplatePopover.full(
            :data="this.description_select4.options"
            prop="keyword"
            width="700"
            @select="row => {model.body.output.description4 = row.id}"
            @show="onShowDescriptionTemplate"
          )
            el-button.desc-btn1(slot="reference") {{this.description_select4.disp}}

          el-button.desc-btn2(
            icon="circle-cross"
            @click="() => {model.body.output.description4 = null}"
          )

    //- 説明文5
    el-row
      el-form-item(
        prop="description5"
        label="説明文5"
      )
        .desc-btn
          AppTemplatePopover.full(
            :data="this.description_select5.options"
            prop="keyword"
            width="700"
            @select="row => {model.body.output.description5 = row.id}"
            @show="onShowDescriptionTemplate"
          )
            el-button.desc-btn1(slot="reference") {{this.description_select5.disp}}

          el-button.desc-btn2(
            icon="circle-cross"
            @click="() => {model.body.output.description5 = null}"
          )

    //- 説明文6 (注意文)
    el-row
      el-form-item(
        prop="description6"
        label="説明文6 (注意文)"
      )
        .desc-btn
          AppTemplatePopover.full(
            :data="this.description_select6.options"
            prop="keyword"
            width="700"
            @select="row => {model.body.output.description6 = row.id}"
            @show="onShowDescriptionTemplate"
          )
            el-button.desc-btn1(slot="reference") {{this.description_select6.disp}}

          el-button.desc-btn2(
            icon="circle-cross"
            @click="() => {model.body.output.description6 = null}"
          )

    //- カテゴリー
    el-row
      el-form-item(
        prop="category"
        label="カテゴリー"
      )
        el-select.full(
          v-model="model.body.output.category"
          multiple
          :multiple-limit="5"
          filterable
          placeholder="選択してください"
        )
          el-option(
            v-for="opt of this.categories"
            :key="opt.id"
            :value="opt.id"
            :label="opt.name"
          )

    //- 備考
    el-row
      el-form-item(prop="remarks" label="備考")
        el-input(v-model="model.body.output.remarks")
</template>

<script>
import {mapState, mapActions} from 'vuex'
import DatabaseEdit from 'lib/DatabaseEdit'
import RulesTree from './RulesTree.vue'
import ElInputLazy from 'lib/ElInputLazy'
import AppTemplatePopover from './AppTemplatePopover'
import {getRuleDescriptionTemplate} from 'lib/RuleDescription'

export default {
  components: {DatabaseEdit, RulesTree, ElInputLazy, AppTemplatePopover},

  data() {
    return {

      settings: {
        // 一覧テーブルのカラム
        columns: [{
          title: '名前',
          data: 'name',
          width: 500,
        }],
      },

      // 詳細フォームのプロパティ
      model: {
        name: '',
        body: {
          input: {
            type: 'and',
            children: [],
          },
          output: {
            description: null,
            description2: null,
            description3: null,
            description4: null,
            description5: null,
            description6: null,
            category: [],
            remarks: '',
          },
        },
      },
    }
  },

  computed: {
    ...mapState({
      categories:            state => state.categories.table,
      description_templates: state => state.description_templates.table,
    }),

    // 説明文
    description_selects() {
      const selects = [
        {prop: 'description',  label: '説明文1',          options: []},
        {prop: 'description2', label: '説明文2',          options: []},
        {prop: 'description3', label: '説明文3',          options: []},
        {prop: 'description4', label: '説明文4',          options: []},
        {prop: 'description5', label: '説明文5',          options: []},
        {prop: 'description6', label: '説明文6 (注意文)', options: []},
      ]

      for(const desc of this.description_templates) {
        selects[desc.type - 1]?.options?.push(desc)
      }

      return selects
    },

    description_select1() {
      return this.makeDescriptionDisp(this.description_selects[0])
    },

    description_select2() {
      return this.makeDescriptionDisp(this.description_selects[1])
    },

    description_select3() {
      return this.makeDescriptionDisp(this.description_selects[2])
    },

    description_select4() {
      return this.makeDescriptionDisp(this.description_selects[3])
    },

    description_select5() {
      return this.makeDescriptionDisp(this.description_selects[4])
    },

    description_select6() {
      return this.makeDescriptionDisp(this.description_selects[5])
    },
  },

  mounted() {
    this.$store.commit('setTitle', 'マスター/自動記入')
  },

  methods: {
    ...mapActions([
      'loadDescriptionTemplates',
      'loadRules',
    ]),

    makeDescriptionDisp(select) {
      let disp = '未設定'
      const id = this.model.body.output[select.prop]
      const row = select.options?.find(x => x.id === id)
      if(row != null) disp = row.text
      return {...select, disp}
    },

    // 説明定型文のリロード
    async onShowDescriptionTemplate() {
      await this.loadDescriptionTemplates()
    },

    //
    async onChange() {
      await this.loadRules()
    },

    async onSelectRow() {
      const descriptionTemplates = getRuleDescriptionTemplate(this.model.body.output, this.description_templates)

      for(const {prop} of this.description_selects) {
        this.model.body.output[prop] = null
      }

      for(const [prop, template] of Object.entries(descriptionTemplates)) {
        this.model.body.output[prop] = template.id
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.full
  width: 100%

.with-button
  margin-right: 10px
  vertical-align: top
  width: calc(100% - 45px)

.desc-btn
  align-items: flex-start
  display: flex
  width: 100%

.desc-btn1
  width: 100%
  white-space: normal
  text-align: left

.desc-btn2
  margin-left: 10px
</style>
