
<template lang="pug">
div.fit
  slot(v-if="show")
  NotFound(v-else)
</template>

<script>
import {get} from 'lodash-es'
import NotFound from '@/components/Notfound'

export default {
  components: {NotFound},

  computed: {
    show() {
      const role = get(this.$route, 'meta.role')
      return role == null || role.includes(this.$store.state.user.role)
    },
  },
}
</script>
