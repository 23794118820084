
<template lang="pug">
el-dialog(
  :visible.sync="visible"
  :title="title"
  :close-on-click-modal="false"
  @close="close"
)
  div(v-for="error of errors.slice(0,20)") {{error}}
  div(v-if="20 < errors.length") ...全{{ errors.length }}件のエラーがあります。

  .buttons(slot="footer")
    el-button(type="info" @click="visible = false") はい
</template>

<script>
export default {
  name: 'DialogImportError',

  data() {
    return {
      visible: true,
      title: '',
      errors: null,
    }
  },

  methods: {
    close() {
      this.$destroy();
      this.$el.parentNode.removeChild(this.$el);
    },
  },
}
</script>

<style>
.buttons {
  display: flex;
  justify-content: flex-end;
}
</style>
