
import {escape, isString} from 'lodash-es'
import { Message } from 'element-ui'

// 通知
// Element UI実装

const notify = (...args) => {
  const texts = []
  const opts = {}

  for(const arg of args) {
    if(isString(arg) || Array.isArray(arg)) {
      texts.push(arg)
    }
    else {
      Object.assign(opts, arg)
    }
  }

  if(0 < texts.length) {
    Object.assign(opts, {message: texts[0]})
  }

  if(opts.html && !opts.noEscape) {
    if(opts.message) {
      opts.message = (Array.isArray(opts.message) ? opts.message : opts.message.split("\n")).map(escape).join('<br>')
    }
  }

  Message(opts)
}

// Primary通知
notify.primary = (...args) => notify({type: 'success'}, ...args)

// Info通知
notify.info = (...args) => notify({type: 'info'}, ...args)

// Warning通知
notify.warning = (...args) => notify({type: 'warning'}, ...args)

// Error通知
notify.error = (...args) => notify({type: 'error'}, ...args)

//
export default notify

// プラグイン
export const NotifyPlugin = {
  install(Vue, options) {
    Vue.prototype.$notify = notify
  },
}
