
<template lang="pug">
.app-ng-store__container
  .app-ng-store__content(
    v-for="c of contents"
  )
    el-checkbox(
      :value="c.value"
      @change="onChange(c)"
    ) {{c.label}}
</template>

<script>
const $_templates = [
  {prop: 'R', label: 'R'},
  {prop: 'Y', label: 'Y'},
  {prop: 'F', label: 'F'},
  {prop: 'W', label: 'W'},
  {prop: 'A', label: 'A'},
]

export default {
  $_templates,

  props: {
    value: Object,
  },

  computed: {
    contents() {
      return $_templates.map(t => ({
          ...t,
          value: this.value?.[t.prop] ?? false,
      }))
    },
  },

  methods: {
    onChange(c) {
      const v = {}
      for(const t of $_templates) {
        v[t.prop] = t.prop === c.prop ? !c.value : this.value?.[t.prop] ?? false
      }
      this.$emit('input', v)
    },
  },
}
</script>

<style lang="sass">
.app-ng-store__container
  display: flex

.app-ng-store__content
  margin-right: 32px
</style>
