
<template lang="pug">
div
  .with-button
    slot

  el-button.no-focus.rotatable(
    icon="caret-bottom"
    size="small"
    :class="{active:open_}"
    @click="open_ = !open_"
  )

  el-collapse-transition
    .array(v-if="open_")
      el-button.no-focus(
        size="mini"
        type="text"
        disabled
        v-if="templates.length === 0"
      ) {{placeholder}}

      template(v-else)
        el-button.no-focus(
          v-for="(text, index) in templates"
          :key="index"
          size="mini"
          type="text"
          :disabled="disabled"
          @click="onClick(text)"
        ) {{text}}
</template>

<script>
export default {
  props: {
    open: Boolean,
    placeholder: {type: String, default: '項目がありません'},
    templates: Array,
    model: String,
    disabled: {default: false},
  },

  data() {
    return {
      open_: this.open,
    }
  },

  watch: {
    open(value) {this.open_ = value},
    open_(value) {this.$emit('update:open', value)},
  },

  methods: {
    onClick(text) {
      this.$emit('click', text)

      let value = this.model != null ? this.model : ''
      if(0 < value.length && value.slice(-1) !== ' ') {
        value += ' '
      }
      this.$emit('update:model', value + text)
    },
  },
}
</script>

<style lang="sass" scoped>
// 右端に展開ボタンを配置するためスロットの幅を狭める
.with-button
  display: inline-block
  margin-right: 10px
  width: calc(100% - 45px)

// 定型文配列表示ボタン
.rotatable :deep(i)
  transition: transform 0.3s ease-in-out
.active :deep(i)
  transform: rotate(180deg)

// 定型文配列
.array
  margin: 4px 0 0
  padding: 0
  line-height: 20px
  > .el-button
    margin-left: 0
    margin-right: 8px
</style>
