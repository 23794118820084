
<template lang="pug">
div
  ItemsList(
    ref="list"
    :unlisten="createFormVisible || editFormVisible"
    @selection="onUpdateSelection"
    @select='onSelectRow'
  )

  ItemsFormEdit(
    ref="formEdit"
    :visible.sync="editFormVisible"
    @change="onChangeItem"
  )
</template>

<script>
import ItemsList from './ItemsList'
import ItemsFormCreate from './ItemsFormCreate'
import ItemsFormEdit from './ItemsFormEditPhoto'

export default {
  components: {ItemsList, ItemsFormCreate, ItemsFormEdit},

  data() {
    return {
      selection: null,
      createFormVisible: false,
      editFormVisible: false,
    }
  },

  methods: {

    // テーブルの行の選択状態の更新
    onUpdateSelection(selection) {
      this.selection = selection
    },

    // 商品情報がダブルクリックされた時
    onSelectRow(item) {
      this.$refs.formEdit.open(item)
    },

    // 商品情報が更新された
    onChangeItem(item) {
      this.$refs.list.update(item)
    },
  },
}
</script>
