
<template lang="pug">
div
  DatabaseEdit(
    module="brands"

    bulk-deletable
    filter-props="name,code"
    root="brands"
    :settings="settings"
    :model="model"

    csvImport csvExport
  )
    el-form-item(prop="name" label="ブランド名")
      el-input(v-model.trim="model.name" autofocus)

    el-form-item(prop="code" label="ブランドコード")
      el-input(v-model.trim="model.code")

    el-form-item(prop="ng_store" label="出品NG店舗")
      AppNgStore.brands-full(v-model="model.ng_store")

    el-form-item(prop="short_name" label="商品属性ブランド名")
      el-input(v-model.trim="model.short_name")

    el-form-item(prop="link_html" label="リンクHTML")
      el-input(
        type="textarea"
        autosize
        v-model.trim="model.link_html"
      )
</template>

<script>
import DatabaseEdit from 'lib/DatabaseEdit'
import AppNgStore from './AppNgStore'

export default {
  components: {DatabaseEdit, AppNgStore},

  data() {
    return {

      settings: {
        // 一覧テーブルのカラム
        columns: [{
          title: 'ブランド名',
          data: 'name',
          width: 500,
        }, {
          title: 'ブランドコード',
          data: 'code',
          width: 300,
        }, {
          title: '出品NG店舗',
          data: row => AppNgStore.$_templates
            .filter(t => row.ng_store?.[t.prop])
            .map(t => t.label)
            .join(', '),
          width: 200,
        }, {
          title: '商品属性ブランド名',
          data: 'short_name',
          width: 300,
        }, {
          title: 'リンクHTML',
          data: 'link_html',
          width: 300,
        }],
      },

      // 詳細フォームのプロパティ
      model: {
        name: '',
        code: '',
        ng_store: null,
        short_name: '',
        link_html: '',
      },
    }
  },

  mounted() {
    this.$store.commit('setTitle', 'マスター/ブランド')
  },
}
</script>

<style lang="sass" scoped>
.brands-full
  width: 100%
</style>
