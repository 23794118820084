
export default {
  data() {
    return {
      editGuard_sign: null,
      editGuard_time: 0,
      editGuard_state: null,
    }
  },

  computed: {
    isEditGuardActive() {
      return this.editGuard_state !== null
    },

    editGuardUser() {
      return this.editGuard_state
    },
  },

  async beforeDestroy() {
    await this.terminateEditGuard()
  },

  methods: {
    async acquireEditGuard(sign, init_state = null) {
      await this.terminateEditGuard()
      this.editGuard_sign = sign
      this.editGuard_time = 0
      this.editGuard_state = init_state
      window.addEventListener('beforeunload', this.terminateEditGuard)
      await this.pollingEditGuard()
    },

    async pollingEditGuard() {
      const now = Date.now()
      if(10000 < (now - this.editGuard_time)) {
        if(this.editGuard_state === null) {
          try {
            const result = (await this.axiosPut('/api/edit_guard/' + this.editGuard_sign, null, {disableLoadingState: true}))?.data
            this.editGuard_time = now
            if(!result.own) {
              this.editGuard_state = result.user ?? ''
            }
          }
          catch(e) {}
        }
        else {
          try {
            const result = (await this.axiosGet('/api/edit_guard/' + this.editGuard_sign, {disableLoadingState: true}))?.data
            this.editGuard_time = now
            this.editGuard_state = result.user ?? false
          }
          catch(e) {}
        }
      }
      this.editGuard_timerId = setTimeout(this.pollingEditGuard, 1000)
    },

    async terminateEditGuard() {
      window.removeEventListener('beforeunload', this.terminateEditGuard)
      clearTimeout(this.editGuard_timerId)
      if(this.editGuard_sign !== null) {
        if(this.editGuard_state === null) {
          // await this.axiosDelete('/api/edit_guard/' + this.editGuard_sign)
          navigator.sendBeacon('/api/edit_guard/' + this.editGuard_sign + '/release')
        }
        this.editGuard_sign = null
      }
    },
  },
}
