
export function getRuleDescriptionTemplate(output, description_templates) {
  const result = {}

  const descriptionProps = ['description', 'description2', 'description3', 'description4', 'description5', 'description6']
  for(const prop of descriptionProps) {
    const id = output[prop]
    if(id == null) continue

    const template = description_templates.find(x => x.id === id)
    if(template == null) continue

    const propd = descriptionProps[template.type - 1]
    if(propd == null) continue

    result[propd] = template
  }

  return result
}
