
<template lang="pug">
div
  el-checkbox-group(
    v-model="status"
    size="small"
    @change="onChangeStatus"
  )
    el-checkbox-button(:label="false" :key="0") 未
    el-checkbox-button(:label="true" :key="1") 済

  el-date-picker(
    :disabled="!status[0]"
    v-model="from"
    size="small"
    placeholder="ここから"
    format="yy/MM/dd"
    :picker-options="fromOptions"
  )
  .arrow →
  el-date-picker(
    :disabled="!status[0]"
    v-model="to"
    size="small"
    placeholder="ここまで"
    format="yy/MM/dd"
    :picker-options="toOptions"
  )
</template>

<script>
export default {
  props: {
    value: Object,
  },

  data() {
    return this.setData(this.value, {

      fromOptions: {
        disabledDate: date => {
          return this.to && this.to < date
        },
      },

      toOptions: {
        disabledDate: date => {
          return this.from && date < this.from
        },
      },
    })
  },

  computed: {
    result() {
      return {
        status: this.status.length === 0 ? null : this.status[0],
        from: this.from || '',
        to: this.to || '',
      }
    },
  },

  watch: {
    value(value, oldval) {
      this.setData(value, this)
    },

    result(value, oldval) {
      if(JSON.stringify(value) !== JSON.stringify(oldval)) {
        this.$emit('input', value)
      }
    },
  },

  methods: {
    setData(value, data) {
      data.status = value.status == null ? [] : [value.status]
      data.from = value.from || ''
      data.to = value.to || ''
      return data
    },

    onChangeStatus(value, oldval) {
      this.status = value.splice(-1, 1)
    },

    filter(rows, prop, filter) {
      const new_rows = []
      if(filter.status === false) {
        for(const row of rows) {
          if(!row[prop]) {
            new_rows.push(row)
          }
        }
        return new_rows
      }
      if(filter.status === true) {
        const from = filter.from ? new Date(new Date(filter.from).setHours(0,0,0,0)).getTime() : -8640000000000000
        const to = filter.to ? new Date(new Date(filter.to).setHours(24,0,0,0)).getTime() : 8640000000000000
        for(const row of rows) {
          if(row[prop]) {
            const at = new Date(row[prop + '_at']).getTime()
            if(from <= at && at <= to) {
              new_rows.push(row)
            }
          }
        }
        return new_rows
      }
      return rows
    },
  },
}
</script>

<style lang="sass" scoped>
.el-checkbox-group
  display: inline-block
  margin-right: 5px
.el-date-editor
  width: 100px
  margin-right: 5px
.arrow
  display: inline-block
  margin-right: 5px
</style>
