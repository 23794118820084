
<template lang="pug">
el-dialog(
  size="full"
  :visible.sync="dialogVisible"
  :close-on-click-modal="false"
  :close-on-press-escape="false"
  :show-close="false"
  @close="$refs.form.clearValidation()"
)
  template(slot="title")
    .header__text マスター/設定

    el-button.no-focus.header__right(
      @click="dialogVisible = false"
    ) キャンセル

    el-button.no-focus.header__right(
      type="primary"
      icon="check"
      @click="onClickSubmit"
    ) 決定

    el-button.no-focus.header__right(
      @click="onClickHelp"
    ) ？

  el-form-ex(
    ref="form"
    :model="formData"
    @submit.prevent.native
  )
    .body__frame
      el-form-item(prop="tax_rate" label="消費税率")
        el-input(v-model.trim="formData.tax_rate" autofocus)

      el-form-item(prop="souryou_muryou_threshold" label="送料無料となる税抜価格")
        el-input(v-model.trim="formData.souryou_muryou_threshold")

      el-form-item(prop="small_keywords" label="小さいサイズ検出 キーワード (「小さいサイズ有り」をチェック) (|で区切る)")
        el-input(v-model="formData.small_keywords" type="textarea" autosize)

      el-form-item(prop="large_keywords" label="大きいサイズ検出 キーワード (〜cmまでご用意を挿入) (|で区切る)")
        el-input(v-model="formData.large_keywords" type="textarea" autosize)

      el-form-item(prop="large_keywords2" label="大きいサイズ検出 キーワード (「大きいサイズ有り」をチェック) (|で区切る)")
        el-input(v-model="formData.large_keywords2" type="textarea" autosize)

      el-form-item(prop="export_templates.name" label="商品名 テンプレート")
        el-input(v-model="formData.export_templates.name" type="textarea" autosize)

      el-form-item(prop="export_templates.pc_catchcopy" label="PC用キャッチコピー テンプレート")
        el-input(v-model="formData.export_templates.pc_catchcopy" type="textarea" autosize)

      el-form-item(prop="export_templates.mobile_catchcopy" label="モバイル用キャッチコピー テンプレート")
        el-input(v-model="formData.export_templates.mobile_catchcopy" type="textarea" autosize)

      el-form-item(prop="export_templates.pc_description" label="PC用商品説明文 テンプレート")
        el-input(v-model="formData.export_templates.pc_description" type="textarea" autosize)

      el-form-item(prop="export_templates.mobile_description" label="モバイル用商品説明文 テンプレート")
        el-input(v-model="formData.export_templates.mobile_description" type="textarea" autosize)

      el-form-item(prop="export_templates.smartphone_description" label="スマートフォン用商品説明文 テンプレート")
        el-input(v-model="formData.export_templates.smartphone_description" type="textarea" autosize)

      el-form-item(prop="export_templates.pc_sales" label="PC用販売説明文 テンプレート")
        el-input(v-model="formData.export_templates.pc_sales" type="textarea" autosize)

      el-form-item(prop="rakuten_api.service_secret" label="楽天RMS WEB API serviceSecret")
        el-input(v-model="formData.rakuten_api.service_secret")

      el-form-item(prop="rakuten_api.license_key" label="楽天RMS WEB API licenseKey")
        el-input(v-model="formData.rakuten_api.license_key" placeholder="更新しない場合は空欄のままにしてください")
</template>

<script>
import {cloneDeep, merge} from 'lodash-es'
import ElFormEx from 'lib/ElFormEx'
import DialogForm from 'lib/DialogForm'

export default {
  components: {ElFormEx, DialogForm},

  data() {
    return {
      dialogVisible: false,

      formData: {
        tax_rate: '',
        souryou_muryou_threshold: '',
        small_keywords: '',
        large_keywords: '',
        large_keywords2: '',
        export_templates: {
          name: '',
          pc_catchcopy: '',
          mobile_catchcopy: '',
          pc_description: '',
          mobile_description: '',
          smartphone_description: '',
          pc_sales: '',
        },
        rakuten_api: {
          service_secret: '',
          license_key: '',
        },
      },
    }
  },

  created() {
    this.initialFormData = cloneDeep(this.formData)
  },

  methods: {
    // ダイアログを開く
    open() {
      this.$store.dispatch('preferences/index')
      .then(response => {
        // TODO: ここ preferences.table へのアクセス記述が長すぎる ################
        this.formData = cloneDeep(merge({}, this.initialFormData, this.$store.state.preferences.table))
        this.dialogVisible = true
      })
      .catch(error => {
        if(error) {
          this.$alert(axiosErrMsg(error), {type: 'error'})
        }
      })
    },

    // 決定ボタンが押下された
    onClickSubmit() {
      this.$store.dispatch('preferences/update', this.formData)
      .then(() => {
        this.dialogVisible = false
      })
      .catch(error => {
        if(this.$refs.form.processLaravelError(error)) { // laravelのvalidatorエラーを処理
          this.$alert(axiosErrMsg(error), {type: 'error'})
        }
      })
    },

    //  ヘルプボタンが押下された
    onClickHelp() {
      window.open('/help/preferences')
    },
  },
}
</script>

<style lang="sass" scoped>
.el-dialog__wrapper :deep(.el-dialog__body)
  padding-top: 15px
.el-button
  margin-bottom: 5px

// ヘッダー部分タイトル/ボタンのレイアウト調整
.header__text
  float: left
  font-size: 120%
.header__right
  float: right !important
  margin-left: 10px

// ボディー部分フォームの枠
.body__frame
  border: solid 1px lightgray
  border-radius: 4px
  margin-bottom: 15px
  padding: 25px
  min-width: 522px
</style>
