
<template lang="pug">
div
  .app-table__status-container
    .app-table__status-group
      div
        template(v-if="data.length")
          template(v-if="settings.data.length < data.length")
            |全{{data.length}}件中の{{settings.data.length}}件
          template(v-else)
            |全{{data.length}}件
        template(v-else)
          |データがありません
        template(v-if="showArchived")
          <span class="archived"> (アーカイブ済)</span>

    .app-table__status-group
      el-input.app-table__status-search(
        :value="searchText"
        size="small"
        icon="circle-cross"
        :on-icon-click="() => searchText = ''"
        @blur="searchText = $event.target.value"
        @keyup.enter.native="searchText = $event.target.value"
      )
        template(slot="prepend") 検索

      el-button.no-focus.rotatable(
        icon="caret-bottom"
        size="small"
        :class="{'rotatable--active':search.visible}"
        @click="search.visible = !search.visible"
      ) 詳細検索

      el-dropdown.no-focus(
        size="small"
        split-button
        @click="refresh"
        @command="onButtonRefreshMaster"
      ) 更新
        el-dropdown-menu(slot="dropdown")
          el-dropdown-item.right(command="refresh-master") マスター更新

          el-dropdown-item.right(
            v-if="showArchived"
            command="hide-archived"
          ) 未アーカイブを表示

          el-dropdown-item.right(
            v-else
            command="show-archived"
          ) アーカイブ済を表示

          //- 診断機能: メニューアイテム
          //- el-dropdown-item.right(command="diagnostic") 診断

      slot

  el-form.search(
    v-if="search.visible"
    :model="search"
    label-width="100px"
    @submit.prevent.native
  )
    el-row
      el-col(:span="24")
        el-form-item(label="JANで検索")
          el-input(
            :value="searchTextJAN"
            size="small"
            icon="circle-cross"
            :on-icon-click="() => searchTextJAN = ''"
            @blur="searchTextJAN = $event.target.value"
            @keyup.enter.native="searchTextJAN = $event.target.value"
          )

    el-row
      el-col(:span="24" :md="12")
        el-form-item(label="入荷・検品")
          AppSearchProgress(v-model="search.checked_0")
      el-col(:span="24" :md="12")
        el-form-item(label="データ記入")
          AppSearchProgress(v-model="search.checked_1")
    el-row
      el-col(:span="24" :md="12")
        el-form-item(label="コメント記入")
          AppSearchProgress(v-model="search.checked_2")
      el-col(:span="24" :md="12")
        el-form-item(label="撮影")
          AppSearchProgress(v-model="search.checked_3")
    el-row
      el-col(:span="24" :md="12")
        el-form-item(label="CSV出力済")
          AppSearchProgress(v-model="search.exported")
      el-col(:span="24" :md="12")
        el-form-item(label="PDF出力済")
          AppSearchProgress(v-model="search.printed")

  HotRowsel(
    ref="hot"
    root="item"
    :settings="settings"
    window-height="auto"
    @select="onSelectRow"
  )

  //- 診断機能: ダイアログ
  el-dialog(title="診断ログの作成" :visible.sync="diagnosticDialogVisible")
    el-form
      ul
        li ご迷惑をおかけしております。問題の調査のためご協力ください
        li 問題が発生している商品番号、項目、気づいたことなど、なるべく詳細に説明欄に入力してください
        li 対比用に同時に問題が起こっていない環境でも説明欄にその旨記述した上で診断ログを送信してください
        li 正確な診断ログを取得するため、ログ送信作業の間は全体的に商品情報の更新をお控えください
      el-form-item(label="発生している問題の説明を入力してください(改行可)")
        el-input(type="textarea" autosize v-model="diagnosticInput")
    span(slot="footer")
      el-button(@click="diagnosticSubmit(false)") キャンセル
      el-button(type="primary" @click="diagnosticSubmit(true)") 送信

</template>

<script>
import {mapState} from 'vuex'
import * as Helpers from 'lib/Helpers'
import HotRowsel from 'lib/HotRowsel'
import AppSearchProgress from './AppSearchProgress'
import {decompactRows} from 'lib/decompact-rows'

export default {
  components: {HotRowsel, AppSearchProgress},

  props: {
    unlisten: Boolean,
  },

  data() {
    return {

      data: [],
      searchText: '',
      searchTextJAN: '',
      selection: null,

      search: {
        visible:   false,
        checked_0: {status: null, from: '', to: ''},
        checked_1: {status: null, from: '', to: ''},
        checked_2: {status: null, from: '', to: ''},
        checked_3: {status: null, from: '', to: ''},
        exported:  {status: null, from: '', to: ''},
        printed:   {status: null, from: '', to: ''},
      },

      // テーブル設定
      settings: {
        columns: [{
          title: '商品番号',
          width: 200,
          data: 'code',
        }, {
          title: '商品名',
          width: 200,
          data: 'name',
        }, {
          title: 'ブランド',
          width: 150,
          data: 'brand_id',
          renderer: (instance, td, row, col, prop, value, cellProperties) => {
            // 新式ブランド名の表示
            const text = this.brands.find(e => e.id === value)?.name
            if (text != null) {
              td.className = 'htNoWrap'
              td.innerHTML = text
            } else {
              td.className = 'htNoWrap items-list-grey'
              td.innerHTML = instance.getDataAtRowProp(row, 'brand')
            }
            return td
          },
        }, {
          title: '種別',
          width: 150,
          data: 'type_id',
          renderer: (instance, td, row, col, prop, value, cellProperties) => {
            td.className = 'htNoWrap'
            td.innerHTML = this.types.find(e => e.id === value)?.name ?? ''
            return td
          },

          // TODO: こうした方が良いかもしれない
          // renderer: function(instance, td, row, col, prop, value, cellProperties) {
          //   Handsontable.renderers.BaseRenderer.apply(this, arguments)
          //   const f = self.types.find(e => e.id === value)
          //   td.innerHTML = (f ? f.name : '')
          //   return td
          // },

        }, {
          title: '性別',
          width: 100,
          data: 'gender',
          renderer: (instance, td, row, col, prop, value, cellProperties) => {
            switch(value) {
              case 'ladies':
                td.innerHTML = 'レディース'
                break
              case 'mens':
                td.innerHTML = 'メンズ'
                break
              case 'uni':
                td.innerHTML = '両用'
                break
              default:
                td.innerHTML = ''
                break
            }
            return td
          },
        }, {
          title: '価格(税別)',
          width: 100,
          data: 'price',
          className: 'htRight',
        }, {
          title: '要確認',
          width: 60,
          data: 'annotation',
          renderer: (instance, td, row, col, prop, value, cellProperties) => {
            td.className = 'htNoWrap'
            td.innerHTML = value ? '○' : ''
            return td
          },
        }, {
          title: '入荷・検品',
          width: 100,
          data: 'checked_0',
          renderer: (instance, td, row, col, prop, value, cellProperties) => {
            td.className = 'htNoWrap'
            const e = this.settings.data[this.$refs.hot.table.toPhysicalRow(row)]
            td.innerHTML = e && e.checked_0 ? moment(e.checked_0_at).format('MM/DD') + ' ' + e.checked_0_by : ''
            return td
          },
        }, {
          title: 'データ記入',
          width: 100,
          data: 'checked_1',
          renderer: (instance, td, row, col, prop, value, cellProperties) => {
            td.className = 'htNoWrap'
            const e = this.settings.data[this.$refs.hot.table.toPhysicalRow(row)]
            td.innerHTML = e && e.checked_1 ? moment(e.checked_1_at).format('MM/DD') + ' ' + e.checked_1_by : ''
            return td
          },
        }, {
          title: 'コメント記入',
          width: 100,
          data: 'checked_2',
          renderer: (instance, td, row, col, prop, value, cellProperties) => {
            td.className = 'htNoWrap'
            const e = this.settings.data[this.$refs.hot.table.toPhysicalRow(row)]
            td.innerHTML = e && e.checked_2 ? moment(e.checked_2_at).format('MM/DD') + ' ' + e.checked_2_by : ''
            return td
          },
        }, {
          title: '撮影',
          width: 100,
          data: 'checked_3',
          renderer: (instance, td, row, col, prop, value, cellProperties) => {
            td.className = 'htNoWrap'
            const e = this.settings.data[this.$refs.hot.table.toPhysicalRow(row)]
            td.innerHTML = e && e.checked_3 ? moment(e.checked_3_at).format('MM/DD') + ' ' + e.checked_3_by : ''
            return td
          },
        }, {
          title: 'CSV出力済',
          width: 100,
          data: 'exported',
          renderer: (instance, td, row, col, prop, value, cellProperties) => {
            td.className = 'htNoWrap'
            const e = this.settings.data[this.$refs.hot.table.toPhysicalRow(row)]
            td.innerHTML = e && e.exported ? moment(e.exported_at).format('MM/DD') + ' ' + e.exported_by : ''
            return td
          },
        }, {
          title: 'PDF出力済',
          width: 100,
          data: 'printed',
          renderer: (instance, td, row, col, prop, value, cellProperties) => {
            td.className = 'htNoWrap'
            const e = this.settings.data[this.$refs.hot.table.toPhysicalRow(row)]
            td.innerHTML = e && e.printed ? moment(e.printed_at).format('MM/DD') + ' ' + e.printed_by : ''
            return td
          },
        }, {
          title: '画像有',
          width: 60,
          data: 'has_picture',
          renderer: (instance, td, row, col, prop, value, cellProperties) => {
            td.className = 'htNoWrap'
            td.innerHTML = value ? '○' : ''
            return td
          },
        }],
        manualColumnResize: true,
        outsideClickDeselects: false,
        persistentState: true,
        rowHeaders: true,
        undo: false,
        wordWrap: false,

        data: [],

        // ソートを有効にする
        columnSorting: true, // 使うとメモリリークを起こす
        sortIndicator: true, // columnSortingで使う
        beforeOnCellMouseDown(event, coords, TD, wt) {},

        // テーブルの選択状態の更新
        afterSelectionEnd: (r, c, r2, c2) => {
          this.selection = {begin: r, end: r2}
        },

        afterDeselect: () => {
          this.selection = null
        },
      },

      // 診断機能: ダイアログ
      diagnosticDialogVisible: false,
      diagnosticInput: '',
    }
  },

  computed: {
    ...mapState({
      types: state => state.types.table,
      brands: state => state.brands.table,
    }),

    filteredTable() {
      let rows = this.data

      const searchText = this.searchText.trim()
      if(searchText) {
        const brandMap = {}
        for (const brand of this.brands) {
          brandMap[brand.id] = brand.name
        }

        const result = []
        const needles = searchText.toLowerCase().split(' ').filter(Boolean)
        const props = ['search_0', 'search_1', 'search_2', 'search_3', 'code', 'jan', 'name']
        for (const row of rows) {
          const values = props.map(prop => (row[prop] ?? '').toLowerCase())
          values.push((brandMap[row.brand_id] ?? row.brand ?? '').toLowerCase())
          if (needles.every(needle => values.some(value => value.includes(needle)))) {
            result.push(row)
          }
        }

        rows = result
      }

      const searchTextJAN = this.searchTextJAN.trim()
      if(searchTextJAN) {
        const result = []
        const needles = searchTextJAN.split(' ').filter(Boolean)
        next_row:
        for(const row of rows) {
          if(row.jan) {
            const haystacks = row.jan.split(',').filter(Boolean)
            for(const haystack of haystacks) {
              for(let i = 0; i < needles.length; ++i) {
                if(needles[i] == haystack) {
                  needles.splice(i, 1)
                  result.push(row)
                  continue next_row
                }
              }
            }
          }
        }
        rows = result
      }

      rows = AppSearchProgress.methods.filter(rows, 'checked_0', this.search.checked_0)
      rows = AppSearchProgress.methods.filter(rows, 'checked_1', this.search.checked_1)
      rows = AppSearchProgress.methods.filter(rows, 'checked_2', this.search.checked_2)
      rows = AppSearchProgress.methods.filter(rows, 'checked_3', this.search.checked_3)
      rows = AppSearchProgress.methods.filter(rows, 'exported',  this.search.exported)
      rows = AppSearchProgress.methods.filter(rows, 'printed',   this.search.printed)

      return rows
    },

    selectionIds() {
      if(this.selection == null) {
        return null
      }

      const ids = []
      const begin = Math.min(this.selection.begin, this.selection.end)
      const end = Math.max(this.selection.begin, this.selection.end) + 1

      const hot_table = this.$refs.hot.table
      const settings_data = this.settings.data

      for(let i = begin; i < end; ++i) {

        const physicalRowIndex = hot_table.toPhysicalRow(i)
        const row = settings_data[physicalRowIndex]

        if(row) {
          ids.push(row.id)
        }
      }

      return ids
    },

    showArchived() {
      return this.$route.query.a == true
    },
  },

  watch: {
    unlisten(value) {
      if(value) {
        this.$refs.hot.table.unlisten()
      }
      else {
        this.$refs.hot.table.listen()
      }
    },

    filteredTable(value) {
      // this.$refs.hot.table.deselectCell()
      this.$set(this.settings, 'data', value)
    },

    selectionIds(value) {
      this.$emit('selection', value)
    },

    // 詳細検索ウィンドウの表示/非表示が発生した場合は
    // レイアウト変更をhandsontableに伝達する必要がある
    'search.visible'() {
      this.$nextTick(() => {
        window.dispatchEvent(new Event('resize'))
      })
    },
  },

  mounted() {
    this.axiosAutoCancel()
    // mounted直後は子コンポーネントがマウントされていない可能性があるので1tick待つ
    this.$nextTick(() => {
      // テーブルを更新する
      this.refresh()
    })
  },

  methods: {

    // visual row index から data 要素を取得
    getData(visualRowIndex) {
      const physicalRowIndex = this.$refs.hot.table.toPhysicalRow(visualRowIndex)
      return this.settings.data[physicalRowIndex]
    },

    // data id から data index を取得してコールバック
    findDataIndex(dataId, callback) {
      for(const [index, data] of Object.entries(this.data)) {
        if(dataId === data.id) {
          callback(index)
          break
        }
      }
    },

    onButtonRefreshMaster(command) {
      switch(command) {

        // マスター更新ボタン押下
        case 'refresh-master':
          this.$store.dispatch('loadFirst')
          .catch(error => {
            if(error) { // AxiosVue: nullの場合は処理不要
              this.$alert(axiosErrMsg(error), {type: 'error'})
            }
          })
          break

        // アーカイブ済を隠す
        case 'hide-archived': {
          this.$router.replace({query: {}})
          break
        }

        // アーカイブ済を表示
        case 'show-archived': {
          this.$router.replace({query: {a: 1}})
          break
        }

        // 診断機能
        case 'diagnostic':
          this.diagnosticDialogVisible = true
          break
      }
    },

    // 診断機能
    diagnosticSubmit(submit) {
      this.diagnosticDialogVisible = false
      if(submit) {
        this.$confirm('送信します。よろしいですか？', '診断ログの送信', {type: 'warning'})
        .then(() => {
          this.axiosPost('/api/diagnostic', {
            diagnosticInput: this.diagnosticInput,
            selectionIds:    this.selectionIds,
            searchText:      this.searchText,
            searchTextJAN:   this.searchTextJAN,
            search:          this.search,
            data:            this.data,
          })
          .then(({data}) => {
            this.$alert('送信が完了しました。診断情報をもとに問題の解決の努力を進めてまいります。ご迷惑をおかけしております。', '診断ログの送信', {type: 'success'})
            this.diagnosticInput = ''
          })
          .catch(error => {
            if(error) { // AxiosVue: nullの場合は処理不要
              this.$alert(axiosErrMsg(error), {type: 'error'})
            }
          })
        })
        .catch(() => {})
      }
    },

    // テーブルを更新する
    refresh() {
      this.axiosGet(this.showArchived ? '/api/items?a=1' : '/api/items')
      .then(({data}) => {
        this.data = decompactRows(data)
        // データのロード後、カーソルのセットは1tick待つ必要がある
        this.$nextTick(() => {
          this.$refs.hot.table.selectCell(0, 0)
        })
      })
      .catch(error => {
        if(error) { // AxiosVue: nullの場合は処理不要
          this.$alert(axiosErrMsg(error), {type: 'error'})
        }
      })
    },

    // テーブルの行が選択された時
    onSelectRow(visibleRowIndex) {
      const data = this.getData(visibleRowIndex)

      // TODO: ⭐️⭐️⭐️⭐️ 問題検出したい
      if(data == null) {
        // const physicalRowIndex = this.$refs.hot.table.toPhysicalRow(visualRowIndex)
        // console.error('ItemsList: 問題検出', visualRowIndex, physicalRowIndex)
        return
      }
      // TODO: ⭐️⭐️⭐️⭐️ 問題検出したい

      this.$emit('select', data)
    },

    // テーブルの行が削除された
    delete(id) {
      this.$refs.hot.table.deselectCell()
      this.findDataIndex(id, index => {
        this.data.splice(index, 1)
      })
    },

    // テーブルの行が更新された
    update(data) {
      this.findDataIndex(data.id, index => {
        this.data.splice(index, 1, data)
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.app-table__status-container
  padding: 0 8px

// ボタンアイコンの回転
.rotatable :deep(i)
  transition: all 0.3s ease-in-out
.rotatable--active :deep(i)
  transform: rotate(180deg)

// 詳細検索
.search
  padding: 8px 10px
  border-radius: 8px
  background-color: rgb(243, 243, 243)
.el-form-item
  margin-bottom: 5px

.archived
  color: red
  font-weight: bold
</style>

<style lang="sass">
// 旧式ブランド名をグレー表示
.items-list-grey
  color: #aaa
</style>
