
<template lang="pug">
div(v-if="limit < length" :class="{'app-display-bytes-red': limit < length}") {{ length }}バイト
</template>

<script>
export default {
  props: {
    value: Object,
    limit: {type: Number, default: 32},
  },

  computed: {
    text() {
      return this.value.name + '(' + this.normalizeCode(this.value.code) + ')'
    },

    length() {
      return getStringBytes(this.text)
    },
  },

  methods: {
    normalizeCode(code) {
      return code.replace(/^\s*-([^\s]*).*$/, '$1')
    },
  },
}

function getStringBytes(text) {
  let length = 0
  for (let i = 0; i < text.length; i++) {
    const c = text.charCodeAt(i)
    length += (c >= 0x0 && c < 0x81) || (c === 0xf8f0) || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4) ? 1 : 2
  }
  return length;
}
</script>

<style lang="sass">
.app-display-bytes-red
  color: red
</style>
