
<template lang="pug">
div(
  :id="root"
  :style="HotBasic_style"
)
</template>

<script>
import HotTable from './handsontable/vue-handsontable-official/HotTable'

export default {
  name: 'HotBasic',
  extends: HotTable,

  props: {
    windowHeight: String,
  },

  data() {
    return {
      HotBasic_style: {},
    }
  },

  created() {
    // settingsのdataが未定義なら[]を入れておく
    // 未定義だとデータをロードする前に5行程度の空のテーブルが表示されてしまう
    if(this.settings.data === undefined) {
      this.$set(this.settings, 'data', [])
    }

    // handsontable 0.34.4時点でpersistentStateでソートなし状態を正しく復元できない問題の修正
    if(this.settings.persistentState && this.settings.persistentStateLoad === undefined) {
      this.$set(this.settings, 'persistentStateLoad', function(key, valuePlaceholder) {
        if(valuePlaceholder.value !== undefined && valuePlaceholder.value.sortOrder === undefined) {
          valuePlaceholder.value.sortColumn = undefined
        }
      })
    }

    // window-height属性がautoなら自動ウインドウリサイズを行う
    // handsontableは初期化後のスタイル変更には対応していないようなので、
    // 初期化後の動的なスタイル変更の取り扱いには注意を要する。
    if(this.windowHeight) {
      this.HotBasic_style = {
        overflow: 'hidden',
        height: this.windowHeight,
      }
    }
  },

  mounted() {
    this.$watch('windowHeight', function(value) {
      this.HotBasic_style = value ? {
        overflow: 'hidden',
        height: value,
      } : {}

      if(value === 'auto') {
        window.addEventListener('resize', this.$_HotBasic_handleWindowResize)
        this.$_HotBasic_handleWindowResize()
      }
      else {
        window.removeEventListener('resize', this.$_HotBasic_handleWindowResize)
        this.$nextTick(function() {
          this.table.render()
        })
      }
    }, {immediate: true})
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.$_HotBasic_handleWindowResize)
  },

  methods: {
    $_HotBasic_handleWindowResize() {
      this.HotBasic_style.height = (window.innerHeight - this.$el.getBoundingClientRect().top) + 'px'
      this.$nextTick(function() {
        this.table.render()
      })
    },
  },
}
</script>
