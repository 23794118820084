
export function filter(haystack, needle, properties) {
  const result = []
  needle = needle.toLowerCase().split(' ').filter(Boolean)
  loop_haystack:
  for(const haystack_0 of haystack) {
    const haystack_1 = properties.map(property_t => {
      return (haystack_0[property_t] + '').toLowerCase()
    })
    loop_needle:
    for(const needle_t of needle) {
      for(const haystack_t of haystack_1) {
        if(haystack_t.indexOf(needle_t) !== -1) {
          continue loop_needle
        }
      }
      continue loop_haystack
    }
    result.push(haystack_0)
  }
  return result
}

export function postJsonAndOpen(action, data) {
  const form_element = document.createElement('form')
  form_element.method = 'POST'
  form_element.action = action
  form_element.target = '_blank'

  const csrf_token = document.head.querySelector('meta[name="csrf-token"]')
  if(csrf_token) {
    const csrf_element = document.createElement('input')
    csrf_element.type = 'hidden'
    csrf_element.name = '_token'
    csrf_element.value = csrf_token.content
    form_element.appendChild(csrf_element)
  }

  const json_element = document.createElement('input')
  json_element.type = 'hidden'
  json_element.name = 'json'
  json_element.value = JSON.stringify(data)
  form_element.appendChild(json_element)

  document.body.appendChild(form_element)
  form_element.submit()
  document.body.removeChild(form_element)
}
