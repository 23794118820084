
<template lang="pug">
div
  .app-table__status-container
    .app-table__status-group
      div
        template(v-if="table.length")
          template(v-if="settings.data.length < table.length")
            |全{{table.length}}件中の{{settings.data.length}}件
          template(v-else)
            |全{{table.length}}件
          | &nbsp;/&nbsp;最終更新 {{lastModified}}
        template(v-else)
          |データがありません

    //- 最終APIステータスと最終APIステータス更新日時の取り扱い
    .app-table__status-group
      div {{rakudirs_status}}
        template(v-if="rakudirs_status_updated_at")
          | ({{rakudirs_status_updated_at}})

    .app-table__status-group
      el-input.rakudir-table__status-search(
        :value="filterText"
        size="small"
        icon="circle-cross"
        :on-icon-click="() => filterText = ''"
        @blur="filterText = $event.target.value"
        @keyup.enter.native="filterText = $event.target.value"
      )
        template(slot="prepend") 検索

      el-button.no-focus(
        size="small"
        @click="refresh"
      ) 更新

      el-button.no-focus(
        size="small"
        type="primary"
        @click="gather"
      ) 収集

      el-button.no-focus(
        size="small"
        type="warning"
        @click="check"
      ) テスト

      el-button.no-focus(
        size="small"
        type="danger"
        @click="invalidate"
      ) 無効化

  HotRowsel(
    ref="hot"
    root="rakudir"
    :settings="settings"
    window-height="auto"
  )
</template>

<script>
import * as Helpers from 'lib/Helpers'
import HotRowsel from 'lib/HotRowsel'

export default {
  components: {HotRowsel},

  data() {
    return {
      lastModified: null,

      // 最終APIステータスと最終APIステータス更新日時の取り扱い
      rakudirs_status: null,
      rakudirs_status_updated_at: null,

      filterText: '',

      settings: {
        data: [],

        manualColumnResize: true,
        outsideClickDeselects: false,
        persistentState: true,
        rowHeaders: true,
        rowHeaderWidth: 80,
        undo: false,
        wordWrap: false,

        // columnSorting: true, // 使うとメモリリークを起こす
        // sortIndicator: true, // columnSortingで使う

        columns: [{
          data: 'code',
          className: 'htRight',
          title: 'ジャンルID',
          width: 150,
        }, {
          data: 'path',
          title: 'パス',
          width: 850,
          renderer(instance, td, row, col, prop, value, cellProperties) {
            td.className = 'htNoWrap'
            td.innerHTML = value ? value.replace(/,/g, ' >> ') : ''
            return td
          },
        }],
      },
    }
  },

  computed: {
    table() {
      return this.$store.state.rakudir.table
    },

    filteredTable() {
      let table = this.table
      const filterText = this.filterText.trim()
      if(filterText) {
        table = Helpers.filter(table, filterText, ['code', 'path'])
      }
      return table
    },
  },

  watch: {
    filteredTable(value) {
      this.settings.data = value
    },
  },

  // 初期化処理
  mounted() {
    this.$store.commit('setTitle', 'マスター/楽天ジャンル')
    // テーブルを更新する
    this.$nextTick(function() {
      this.refresh()
    })
  },

  methods: {
    // テーブルを更新する
    refresh() {
      this.$store.dispatch('rakudir/index')
      .then(response => {
        this.lastModified = moment(Date.parse(response.headers['last-modified'])).format('lll')

        // 最終APIステータスと最終APIステータス更新日時の取り扱い
        switch (response.headers['x-rakudirs-status']) {
          case 'processing':
            this.rakudirs_status = '収集中 ' + response.headers['x-rakudirs-status-progress']
            break

          case 'success':
            this.rakudirs_status = '収集成功'
            break

          case 'fail':
            this.rakudirs_status = '収集エラー'
            break

          default:
            this.rakudirs_status = '不明'
            break
        }
        this.rakudirs_status_updated_at = moment(Date.parse(response.headers['x-rakudirs-status-updated-at'])).format('lll')

      })
      .catch(error => {
        if(error) { // AxiosVue: nullの場合は処理不要
          this.$alert(axiosErrMsg(error), {type: 'error'})
        }
      })
    },

    // 楽天サイトからデータを収集する
    gather() {
      this.$msgbox({
        title: 'データ収集',
        type: 'warning',
        message: '楽天 RMS Web API / Navigation API 2.0 からジャンル情報を取得します。処理には数時間かかることがあります。続けますか？',
        showCancelButton: true,
      })
      .then(() => {
        axios.get('/api/rakudir/gather')
        .then(response => {
          this.refresh()
        })
        .catch(error => {
          if(error) { // AxiosVue: nullの場合は処理不要
            this.$alert(axiosErrMsg(error), {type: 'error'})
          }
        })
      })
      .catch(() => {})
    },

    // 接続テスト
    check() {
      axios.get('/api/rakudir/check')
      .then(response => {
        const lines = response.data.split('\n')
        const h = this.$createElement
        this.$msgbox({
          title: 'テスト結果',
          message: h('div', lines.map((t) => h('div', t))),
        })
      })
      .catch(error => {
        if(error) { // AxiosVue: nullの場合は処理不要
          this.$alert(axiosErrMsg(error), {type: 'error'})
        }
      })
    },

    // データ無効化
    invalidate() {
      this.$msgbox({
        title: 'データ無効化',
        type: 'warning',
        message: '次回の収集で楽天側の更新がなくとも、確実に全てのデータを再取得します。続けますか？',
        showCancelButton: true,
      })
      .then(() => {
        axios.get('/api/rakudir/invalidate')
        .then(response => {
          this.$msgbox({
            title: 'データ無効化',
            message: response.data,
          })
        })
        .catch(error => {
          if(error) { // AxiosVue: nullの場合は処理不要
            this.$alert(axiosErrMsg(error), {type: 'error'})
          }
        })
      })
      .catch(() => {})
    },
  },
}
</script>

<style lang="sass" scoped>
.app-table__status-container
  padding: 0 8px

.rakudir-table__status-search
  width: 200px !important
</style>
