
const axios = require('axios')

export default function() {
  const instance = axios.create()
  instance.CancelToken = axios.CancelToken
  instance.isCancel = axios.isCancel

  instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

  // const token = document.head.querySelector('meta[name="csrf-token"]')
  // if(token) {
  //   instance.defaults.headers.common['X-CSRF-TOKEN'] = token.content
  // } else {
  //   console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
  // }

  return instance
}
