
<template lang="pug">
div
  Preferences(ref="preferences")

  div(v-if="firstLoading")
    .splash

  template(v-else)
    .header(ref="navi")
      el-menu(
        mode="horizontal"
        :theme="theme"
        :default-active="defaultActive"
        @select="onSelect"
      )
        li.el-menu-item.title {{title}}

        el-submenu.right(index="session")
          template(slot="title") {{$store.state.user.name}}
          el-menu-item(
            v-for="route of routes"
            :index="route.name"
            :key="route.name"
          ) {{route.meta.title}}

          el-menu-item(
            v-if="isAdmin"
            index="preferences"
          ) マスター/設定

          //- el-menu-item(
          //-   v-if="isAdmin"
          //-   index="errorTest"
          //- ) エラーテスト

          el-menu-item(index="logout") ログアウト

        li.el-menu-disp.right(:style="versionStyle") version {{version}}

    .content(:style="contentStyle")
      RoleGuard
        router-view(:key="$route.fullPath")
</template>

<script>
import {mapState}  from 'vuex'
import {Loading}   from 'element-ui'
import RoleGuard   from 'components/RoleGuard'
import Preferences from 'components/Preferences'

export default {
  components: {RoleGuard, Preferences},

  data() {
    return {
      firstLoading: true,
      defaultActive: null,

      contentStyle: {
        paddingTop: '70px',
      },
    }
  },

  computed: {
    isAdmin() {
      return this.$store.state.user.role === 'admin'
    },

    theme() {
      return this.isAdmin ? 'dark' : null
    },

    routes() {
      return this.$router.options.routes
      .filter(route => {
        const meta = route.meta
        if(route.meta != null && meta.title != null) {
          const role = meta.role
          return role == null || role.includes(this.$store.state.user.role)
        }
        return false
      })
      .sort((a, b) => a.meta.order - b.meta.order)
    },

    version() {
      return this.$store.state.revision || 'NA'
    },

    versionStyle() {
      if(this.version.indexOf('/') !== -1) {
        return {color: 'red'}
      }
      return {}
    },

    ...mapState(['loading', 'loadingText', 'title']),
  },

  watch: {
    // ローディング表示
    loading(value) {
      if(value) {
        if(this.$_loadingInstance == null) {
          this.$_loadingInstance = Loading.service({
            fullscreen: true,
            text: this.loadingText != null ? this.loadingText :'ネットワークにアクセスしています...',
          })
        }
      }
      else {
        if(this.$_loadingInstance != null) {
          this.$_loadingInstance.close()
          this.$_loadingInstance = null
        }
      }
    },
  },

  mounted() {
    this.loadFirst()

    // el-menuのアクティブを設定する
    this.defaultActive = this.$route.name
  },

  beforeDestroy() {
    // app-header-contentの上マージンをヘapp-navi高さに追従させる
    window.removeEventListener('resize', this.handleWindowResize)
  },

  methods: {
    // アプリケーショングローバル情報の取得
    loadFirst() {

      this.$store.dispatch('loadFirst')
      .then(response => {
        this.firstLoading = false

        // app-header-contentの上マージンをヘapp-navi高さに追従させる
        this.$nextTick(function() {
          window.addEventListener('resize', this.handleWindowResize)
          this.handleWindowResize()
        })
      })
      .catch(error => {
        if(error) { // AxiosVue: nullの場合は処理不要
          this.$alert(axiosErrMsg(error), '初期化情報の読み込み', {
            type: 'error',
            showClose: false,
            confirmButtonText: 'リトライ',
          })
          .then(() => {
            this.loadFirst()
          })
        }
      })
    },

    // ヘッダメニューの選択
    onSelect(index) {
      // エラーテストの場合
      if(index === 'errorTest') {
        throw new Error('エラーテストです')
      }
      // マスター/設定の場合、ルート遷移せずにポップアップで処理
      if(index === 'preferences') {
        this.$refs.preferences.open()
      }
      // ログアウトの場合、ルート遷移せずにポップアップで処理
      else if(index === 'logout') {
        this.$confirm('ログアウトしますか', 'ログアウト', {
          type: 'warning',
          showClose: false,
        })
        .then(() => {
          this.axiosPost('/logout')
          .then(response => {
            location.href = '/login'
          })
          .catch(error => {
            if(error) { // AxiosVue: nullの場合は処理不要
              this.$alert(axiosErrMsg(error), {type: 'error'})
            }
          })
        })
        .catch(() => {})
      }
      // それ以外はルート遷移
      else if(index !== this.$route.name) {
        this.$router.push({name: index})
      }

      // el-menuのアクティブを設定する: 遷移しないルートのため再設定
      this.defaultActive = null
      this.$nextTick(() => {
        this.defaultActive = this.$route.name
      })
    },

    // app-header-contentの上マージンをヘapp-navi高さに追従させる
    handleWindowResize() {
      if(this.$refs.navi) {
        this.contentStyle.paddingTop = (this.$refs.navi.offsetHeight + 10) + 'px'
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.splash
  height: 100vh

.header
  position: fixed
  z-index: 1000
  width: 100%

.title
  font-size: 120%
  cursor: default
  transition: none
  &:hover
    border-bottom: none
</style>
