
<template lang="pug">
div
  DatabaseEdit(
    module="types"

    filter-props="name"
    root="types"
    :settings="settings"
    :model="model"
    label-width="160px"

    csvImport csvExport
  )
    //- 詳細フォームの編集項目
    el-form-item(prop="name" label="種別名")
      el-input(v-model.trim="model.name" autofocus)

    el-form-item(prop="about_image_templates" label="画像について定型文")
      el-input(v-model.trim="model.about_image_templates" type="textarea" autosize)

    el-form-item(prop="function_templates" label="機能定型文")
      el-input(v-model.trim="model.function_templates" type="textarea" autosize)

    el-form-item(prop="catchcopy_templates" label="キャッチコピー定型文")
      el-input(v-model.trim="model.catchcopy_templates" type="textarea" autosize)

    el-form-item(prop="catchcopy2_templates" label="キャッチコピー2定型文")
      el-input(v-model.trim="model.catchcopy2_templates" type="textarea" autosize)

    el-form-item(prop="catchcopy3_templates" label="キャッチコピー3定型文")
      el-input(v-model.trim="model.catchcopy3_templates" type="textarea" autosize)

    el-form-item(prop="material_templates" label="素材定型文")
      el-input(v-model.trim="model.material_templates" type="textarea" autosize)

    el-form-item(prop="remarks" label="備考")
      el-input(v-model.trim="model.remarks" type="textarea" autosize)

    el-form-item(prop="memo" label="メモ書き")
      el-input(v-model.trim="model.memo" type="textarea" autosize)

    el-form-item(prop="size_columns" label="サイズ項目")
      el-input(v-model.trim="model.size_columns")

    el-form-item(prop="fukubukuro" label="福袋対応")
      el-checkbox(v-model.trim="model.fukubukuro")
</template>

<script>
import DatabaseEdit from 'lib/DatabaseEdit'

export default {
  components: {DatabaseEdit},

  data() {
    return {

      settings: {
        // 一覧テーブルのカラム
        columns: [{
          title: '種別名',
          data: 'name',
          width: 1000,
        }],
      },

      // 詳細フォームのプロパティ
      model: {
        name: '',
        about_image_templates: '',
        function_templates: '',
        catchcopy_templates: '',
        catchcopy2_templates: '',
        catchcopy3_templates: '',
        material_templates: '',
        remarks: '',
        memo: '',
        size_columns: '',
        fukubukuro: false,
      },

    }
  },

  mounted() {
    this.$store.commit('setTitle', 'マスター/種別')
  },
}
</script>

<style lang="sass" scoped>
// サイズ表記用: 連続el-inputにボトムマージンをつける
// .el-input:not(:last-child)
//   margin-bottom: 10px
// サイズ表記用: ボタン付きel-input
.with-button
  width: calc(100% - 45px)
  + button
    margin-left: 10px
// サイズ表記用: 右端ボタン
.last-button
  margin-left: calc(100% - 35px)
</style>
