
import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

import DebugTest            from 'components/DebugTest'
import Types                from 'components/Types'
import Brands               from 'components/Brands'
import Categories           from 'components/Categories'
import DescriptionTemplates from 'components/DescriptionTemplates'
import SizeTemplates        from 'components/SizeTemplates'
import Rules                from 'components/Rules'
import Users                from 'components/Users'
import Rakudir              from 'components/Rakudir'
import ItemsTop             from 'components/ItemsTop'
import Notfound             from 'components/Notfound'

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{

    path: '/debug',
    name: 'debug',
    component: DebugTest,
  }, {

    path: '/types',
    name: 'types',
    component: Types,
    meta: {
      title: 'マスター/種別',
      role: ['admin'],
      order: 1,
    },
  }, {
    path: '/brands',
    name: 'brands',
    component: Brands,
    meta: {
      title: 'マスター/ブランド',
      role: ['admin'],
      order: 2,
    },
  }, {
    path: '/categories',
    name: 'categories',
    component: Categories,
    meta: {
      title: 'マスター/カテゴリ',
      role: ['admin'],
      order: 3,
    },
  }, {
    path: '/description_templates',
    name: 'description_templates',
    component: DescriptionTemplates,
    meta: {
      title: 'マスター/説明定型文',
      role: ['admin'],
      order: 4,
    },
  }, {
    path: '/size_templates',
    name: 'size_templates',
    component: SizeTemplates,
    meta: {
      title: 'マスター/メーカーサイズ定型文',
      role: ['admin'],
      order: 5,
    },
  }, {
    path: '/rules',
    name: 'rules',
    component: Rules,
    meta: {
      title: 'マスター/自動記入',
      role: ['admin'],
      order: 6,
    },
  }, {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      title: 'マスター/ユーザー',
      role: ['admin'],
      order: 7,
    },
  }, {
    path: '/rakudir',
    name: 'rakudir',
    component: Rakudir,
    meta: {
      title: 'マスター/楽天ジャンル',
      role: ['admin'],
      order: 8,
    },
  }, {
    path: '/',
    name: 'items',
    component: ItemsTop,
    meta: {
      title: '商品情報',
      order: 0,
    },
  }, {
    path: '*',
    component: Notfound,
  }]
})
