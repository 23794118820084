
export default {
  namespaced: true,

  state() {
    return {
      table: {},
    }
  },

  mutations: {
    // テーブルの取得
    updateTable(state, table) {
      state.table = table
    },
  },

  actions: {
    // テーブルの取得
    index({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/api/preferences')
        .then(response => {
          commit('updateTable', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
      })
    },

    // テーブルの更新
    update({commit}, table) {
      return new Promise((resolve, reject) => {
        axios.post('/api/preferences', table)
        .then(response => {

          // RMS WEB API ライセンスキーはクライアントで持たない
          if (table.rakuten_api?.license_key) {
            delete table.rakuten_api.license_key
          }

          commit('updateTable', table)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
      })
    },
  },
}
