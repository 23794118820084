
<template lang="pug">
DropGuard
  el-dialog.header-fixed(
    size="full"
    :visible.sync="visible_"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    @close="onClose"
  )
    template(slot="title")

      template(v-if="editGuard_state === null")

        el-button.no-focus.right(
          :disabled="state !== true"
          @click="onCancel"
        ) キャンセル

        el-button.no-focus.right(
          type="primary"
          icon="check"
          :loading="state === 'submitting'"
          :disabled="state !== 'submitting' && state !== true"
          @click="onSubmit"
        ) 決定

        el-button.no-focus.right(
          v-if="$store.state.user.role === 'admin'"
          type="danger"
          icon="delete"
          :loading="state === 'deleting'"
          :disabled="state !== 'deleting' && state !== true"
          @click="onDelete"
        ) 削除

        el-button.no-focus.left(
          @click="onClickRule"
        ) 自動記入

      template(v-else-if="editGuard_state === false")

        el-button.no-focus.right(
          :disabled="state !== true"
          @click="onCancel"
        ) 閉じる

        el-button.no-focus.right(
          type="info"
          @click="onEditGuardAcquire"
        ) 編集

        .edit-guard__text 編集を開始できます

      template(v-else)

        el-button.no-focus.right(
          :disabled="state !== true"
          @click="onCancel"
        ) 閉じる

        .edit-guard__text {{editGuard_state}}が編集中です

    el-form-ex(
      ref="form"
      label-width="120px"
      :model="model"
      @submit.prevent.native="onSubmit"
    )
      .form-frame
        el-row
          //- 入荷・検品
          el-col(:span="12" :md="6")
            el-form-item(prop="checked_0" label="入荷・検品" label-width="100px")
              AppProgrssCheck(
                :msg="msg"
                :value.sync="model.checked_0"
                :date.sync="model.checked_0_at"
                :user.sync="model.checked_0_by"
                :disabled="formDisabled"
              )

          //- データ記入
          el-col(:span="12" :md="6")
            el-form-item(prop="checked_1" label="データ記入" label-width="100px")
              AppProgrssCheck(
                :msg="msg"
                :value.sync="model.checked_1"
                :date.sync="model.checked_1_at"
                :user.sync="model.checked_1_by"
                :disabled="formDisabled"
              )

          //- コメント記入
          el-col(:span="12" :md="6")
            el-form-item(prop="checked_2" label="コメント記入" label-width="100px")
              AppProgrssCheck(
                :msg="msg"
                :value.sync="model.checked_2"
                :date.sync="model.checked_2_at"
                :user.sync="model.checked_2_by"
                :disabled="formDisabled"
              )

          //- 撮影
          el-col(:span="12" :md="6")
            el-form-item(prop="checked_3" label="撮影" label-width="100px")
              AppProgrssCheck(
                :msg="msg"
                :value.sync="model.checked_3"
                :date.sync="model.checked_3_at"
                :user.sync="model.checked_3_by"
                :disabled="formDisabled"
              )

          //- CSV出力済
          el-col(:span="12" :md="{span:6, push:12}")
            el-form-item(prop="exported" label="CSV出力済" label-width="100px")
              AppProgrssCheck(
                :msg="msg"
                :value.sync="model.exported"
                :date.sync="model.exported_at"
                :user.sync="model.exported_by"
                :disabled="formDisabled"
              )

          //- PDF出力済
          el-col(:span="12" :md="{span:6, push:12}")
            el-form-item(prop="printed" label="PDF出力済" label-width="100px")
              AppProgrssCheck(
                :msg="msg"
                :value.sync="model.printed"
                :date.sync="model.printed_at"
                :user.sync="model.printed_by"
                :disabled="formDisabled"
              )

      .form-no-frame
        el-row(v-if="current_memo")
          el-col(:span="24")
            el-form-item(prop="memo" label="メモ書き")
              el-input(
                type="textarea"
                autosize
                :value="current_memo"
                readonly
              )

        el-row
          //- 要確認
          el-col(:span="24")
            el-form-item(prop="annotation" label="要確認")
              el-input-lazy(
                type="textarea"
                autosize
                v-model="model.annotation"
                :disabled="formDisabled"
              )

      .form-frame
        el-row
          //- 画像について
          el-col(:span="24")
            el-form-item(prop="about_image" label="画像について")
              AppTemplateArray(
                :placeholder="template_placeholder"
                :templates="current_about_image_templates"
                :model.sync="model.about_image"
                :disabled="formDisabled"
              )
                el-input-lazy(
                  v-model="model.about_image"
                  :disabled="formDisabled"
                )

        el-row
          //- 画像
          el-col(:span="24" :md="{span:5, push:19}")
            AppPictureInput.item-picture(
              :visible='visible_'
              :image.sync="model.picture"
              :disabled="formDisabled"
            )

          el-col(:span="24" :md="{span:19, pull:5}")
            el-row
              //- 種別
              el-col(:span="24" :md="12")
                el-form-item(prop="type_id" label="種別")
                  el-select.full(
                    v-model="model.type_id"
                    placeholder="選択してください"
                    :disabled="formDisabled"
                    @change="onChangeType"
                  )
                    el-option(
                      v-for="t in types"
                      :key="t.id"
                      :value="t.id"
                      :label="t.name"
                    )

              //- 新作表記
              el-col(:span="24" :md="12")
                el-form-item(prop="sinsaku_hyouki" label="新作表記")
                  el-select.full(
                    clearable
                    v-model="model.sinsaku_hyouki"
                    placeholder="新作表記なし"
                    :disabled="formDisabled"
                  )
                    el-option(
                      v-for="option in sinsakuHyoukiOptions"
                      :key="option"
                      :value="option"
                    )

            el-row
              //- 性別
              el-col(:span="24" :md="12")
                el-form-item(prop="gender" label="性別")
                  el-radio-group(v-model="model.gender")
                    el-radio-button.items-form-edit-radio-red(
                      label="ladies"
                      :disabled="formDisabled"
                    ) レディース
                    el-radio-button(
                      label="mens"
                      :disabled="formDisabled"
                    ) メンズ
                    el-radio-button.items-form-edit-radio-yellow(
                      label="uni"
                      :disabled="formDisabled"
                    ) 両用

              //- ブランド
              el-col(:span="24" :md="12")
                el-form-item(prop="brand" label="ブランド")
                  el-input-lazy.with-button(
                    v-model="brandName"
                    readonly
                    :class="{'items-form-edit-input-grey': brandOld}"
                    :disabled="formDisabled"
                  )

                  //- メーカーサイズ/定型文ボタン
                  AppTemplatePopover(
                    :data="brands"
                    prop="name"
                    width="400"
                    @select="onSelectBrand"
                  )
                    el-button.no-focus(
                      icon="edit"
                      size="small"
                      slot="reference"
                      :disabled="formDisabled"
                    )

            el-row
              //- 商品番号
              el-col(:span="24" :md="12")
                el-form-item(prop="code" label="商品番号")
                  el-input-lazy(
                    v-model.trim="model.code"
                    :disabled="formDisabled"
                  )

              //- JANコード
              el-col(:span="24" :md="12")
                el-form-item(prop="jan" label="JANコード")
                  el-input-lazy(
                    v-model.trim="model.jan"
                    :disabled="formDisabled"
                  )

            el-row
              //- 価格(税別)
              el-col(:span="24" :md="12")
                el-form-item(prop="price" label="価格(税別)")
                  //- ここだけel-input-lazyを使わない
                  el-input.form-input-yen.with-button(
                    v-model.trim="model.price"
                    :disabled="formDisabled"
                    @change="onChangePrice"
                  )
                  el-button.no-focus(
                    size="mini"
                    icon="d-arrow-right"
                    :type="priceLink ? 'primary' : null"
                    :disabled="formDisabled"
                    @click="priceLink = !priceLink"
                  )

              //- 価格(税込)
              el-col(:span="24" :md="12")
                el-form-item(prop="price_tax" label="価格(税込)")
                  el-input-lazy.form-input-yen.with-button(
                    v-model.trim="model.price_tax"
                    :disabled="formDisabled"
                  )

        el-row
          //- 商品名
          el-col(:span="24")
            el-form-item(prop="name" label="商品名")
              el-input-lazy(
                v-model="model.name"
                :disabled="formDisabled"
              )

        el-row
          //- 機能
          el-col(:span="24")
            el-form-item(prop="function" label="機能")
              AppTemplateArray(
                :placeholder="template_placeholder"
                :templates="current_function_templates"
                :model.sync="model.function"
                :disabled="formDisabled"
              )
                el-input-lazy(
                  type="textarea"
                  autosize
                  v-model="model.function"
                  :disabled="formDisabled"
                )

        el-row
          //- キャッチコピー
          el-col(:span="24")
            el-form-item(prop="catchcopy" label="キャッチコピー")
              AppTemplateArray(
                :placeholder="template_placeholder"
                :templates="current_catchcopy_templates"
                :model.sync="model.catchcopy"
                :disabled="formDisabled"
              )
                el-input-lazy(
                  v-model="model.catchcopy"
                  :disabled="formDisabled"
                )

        el-row
          //- キャッチコピー2
          el-col(:span="24")
            el-form-item(prop="catchcopy2" label="キャッチコピー2")
              AppTemplateArray(
                :placeholder="template_placeholder"
                :templates="current_catchcopy2_templates"
                :model.sync="model.catchcopy2"
                :disabled="formDisabled"
              )
                el-input-lazy(
                  v-model="model.catchcopy2"
                  :disabled="formDisabled"
                )

        el-row
          //- キャッチコピー3
          el-col(:span="24")
            el-form-item(prop="catchcopy3" label="キャッチコピー3")
              AppTemplateArray(
                :placeholder="template_placeholder"
                :templates="current_catchcopy3_templates"
                :model.sync="model.catchcopy3"
                :disabled="formDisabled"
              )
                el-input-lazy(
                  v-model="model.catchcopy3"
                  :disabled="formDisabled"
                )

        el-row
          //- 素材
          el-col(:span="24")
            el-form-item(prop="material" label="素材")
              AppTemplateArray(
                :placeholder="template_placeholder"
                :templates="current_material_templates"
                :model.sync="model.material"
                :disabled="formDisabled"
              )
                el-input-lazy(
                  type="textarea"
                  autosize
                  v-model="model.material"
                  :disabled="formDisabled"
                )

        el-row
          //- メーカーサイズ
          el-col(:span="24")
            el-form-item(prop="maker_size" label="メーカーサイズ")
              el-input-lazy.with-button(
                type="textarea"
                autosize
                v-model="model.maker_size"
                :disabled="formDisabled"
              )

              //- メーカーサイズ/定型文ボタン
              AppTemplatePopover(
                :data="size_templates"
                prop="keyword"
                width="700"
                @select="onSelectMakerSizeTemplate"
              )
                el-button.no-focus(
                  icon="edit"
                  size="small"
                  slot="reference"
                  :disabled="formDisabled"
                )

        //- サイズテーブル
        el-row
          el-col(:span="24")
            el-table(
              :data="model.sizes"
              border
            )
              el-table-column(label="サイズ名" width="150" class-name="table-input" fixed)
                el-input-lazy(
                  slot-scope="scope"
                  v-model.trim="model.sizes[scope.$index].name"
                  :disabled="formDisabled"
                )

              el-table-column(label="サイズ" width="100" class-name="table-input" fixed)
                DummyInput(
                  slot-scope="scope"
                  :value="model.sizes[scope.$index].code"
                  :disabled="formDisabled"
                )

              el-table-column(
                v-for="(column, index) in model.size_columns"
                :key="index"
                :render-header="onRenderSizesHeader"
                class-name="items-form-table-column"
                width="150"
              )
                template(slot-scope="scope")
                  input.size-cell-value(
                    v-model.trim="model.sizes[scope.$index].values[index]"
                    :class="cellColorClass"
                    :disabled="formDisabled"
                  )
                  button.size-cell-unit(
                    type="button"
                    :class="cellColorClass"
                    :disabled="formDisabled"
                    @click="onClickSizeCellUnit(index)"
                  ) {{column.unit}}

              el-table-column(
                min-width="90"
                :render-header="onRenderSizesHeaderControl"
              )
                template(slot-scope="scope")
                  .item-form-table-dropdown-container
                    el-dropdown(
                      trigger="click"
                      @command="onClickSizeDropdown($event, scope.$index)"
                    )
                      i.el-icon-caret-bottom
                      el-dropdown-menu(
                        slot="dropdown"
                        v-if="!formDisabled"
                      )
                        el-dropdown-item(command='up' :disabled="scope.$index === 0") 行を上に移動
                        el-dropdown-item(command='down' :disabled="scope.$index === model.sizes.length - 1") 行を下に移動
                        el-dropdown-item(command='prev') 行を上に追加
                        el-dropdown-item(command='next') 行を下に追加
                        el-dropdown-item(command='del') 行を削除

                    AppDisplayBytes(:value="model.sizes[scope.$index]")

              el-button(
                slot="empty"
                type="primary"
                icon="plus"
                size="small"
                @click="onClickSizeInsert(0)"
              ) サイズがありません

        el-row
          //- 楽天納期管理番号
          el-col(:span="24" :md="8")
            el-form-item(prop="rakuten_nouki" label="楽天納期管理番号" label-width="130px")
              el-input-lazy(
                v-model.trim="model.rakuten_nouki"
                :disabled="formDisabled"
              )

          //- 配送方法セット
          el-col(:span="24" :md="8")
            el-form-item(prop="haisou_houhou" label="配送方法セット" label-width="130px")

              el-select.full(
                v-model="model.haisou_houhou"
                placeholder="選択してください"
                :disabled="formDisabled"
              )
                el-option(:value="1" label="1: コンビニ 可 　/ メール便 不可")
                el-option(:value="2" label="2: コンビニ 可 　/ メール便 可")
                el-option(:value="3" label="3: コンビニ 不可 / メール便 不可")

          //-表示順序No
          el-col(:span="24" :md="8")
            el-form-item(prop="hyouji_jyunjo" label="表示順序No" label-width="130px")
              el-input-lazy(
                v-model.trim="model.hyouji_jyunjo"
                :disabled="formDisabled"
              )

        el-row
          //- 備考チェック
          el-col(v-for="(item, index) in remarks" :key="index" :span="12" :md="6")
            el-form-item(prop="remarks[item]" :label="item" label-width="207px")
              el-checkbox(
                v-model="remarkChecks[item]"
                :disabled="formDisabled"
              )

        //- カラーテーブル
        el-row
          el-col(:span="24")
            el-table(:data="model.colors" border)
              el-table-column(label="カラー名" width="265" class-name="table-input" fixed)
                el-input-lazy(
                  slot-scope="scope"
                  v-model.trim="model.colors[scope.$index].name"
                  :disabled="formDisabled"
                )

              el-table-column(label="カラー" width="100" class-name="table-input" fixed)
                DummyInput(
                  slot-scope="scope"
                  :value="model.colors[scope.$index].code"
                  :disabled="formDisabled"
                )

              el-table-column(
                v-for="(size, index) in model.sizes"
                :key="index"
                :label="size.name + ' (' + size.code + ')'"
                class-name="table-input"
                width="150"
              )
                el-input-lazy(
                  slot-scope="scope"
                  v-model.trim="model.colors[scope.$index].values[index]"
                  :disabled="formDisabled"
                )

              el-table-column
                template(slot-scope="scope")
                  .item-form-table-dropdown-container
                    el-dropdown(
                      trigger="click"
                      @command="onClickColorDropdown($event, scope.$index)"
                    )
                      i.el-icon-caret-bottom
                      el-dropdown-menu(
                        slot="dropdown"
                        v-if="!formDisabled"
                      )
                        el-dropdown-item(command='up' :disabled="scope.$index === 0") 行を上に移動
                        el-dropdown-item(command='down' :disabled="scope.$index === model.colors.length - 1") 行を下に移動
                        el-dropdown-item(command='prev') 行を上に追加
                        el-dropdown-item(command='next') 行を下に追加
                        el-dropdown-item(command='del') 行を削除

                    AppDisplayBytes(:value="model.colors[scope.$index]")

              el-button(
                slot="empty"
                type="primary"
                icon="plus"
                size="small"
                @click="onClickColorInsert(0)"
              ) カラーがありません

        //- 説明文1
        el-row
          el-col(:span="24")
            el-form-item(prop="description" label="説明文1" label-width="130px")
              DropFile(@drop="onDropFile")
                el-input-lazy.with-button(
                  type="textarea"
                  autosize
                  v-model="model.description"
                  :disabled="formDisabled"
                )

                //- 説明文/定型文ボタン
                AppTemplatePopover(
                  :data="description1_templates"
                  prop="keyword"
                  width="700"
                  @select="onSelectDescriptionTemplate"
                  @show="onShowDescriptionTemplate"
                )
                  el-button.no-focus(
                    icon="edit"
                    size="small"
                    slot="reference"
                    :disabled="formDisabled"
                  )

        //- 説明文2
        el-row
          el-col(:span="24")
            el-form-item(prop="description2" label="説明文2" label-width="130px")
              DropFile(@drop="files => onDropFile(files, 2)")
                el-input-lazy.with-button(
                  type="textarea"
                  autosize
                  v-model="model.description2"
                  :disabled="formDisabled"
                )

                //- 説明文/定型文ボタン
                AppTemplatePopover(
                  :data="description2_templates"
                  prop="keyword"
                  width="700"
                  @select="row => onSelectDescriptionTemplate(row, 2)"
                  @show="onShowDescriptionTemplate"
                )
                  el-button.no-focus(
                    icon="edit"
                    size="small"
                    slot="reference"
                    :disabled="formDisabled"
                  )

        //- 説明文3
        el-row
          el-col(:span="24")
            el-form-item(prop="description3" label="説明文3" label-width="130px")
              DropFile(@drop="files => onDropFile(files, 3)")
                el-input-lazy.with-button(
                  type="textarea"
                  autosize
                  v-model="model.description3"
                  :disabled="formDisabled"
                )

                //- 説明文/定型文ボタン
                AppTemplatePopover(
                  :data="description3_templates"
                  prop="keyword"
                  width="700"
                  @select="row => onSelectDescriptionTemplate(row, 3)"
                  @show="onShowDescriptionTemplate"
                )
                  el-button.no-focus(
                    icon="edit"
                    size="small"
                    slot="reference"
                    :disabled="formDisabled"
                  )

        //- 説明文4
        el-row
          el-col(:span="24")
            el-form-item(prop="description4" label="説明文4" label-width="130px")
              DropFile(@drop="files => onDropFile(files, 4)")
                el-input-lazy.with-button(
                  type="textarea"
                  autosize
                  v-model="model.description4"
                  :disabled="formDisabled"
                )

                //- 説明文/定型文ボタン
                AppTemplatePopover(
                  :data="description4_templates"
                  prop="keyword"
                  width="700"
                  @select="row => onSelectDescriptionTemplate(row, 4)"
                  @show="onShowDescriptionTemplate"
                )
                  el-button.no-focus(
                    icon="edit"
                    size="small"
                    slot="reference"
                    :disabled="formDisabled"
                  )

        //- 説明文5
        el-row
          el-col(:span="24")
            el-form-item(prop="description5" label="説明文5" label-width="130px")
              DropFile(@drop="files => onDropFile(files, 5)")
                el-input-lazy.with-button(
                  type="textarea"
                  autosize
                  v-model="model.description5"
                  :disabled="formDisabled"
                )

                //- 説明文/定型文ボタン
                AppTemplatePopover(
                  :data="description5_templates"
                  prop="keyword"
                  width="700"
                  @select="row => onSelectDescriptionTemplate(row, 5)"
                  @show="onShowDescriptionTemplate"
                )
                  el-button.no-focus(
                    icon="edit"
                    size="small"
                    slot="reference"
                    :disabled="formDisabled"
                  )

        //- 説明文6 (注意文)
        el-row
          el-col(:span="24")
            el-form-item(prop="description6" label="説明文6 (注意文)" label-width="130px")
              DropFile(@drop="files => onDropFile(files, 6)")
                el-input-lazy.with-button(
                  type="textarea"
                  autosize
                  v-model="model.description6"
                  :disabled="formDisabled"
                )

                //- 説明文/定型文ボタン
                AppTemplatePopover(
                  :data="description6_templates"
                  prop="keyword"
                  width="700"
                  @select="row => onSelectDescriptionTemplate(row, 6)"
                  @show="onShowDescriptionTemplate"
                )
                  el-button.no-focus(
                    icon="edit"
                    size="small"
                    slot="reference"
                    :disabled="formDisabled"
                  )

        //- カテゴリー
        el-row(
          v-for="t in 4"
          v-if="categoryTypedMaster[t] && categoryTypedMaster[t].length"
          :key="t"
        )
          el-col(:span="24")
            el-form-item(
              prop="category"
              :label="'カテゴリー' + t"
              label-width="130px"
            )
              el-select.full(
                v-model="categoryTyped[t]"
                multiple
                :multiple-limit="5"
                filterable
                placeholder="選択してください"
                :disabled="formDisabled"
              )
                el-option(
                  v-for="cat in categoryTypedMaster[t]"
                  :key="cat.name"
                  :value="cat.name"
                )

        el-row
          //- 楽天ジャンル
          el-col(:span="24")
            el-form-item(prop="rakudir_code" label="楽天ジャンル" label-width="130px")

              //- 楽天ジャンル/選択リスト
              AppTemplatePopover.full-width(
                :data="rakudir"
                :prop="['code', 'path']"
                :width="[80, 600]"
                @select="onSelectRakudir"
              )
                el-button(
                  slot="reference"
                  :disabled="formDisabled"
                ) {{rakudir_disp}}

        //- 複数JANテーブル
        el-row
          el-col(:span="24")
            el-table(:data="model.colors" border)
              el-table-column(label="JAN" width="265" class-name="table-input" fixed)
                DummyInput(
                  slot-scope="scope"
                  :value="model.colors[scope.$index].name"
                  :disabled="formDisabled"
                )

              el-table-column(label="カラー" width="100" class-name="table-input" fixed)
                DummyInput(
                  slot-scope="scope"
                  :value="model.colors[scope.$index].code"
                  :disabled="formDisabled"
                )

              el-table-column(
                v-for="(size, index) in model.sizes"
                  :key="index"
                  :label="size.name + ' (' + size.code + ')'"
                  class-name="table-input"
                  width="150"
                )
                  el-input-lazy(
                    slot-scope="scope"
                    :value="model.jans?.[normalizeCode(size.code)]?.[normalizeCode(scope.row.code)]"
                    @input="value => model.jans[normalizeCode(size.code)][normalizeCode(scope.row.code)] = value.trim()"
                    :disabled="formDisabled"
                  )

              el-table-column
</template>

<script>
import {mapState, mapActions} from 'vuex'
import DummyInput from 'lib/DummyInput'
import ElFormEx from 'lib/ElFormEx'
import ElInputLazy from 'lib/ElInputLazy'
import DropFile from 'lib/DropFile'
import DropGuard from 'lib/DropGuard'
import AppDisplayBytes from './AppDisplayBytes'
import AppPictureInput from './AppPictureInput'
import AppProgrssCheck from './AppProgrssCheck'
import AppTemplateArray from './AppTemplateArray'
import AppTemplatePopover from './AppTemplatePopover'

import EditGuardMixin from '@/lib/EditGuardMixin.js'
import RuleMixin from './ItemsFormEditRuleMixin.js'
import JansMixin from './ItemsFormEditJansMixin.js'

const encoding = require('encoding-japanese')

// オブジェクトのクローニング
function clone(obj) {
  return JSON.parse(JSON.stringify(obj))
}

// 文字列をパイプ文字|または改行で分割して配列にし、各要素の空文字をトリムし、空文字列のみの要素は削除
function splitText(text) {
  return text.split(/[|\r\n]+/).map(e => e.trim()).filter(e => e !== '')
}

//
function parseSizeColumn(text) {
  const arr = text.split(',').map(e => e.trim())
  return {
    label: arr[0],
    unit: arr[1] != null ? arr[1] : 'cm',
  }
}

export default {
  components: {DummyInput, ElFormEx, AppDisplayBytes, AppPictureInput, AppProgrssCheck, AppTemplateArray, AppTemplatePopover, ElInputLazy, DropFile, DropGuard},
  mixins: [EditGuardMixin, RuleMixin, JansMixin],

  props: {
    visible: Boolean,
  },

  data() {
    return {
      open_id: null,

      msg: 0,

      visible_: this.visible,

      state: true,

      model: {
        id: null,

        checked_0: null,
        checked_0_at: null,
        checked_0_by: null,

        checked_1: null,
        checked_1_at: null,
        checked_1_by: null,

        checked_2: null,
        checked_2_at: null,
        checked_2_by: null,

        checked_3: null,
        checked_3_at: null,
        checked_3_by: null,

        exported: null,
        exported_at: null,
        exported_by: null,

        printed: null,
        printed_at: null,
        printed_by: null,

        about_image: null,

        picture: null,

        type_id: null,

        sinsaku_hyouki: null,

        gender: null,
        brand: null,
        brand_id: null,

        code: null,
        jan: null,

        price: null,
        price_tax: null,

        name: null,
        function: null,
        catchcopy: null,
        catchcopy2: null,
        catchcopy3: null,
        material: null,
        maker_size: null,

        size_columns: [],
        sizes: [],

        rakuten_nouki: null,
        haisou_houhou: null,
        hyouji_jyunjo: null,

        remarks: {},

        colors: [],

        description: null,
        description2: null,
        description3: null,
        description4: null,
        description5: null,
        description6: null,
        category: [],
        rakudir_code: null,
        annotation: null,

        jans: {},
      },

      sinsakuHyoukiOptions: [],
      priceLink: true,

      remarks: [],
      remarkChecks: [],

      categoryTypedMaster: {},
      categoryTyped: {},

      currentTypeId: null, // el-selectのchangeイベントタイミングバグへの対処(リアクティブからのvalue変更でも点火してしまう問題)
    }
  },

  computed: {
    ...mapState({
      types:                 state => state.types.table,
      brands:                state => state.brands.table,
      categories:            state => state.categories.table,
      description_templates: state => state.description_templates.table,
      size_templates:        state => state.size_templates.table,
      rakudir:               state => state.rakudir.table,
    }),

    // フォーム全体の無効化
    formDisabled() {
      return this.editGuard_state !== null
    },

    // テーブルセルの色指定クラス (サイズの項目用)
    cellColorClass() {
      return this.formDisabled ? 'items-form-cell-disabled' : ''
    },

    // 選択中の種別
    current_type() {
      return this.types.find(e => e.id === this.model.type_id)
    },

    // 画像について 定型文配列
    current_about_image_templates() {
      return this.current_type ? splitText(this.current_type.about_image_templates) : []
    },

    // 機能 定型文配列
    current_function_templates() {
      return this.current_type ? splitText(this.current_type.function_templates) : []
    },

    // キャッチコピー 定型文配列
    current_catchcopy_templates() {
      return this.current_type ? splitText(this.current_type.catchcopy_templates) : []
    },

    // キャッチコピー2 定型文配列
    current_catchcopy2_templates() {
      return this.current_type ? splitText(this.current_type.catchcopy2_templates) : []
    },

    // キャッチコピー3 定型文配列
    current_catchcopy3_templates() {
      return this.current_type ? splitText(this.current_type.catchcopy3_templates) : []
    },

    // 素材 定型文配列
    current_material_templates() {
      return this.current_type ? splitText(this.current_type.material_templates) : []
    },

    // メモ書き
    current_memo() {
      return this.current_type ? this.current_type.memo : ''
    },

    // サイズテーブルカラム 配列
    current_size_columns() {
      return this.current_type ? splitText(this.current_type.size_columns).map(parseSizeColumn) : []
    },

    // 備考チェック 配列
    current_remarks() {
      return this.current_type ? splitText(this.current_type.remarks) : []
    },

    // 楽天ジャンル表記
    rakudir_disp() {
      const f = this.rakudir.find(e => e.code === this.model.rakudir_code)
      return f ? f.code + ' ' + f.path : '選択してください'
    },

    // 種別ごとの説明定型文
    description1_templates() {
      return this.description_templates.filter(row => row.type === 1 || row.type === 0)
    },

    description2_templates() {
      return this.description_templates.filter(row => row.type === 2 || row.type === 0)
    },

    description3_templates() {
      return this.description_templates.filter(row => row.type === 3 || row.type === 0)
    },

    description4_templates() {
      return this.description_templates.filter(row => row.type === 4 || row.type === 0)
    },

    description5_templates() {
      return this.description_templates.filter(row => row.type === 5 || row.type === 0)
    },

    description6_templates() {
      return this.description_templates.filter(row => row.type === 6 || row.type === 0)
    },

    // テンプレートのプレースホルダ
    template_placeholder() {
      return this.current_type ? '定型文なし' : '種別を選択してください'
    },

    // 新式ブランド名の表示
    brandModel() {
      return this.brands.find(e => e.id === this.model.brand_id)
    },

    brandOld() {
      return this.brandModel == null
    },

    brandName() {
      return this.brandModel?.name ?? this.model.brand
    },
  },

  watch: {
    visible(value) {this.visible_ = value},
    visible_(value) {this.$emit('update:visible', value)},

    // 備考のbindingが正しく働くように、remarkChecksのプロパティを適宜作成する
    current_remarks(value) {
      const remarks = []
      for(let remark of value) {
        if(remark[0] === '*') {
          continue
        }
        let value = false
        if(remark[0] === '^') {
          remark = remark.substr(1)
          value = true
        }
        remarks.push(remark)
        if(this.remarkChecks[remark] === undefined) {
          this.$set(this.remarkChecks, remark, value)
        }
      }
      this.remarks = remarks
    },
  },

  created() {
    // 初期modelのJSON
    this.initialModelJSON = JSON.stringify(this.model)
  },

  mounted() {
    this.axiosAutoCancel()

    // 新作表記オプションの作成
    const today = new Date()
    today.setDate(1)
    today.setMonth(today.getMonth() - 1)
    for(let i = 0; i < 2; ++i) {
      this.sinsakuHyoukiOptions.push(today.getFullYear() + (today.getMonth() < 6 ? ' 春夏 新作' : ' 秋冬 新作'))
      today.setMonth(today.getMonth() + 6)
    }
  },

  methods: {
    ...mapActions(['loadDescriptionTemplates']),

    // フォームを開く
    async open(item) {
      await this.openById(item.id)
    },

    async openById(item_id) {
      this.open_id = item_id
      this.axiosGet('/api/items/' + item_id)
      .then(async response => {

        // 編集ガード
        await this.acquireEditGuard('sheets-' + item_id)

        //
        this.visible_ = true
        this.state = true

        const model = JSON.parse(this.initialModelJSON)
        for(const key of Object.keys(model)) {
          if(response.data[key] != null) {
            model[key] = response.data[key]
          }
        }
        this.model = model
        this.currentTypeId = model.type_id // el-selectのchangeイベントタイミングバグへの対処(リアクティブからのvalue変更でも点火してしまう問題)

        // 複数JANへの対応
        this.setupJans(model.jans)

        // remarksをremarkChecksに変換
        const checks = {}
        for(let remark of this.current_remarks) {
          if(remark[0] === '*') {
            continue
          }
          if(remark[0] === '^') {
            remark = remark.substr(1)
          }
          checks[remark] = this.model.remarks.indexOf(remark) !== -1
        }
        this.remarkChecks = checks

        // カテゴリーを種別毎に分割する
        const categoryMaster = this.categories.slice().sort((a, b) => a.name.localeCompare(b.name))

        const categoryTypedMaster = {1: [], 2: [], 3: [], 4: []}
        for(const cat of categoryMaster) {
          categoryTypedMaster[cat.type].push(cat)
        }
        this.categoryTypedMaster = categoryTypedMaster

        const categoryTyped = {1: [], 2: [], 3: [], 4: []}
        for(const cat of this.model.category) {
          const f = categoryMaster.find(e => e.name === cat)
          categoryTyped[f ? f.type : 1].push(cat)
        }
        this.categoryTyped = categoryTyped

        // サブコンポーネントにフォームが開かれたことを通知する
        ++this.msg
      })
      .catch(error => {
        if(error) { // AxiosVue: nullの場合は処理不要
          if(error.response && error.response.status === 404) {
            this.$alert('この商品は削除されています。ページを更新してください。', {type: 'error'})
            this.visible_ = false
          }
          else {
            this.$alert(axiosErrMsg(error), {type: 'error'})
          }
        }
      })
    },

    // 種別の変更
    onChangeType(value) {
      if(this.currentTypeId != value) { // el-selectのchangeイベントタイミングバグへの対処(リアクティブからのvalue変更でも点火してしまう問題)
        this.currentTypeId = value
        // ここはdeep cloneすること！
        this.$set(this.model, 'size_columns', clone(this.current_size_columns))
      }
    },

    // ブランドの変更
    onSelectBrand(row) {
      this.model.brand = row.name
      this.model.brand_id = row.id
    },

    // 価格の変更
    onChangePrice(value) {
      if(this.priceLink) {
        const price = value.trim()
        if(price !== '' && !isNaN(price)) {
          this.model.price_tax = Math.floor(price * this.$store.state.preferences.table.tax_rate)
        }
      }
    },

    // メーカーサイズの定型文挿入
    onSelectMakerSizeTemplate(row) {
      let value = this.model.maker_size != null ? this.model.maker_size : ''
      if(0 < value.length) {
        value += '\n'
      }
      this.model.maker_size = value + row.text
    },

    // 説明文の定型文挿入
    onSelectDescriptionTemplate(row, type = '') {
      const prop = 'description' + type
      let value = this.model[prop] != null ? this.model[prop] : ''
      if(0 < value.length) {
        value += '\n'
      }
      this.model[prop] = value + row.text
    },

    // 説明定型文のリロード
    async onShowDescriptionTemplate() {
      await this.loadDescriptionTemplates()
    },

    // 楽天ジャンルの選択
    onSelectRakudir(row) {
      this.model.rakudir_code = row.code
    },

    // サイズテーブルのヘッダにel-inputを埋め込み
    onRenderSizesHeader(h, {column, $index}) {
      return h('el-input', {
        class: 'items-form-sizes-header-input',
        props: {
          value: this.model.size_columns[$index - 2].label,
          disabled: this.formDisabled,
        },
        on: {
          input: value => {
            this.model.size_columns[$index - 2].label = value
          },
        },
      })
    },

    // サイズテーブルヘッダ右端のレンダリング(ドロップダウン)
    onRenderSizesHeaderControl(h, {column, $index}) {
      const children = [
        h('i', {class: 'el-icon-caret-bottom'}),
      ]

      if(!this.formDisabled) {
        children.push(
          h('el-dropdown-menu', {slot: 'dropdown'}, [
            h('el-dropdown-item', {
              props: {
                command: 'del',
                disabled: this.model.size_columns.length <= 0,
              },
              domProps: {innerHTML: '項目を減らす'},
            }),
            h('el-dropdown-item', {
              props: {command: 'add'},
              domProps: {innerHTML: '項目を増やす'},
            }),
          ])
        )
      }

      return h('el-dropdown', {
        props: {trigger: 'click'},
        on: {
          command: e => {
            switch(e) {
              case 'del':
                // サイズテーブルのカラムを減らす
                if(0 < this.model.size_columns.length) {
                  this.model.size_columns.pop()
                }
                break
              case 'add': {
                // サイズテーブルのカラムを増やす
                let name = parseSizeColumn('')
                if(this.model.size_columns.length < this.current_size_columns.length) {
                  // ここはcloneすること！
                  name = clone(this.current_size_columns[this.model.size_columns.length])
                }
                this.model.size_columns.push(name)
                break
              }
            }
          },
        },
      }, children)
    },

    // サイズテーブル 単位ボタン押下
    onClickSizeCellUnit(index) {
      this.$prompt('単位を入力してください。', '単位の変更', {
        inputValue: this.model.size_columns[index].unit,
      })
      .then(result => {
        this.model.size_columns[index].unit = result.value
      })
      .catch(() => {})
    },

    // サイズテーブル行右端のドロップダウンコマンド
    onClickSizeDropdown(command, index) {
      switch(command) {
      case 'up': {
        const moving = this.model.sizes.splice(index, 1)
        this.model.sizes.splice(index - 1, 0, ...moving)
        this.model.colors = this.model.colors.map((colors) => {
          const moving = colors.values.splice(index, 1)
          colors.values.splice(index - 1, 0, ...moving)
          return colors
        })
        break
      }
      case 'down': {
        const moving = this.model.sizes.splice(index, 1)
        this.model.sizes.splice(index + 1, 0, ...moving)
        this.model.colors = this.model.colors.map((colors) => {
          const moving = colors.values.splice(index, 1)
          colors.values.splice(index + 1, 0, ...moving)
          return colors
        })
        break
      }
      case 'prev':
        this.onClickSizeInsert(index)
        break
      case 'next':
        this.onClickSizeInsert(index + 1)
        break
      case 'del':
        this.onClickSizeDelete(index)
        break
      }
    },

    // サイズテーブル行削除ボタン押下
    onClickSizeDelete(index) {
      this.$confirm('削除します。よろしいですか？', 'サイズの行を削除', {
        type: 'warning',
      })
      .then(() => {
        this.model.sizes.splice(index, 1)
        for(const color of this.model.colors) {
          color.values.splice(index, 1)
        }

        // 複数JANへの対応
        this.updateJans()
      })
      .catch(() => {})
    },

    // サイズテーブル行追加ボタン押下
    onClickSizeInsert(index) {
      this.$prompt('コードを入力してください。', 'サイズの行を追加', {
        inputValidator: code => {
          if(!code) {
            return 'コードは必須です'
          }
          if(this.model.sizes.some(size => {
            return size.code === code
          })) {
            return 'コードが重複しています'
          }
          return true
        },
      })
      .then(result => {
        this.model.sizes.splice(index, 0, {code: result.value, name: '', values: []})
        for(const color of this.model.colors) {
          color.values.splice(index, 0, null)
        }

        // 複数JANへの対応
        this.updateJans()
      })
      .catch(() => {})
    },

    // カラーテーブル行右端のドロップダウンコマンド
    onClickColorDropdown(command, index) {
      switch(command) {
      case 'up': {
        const moving = this.model.colors.splice(index, 1)
        this.model.colors.splice(index - 1, 0, ...moving)
        break
      }
      case 'down': {
        const moving = this.model.colors.splice(index, 1)
        this.model.colors.splice(index + 1, 0, ...moving)
        break
      }
      case 'prev':
        this.onClickColorInsert(index)
        break
      case 'next':
        this.onClickColorInsert(index + 1)
        break
      case 'del':
        this.onClickColorDelete(index)
        break
      }
    },

    // カラーテーブル行削除ボタン押下
    onClickColorDelete(index) {
      this.$confirm('削除します。よろしいですか？', 'カラーの行を削除', {
        type: 'warning',
      })
      .then(() => {
        this.model.colors.splice(index, 1)

        // 複数JANへの対応
        this.updateJans()
      })
      .catch(() => {})
    },

    // カラーテーブル行追加ボタン押下
    onClickColorInsert(index) {
      this.$prompt('コードを入力してください。', 'カラーの行を追加', {
        inputValidator: code => {
          if(!code) {
            return 'コードは必須です'
          }
          if(this.model.colors.some(color => {
            return color.code === code
          })) {
            return 'コードが重複しています'
          }
          return true
        },
      })
      .then(result => {
        this.model.colors.splice(index, 0, {code: result.value, name: '', values: []})

        // 複数JANへの対応
        this.updateJans()
      })
      .catch(() => {})
    },

    // 削除ボタン押下
    onDelete() {
      this.$confirm('削除します。よろしいですか？', '商品情報の削除', {
        type: 'warning',
      })
      .then(() => {
        this.state = 'deleting'
        this.axiosDelete('/api/items/' + this.model.id)
        .then(response => {
          this.$emit('delete', this.model.id)
          this.visible_ = false
        })
        .catch(error => {
          if(error) { // AxiosVue: nullの場合は処理不要
            if(error.response && error.response.status === 404) {
              this.$alert('この商品は削除されています。ページを更新してください。', {type: 'error'})
              this.visible_ = false
            }
            else {
              this.$alert(axiosErrMsg(error), {type: 'error'})
            }
          }
          this.state = true
        })
      })
      .catch(() => {})
    },

    // 決定ボタン押下
    onSubmit() {
      this.state = 'submitting'

      // 複数JANへの対応
      this.cleanupJans()

      // 種別毎のカテゴリーを結合する
      let category = []
      for(const [index, data] of Object.entries(this.categoryTyped)) {
        category = category.concat(data)
      }
      this.model.category = category

      // remarkChecksをremarksに変換
      const remarks = []
      for(let remark of this.current_remarks) {
        if(remark[0] === '*') {
          remark = remark.substr(1)
          remarks.push(remark)
          continue
        }
        if(remark[0] === '^') {
          remark = remark.substr(1)
        }
        if(this.remarkChecks[remark]) {
          remarks.push(remark)
        }
      }
      this.model.remarks = remarks

      this.axiosPut('/api/items/' + this.model.id, this.model)
      .then(response => {
        this.$emit('change', response.data)
        this.visible_ = false
      })
      .catch(error => {
        if(error) { // AxiosVue: nullの場合は処理不要
          if(error.response && error.response.status === 404) {
            this.$alert('この商品は削除されています。ページを更新してください。', {type: 'error'})
            this.visible_ = false
          }
          else if(this.$refs.form.processLaravelError(error)) { // laravelのvalidatorエラーを処理
            this.$alert(axiosErrMsg(error), {type: 'error'}) // または通信エラー
          }
        }
        this.state = true
      })
    },

    // キャンセルボタン押下
    onCancel() {
      this.visible_ = false
    },

    // 編集権の獲得
    async onEditGuardAcquire() {
      await this.openById(this.open_id)
    },

    // フォームが閉じる
    async onClose() {
      this.$refs.form.clearValidation()

      // 編集ガード
      await this.terminateEditGuard()
    },

    // 画面へのファイルドロップ、説明文テキストファイルインポート
    onDropFile(files, type = '') {
      const prop = 'description' + type

      if(files[0]?.name == null) { // 長さが0のfilesが送られてくることがある
        return
      }

      if(1 < files.length) {
        this.$alert('ファイルのドロップは1度に1つまででお願いいたします。')
        return
      }

      const formData = new FormData()
      formData.append('upload', files[0], files[0].name)

      const upload = formData.get('upload')
      if(upload.type !== 'text/plain') {
        this.$alert('テキストファイルのみドロップ可能です。')
        return
      }

      const reader = new FileReader()
      reader.onload = e => {
        const text = encoding.convert(new Uint8Array(e.target.result), {
          to: 'UNICODE',
          from: 'AUTO',
          type: 'string',
        })

        // 説明文の定型文挿入
        let value = this.model[prop] != null ? this.model[prop] : ''
        if(0 < value.length) {
          value += '\n'
        }
        this.model[prop] = value + text
      }
      reader.readAsArrayBuffer(upload)
    },
  },
}
</script>

<style lang="sass" scoped>
// 上部ボタン
.el-dialog__header .el-button
  margin-bottom: 5px
  &.right
    float: right !important
    margin-left: 10px
.el-dialog__wrapper :deep(.el-dialog__body)
  padding-top: 15px

// フォーム枠
.form-frame
  border: solid 1px lightgray
  border-radius: 4px
  margin-bottom: 15px
  min-width: 522px
  padding: 25px

.form-no-frame
  border: solid 1px transparent
  border-radius: 4px
  margin-bottom: 15px
  min-width: 522px
  padding-left: 25px
  padding-right: 25px

// セレクタの幅を広げる
.el-select.full
  width: 100%

// ボタン付きtextarea
.with-button
  margin-right: 10px
  vertical-align: top
  width: calc(100% - 45px)

// popover内のテーブルの折り返し禁止、選択不可、カーソル形状
.el-table.nowrap :deep(.cell)
  cursor: default
  user-select: none
  white-space: nowrap
.el-form-item:last-child
  margin-bottom: 0
.el-col
  margin-bottom: 22px
.el-row:last-child
  margin-bottom: -22px

// 画像
.item-picture
  margin-left: 16px

// サイズテーブル 単位付き入力欄
.size-cell-value
  background-color: transparent
  border: none
  box-sizing: border-box
  font-size: inherit
  height: 100%
  padding: 3px 6px 3px 18px
  vertical-align: top
  width: calc(100% - 30px)
.size-cell-unit
  background-color: transparent
  border-left: solid 1px rgb(239,243,246) // 32 25 19
  border-right: none
  border-top: none
  border-bottom: none
  color: silver
  font-size: inherit
  height: 100%
  padding: 0
  width: 30px
.size-cell-value:focus, .size-cell-unit:focus
  outline: 0

// AppTemplatePopover/el-buttonを横幅いっぱいに広げ、テキストを左詰めにする
.full-width, .full-width .el-button
  text-align: left
  width: 100%

// 編集ガードメッセージ
.edit-guard__text
  float: left
  margin-left: 8px
  margin-top: 8px
</style>

<style lang="sass">
// テーブルヘッダ
.items-form-table-column
  .cell
    padding-left: 0
    padding-right: 0
    position: relative
  .cell, .el-input, .el-input__inner
    height: 100%
  .el-input__inner
    background-color: transparent
    border: none
    padding-left: 18px
    padding-right: 18px

// 無効時セルカラー
.items-form-cell-disabled
  background-color: #eef1f6 !important
  color: #bbb !important

// 性別ラジオボタンの色を変える
.items-form-edit-radio-red.is-active .el-radio-button__inner
  background-color: red !important
  border-color: red !important

.items-form-edit-radio-yellow.is-active .el-radio-button__inner
  background-color: gold !important
  border-color: gold !important
  color: black !important

// バイト数表示
.item-form-table-dropdown-container
  display: flex
  gap: 1em

// 旧式ブランド名をグレー表示
.items-form-edit-input-grey .el-input__inner
  color: #aaa
</style>
