
<template lang="pug">
div
  DatabaseEdit(
    module="description_templates"

    bulk-deletable
    filter-props="keyword"
    root="description_templates"
    :settings="settings"
    :model="model"

    csvImport csvExport
  )
    //- 詳細フォームの編集項目
    el-form-item(prop="type" label="種別")
      el-radio-group(v-model="model.type")
        //- el-radio-button.type-button(:label="0") すべて
        el-radio-button.type-button(:label="1") 説明文1
        el-radio-button.type-button(:label="2") 説明文2
        el-radio-button.type-button(:label="3") 説明文3
        el-radio-button.type-button(:label="4") 説明文4
        el-radio-button.type-button(:label="5") 説明文5
        el-radio-button.type-button2(:label="6") 説明文6 (注意文)

    el-form-item(prop="keyword" label="キーワード")
      el-input(v-model="model.keyword" autofocus)

    el-form-item(prop="text" label="説明定型文")
      el-input(v-model="model.text" type="textarea" autosize)
</template>

<script>
import DatabaseEdit from 'lib/DatabaseEdit'

const TypeLabel = {0: 'すべて', 1: '説明文1', 2: '説明文2', 3: '説明文3', 4: '説明文4', 5: '説明文5', 6: '説明文6 (注意文)'}

export default {
  components: {DatabaseEdit},

  data() {
    return {

      settings: {
        // 一覧テーブルのカラム
        columns: [{
          title: 'キーワード',
          data: 'keyword',
          width: 500,
        }, {
          title: '説明定型文',
          data: 'text',
          width: 500,
        }, {
          title: '種別',
          data: row => TypeLabel[row.type] ?? '',
          width: 100,
        }],
      },

      // 詳細フォームのプロパティ
      model: {
        keyword: '',
        text: '',
        type: 1,
      },
    }
  },

  mounted() {
    this.$store.commit('setTitle', 'マスター/説明定型文')
  },
}
</script>

<style lang="sass" scoped>
.type-button :deep(.el-radio-button__inner)
  width: 6rem

.type-button2 :deep(.el-radio-button__inner)
  width: 8rem
</style>
