
import Vue from 'vue'

import './console-override'
import {handler} from './error-handler'
// import store from '@/store'
// import reporterNotify from './error-reporter-notify'
import reporterPost from './error-reporter-post'

// TODO: 現状 srv_env.debug_report_js を無視する(常にtrueに)
// if(srv_env.debug_report_js) {

// javascriptエラーレポート

function report(source, severity, args) {

  // if(!store.getters['config/isProduction']) {
  //   reporterNotify(source, severity, args)
  // }

  // if(store.state.config.app.error_report) {
    reporterPost(source, severity, args)
  // }
}

// error/warning出力
// VMが直接発するconsoleログはインターセプトできない

console.override.error((...args) => {
  console.former.error(...args)
  report('console.error', 'error', args)
})

console.override.warn((...args) => {
  console.former.warn(...args)
  report('console.warn', 'warning', args)
})

// Vueのerror/warningハンドラ
// consoleにそのまま送る

Vue.config.errorHandler = function(err, vm, info) { // eslint-disable-line no-unused-vars
  // console.error(err) // eslint-disable-line no-console
  handler(err)
}

Vue.config.warnHandler = function(msg, vm, trace) { // eslint-disable-line no-unused-vars
  console.warn(msg) // eslint-disable-line no-console
}

// window.onerrorのインターセプト
window.onerror = function(msg, file, line, col, err) {
  report('window.onerror', 'error', [msg, file, line, col, err])
}

// unhandledrejection/unhandledrejectionイベントのリスナ
// chrome以外では動作しない、webpack-dev-server経由の場合は動作しない

window.addEventListener('unhandledrejection', function(e) {
  report('unhandledrejection', 'error', [e.reason])
})

window.addEventListener('rejectionhandled', function(e) {
  report('rejectionhandled', 'error', [e])
})
