
<script>
import ElInput from 'element-ui/lib/input.js'

export default {
  name: 'ElInputLazy',
  extends: ElInput,

  methods: {
    handleBlur(event) {
      const value = event.target.value
      this.$emit('input', value)
      this.$emit('change', value)

      this.$emit('blur', event)
      if (this.validateEvent) {
        this.dispatch('ElFormItem', 'el.form.blur', [this.currentValue])
      }
    },

    handleInput(event) {
      const value = event.target.value
      // this.$emit('input', value)
      this.setCurrentValue(value)
      // this.$emit('change', value)
    },

  },
}
</script>
