// 複数JANへの対応
export default {
  methods: {
    setupJans(jans) {
      this.updateJans()
    },

    updateJans() {
      this.cleanupJans()
    },

    cleanupJans() {
      const jans = {}
      for (const size of this.model.sizes) {
        const sizeCode = this.normalizeCode(size.code)
        const jans0 = {}
        for (const color of this.model.colors) {
          const colorCode = this.normalizeCode(color.code)
          let value = this.model.jans?.[sizeCode]?.[colorCode]
          if (value == null) {
            value = ''
          }
          jans0[colorCode] = value
        }
        jans[sizeCode] = jans0
      }
      this.model.jans = jans
    },

    normalizeCode(code) {
      return code.replace(/^\s*-([^\s]*).*$/, '$1')
    },
  },
}
