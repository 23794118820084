
<template lang="pug">
div
  ItemsList(
    ref="list"
    @select='onSelectRow'
  )
</template>

<script>
import * as Helpers from 'lib/Helpers'
import ItemsList from './ItemsList'

export default {
  components: {ItemsList},

  methods: {

    // 商品情報がダブルクリックされた時
    onSelectRow(item) {
      Helpers.postJsonAndOpen('/pdf', [item.id])
    },
  },
}
</script>
