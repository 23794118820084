
<template lang="pug">
.rules-tree
  .rules-tree__row
    .rules-tree__branch
      .rules-tree__content
        el-select.rules-tree__branch-select(v-model="value.type")
          el-option(value="and" label="すべて")
          el-option(value="or" label="いずれか")

      .rules-tree__action
        el-dropdown.rules-tree__margin-right(
          trigger="click"
          @command="onAdd"
        )
          el-button(icon="plus" type="primary")
          el-dropdown-menu(slot="dropdown")
            el-dropdown-item(command="item") ルールを追加
            el-dropdown-item(command="branch") 階層を追加

        el-button(icon="delete" type="danger" :disabled="parent == null" @click="onDelData")

  .rules-tree__row(v-for="(child, childIndex) of value.children")
    .rules-tree__indent
      .rules-tree__indent-1
      .rules-tree__indent-2
        .rules-tree__indent-3
        .rules-tree__indent-4(v-if="childIndex < lastChildIndex")

    .rules-tree__column
      RulesTree(
        v-if="child.children != null"
        :value="child"
        :parent="value"
        @delChild="onDelChild(childIndex)"
      )

      .rules-tree__item(v-else)
        .rules-tree__content
          el-select.rules-tree__item-select(
            v-model="child.type"
            @change="onChangeItem(childIndex)"
          )
            el-option(value="type_id"     label="種別")
            el-option(value="gender"      label="性別")
            el-option(value="brand"       label="ブランド")
            el-option(value="brand_exact" label="ブランド完全一致")
            el-option(value="name"        label="商品名")
            el-option(value="function"    label="機能")
            el-option(value="catchcopy"   label="キャッチコピー")
            el-option(value="size_name"   label="サイズ名")
            el-option(value="size_value"  label="サイズ値")

          //- 種別
          template(v-if="child.type === 'type_id'")
            el-select.full(
              multiple
              v-model="child.type_id"
              placeholder="選択してください"
            )
              el-option(
                v-for="x in types"
                :key="x.id"
                :value="x.id"
                :label="x.name"
              )

          //- 性別
          template(v-else-if="child.type === 'gender'")
            el-checkbox-group(v-model="child.gender")
              el-checkbox-button.gender(
                v-for="x in genders"
                :key="x.value"
                :label="x.value"
              ) {{x.label}}

          //- ブランド
          template(v-else-if="child.type === 'brand' || child.type === 'brand_exact'")
            el-input.full(v-model="child.brand")

          //- 商品名
          template(v-else-if="child.type === 'name'")
            el-input.full(v-model="child.name")

          //- 機能
          template(v-else-if="child.type === 'function'")
            el-input.full(v-model="child.function")

          //- キャッチコピー
          template(v-else-if="child.type === 'catchcopy'")
            el-input.full(v-model="child.catchcopy")

          //- サイズ名
          template(v-else-if="child.type === 'size_name'")
            el-input.full(v-model="child.size_name")

          //- サイズ値
          template(v-else-if="child.type === 'size_value'")
            el-input(v-model="child.row")
              template(slot="prepend") 行
            el-input(v-model="child.col")
              template(slot="prepend") 列
            el-input(v-model="child.min" type="number")
              template(slot="prepend") 最小
            el-input(v-model="child.max" type="number")
              template(slot="prepend") 最大

        .rules-tree__action
          el-button(icon="delete" type="danger" @click="onDelChild(childIndex)")
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'RulesTree',

  props: {
    value: Object,
    parent: Object,
  },

  computed: {
    ...mapState({
      types: state => state.types.table,
    }),

    lastChildIndex() {
      return this.value.children.length - 1
    },

    genders() {
      return [
        {value: 'ladies', label: 'レディース'},
        {value: 'mens',   label: 'メンズ'},
        {value: 'uni',    label: '両用'},
      ]
    },
  },

  methods: {
    onAdd(cmd) {
      switch(cmd) {
        case 'branch': this.onAddBranch(); break
        case 'item':   this.onAddItem();   break
      }
    },

    onAddBranch() {
      this.value.children.push({
        type: 'and',
        children: [],
      })
    },

    onAddItem() {
      this.value.children.push({
        type: 'type_id',
        type_id: [],
      })
    },

    onDelData() {
      this.$emit('delChild')
    },

    onDelChild(childIndex) {
      this.value.children.splice(childIndex, 1)
    },

    async onChangeItem(childIndex) {

      const values = {
        type_id:    {type_id:   []},
        gender:     {gender:    []},
        brand:      {brand:     ''},
        name:       {name:      ''},
        function:   {function:  ''},
        catchcopy:  {catchcopy: ''},
        size_name:  {size_name: ''},
        size_value: {row: '', col: '', min: '', max: ''},
      }

      const child = this.value.children[childIndex]
      const value = values[child.type]
      if(value != null) {
        this.$set(this.value.children, childIndex, {
          type: child.type,
          ...value,
        })
      }
    },
  },
}
</script>

<style lang="sass">
.rules-tree *
  box-sizing: border-box

.rules-tree,
.rules-tree__column
  display: flex
  flex-direction: column
  width: 100%
  min-width: 0

.rules-tree__row
  display: flex
  flex-direction: row

.rules-tree__indent
  display: flex
  flex-direction: row
  width: 24px

.rules-tree__indent-1
  display: flex
  flex-direction: column
  width: 50%

.rules-tree__indent-2
  display: flex
  flex-direction: column
  flex-grow: 1

.rules-tree__indent-3
  border-bottom: 2px solid gray
  border-left: 2px solid gray
  height: 23px

.rules-tree__indent-4
  border-left: 2px solid gray
  flex-grow: 1

.rules-tree__branch,
.rules-tree__item
  display: flex
  flex-direction: row
  padding: 4px
  width: 100%

  &:hover
    background-color: rgba(0, 0, 255, .1)

.rules-tree__content
  display: flex
  flex-direction: row
  flex-grow: 1
  overflow: hidden

  & > *:nth-child(n + 2)
    margin-left: 8px

.rules-tree__action
  margin-left: 8px

.rules-tree__branch-select
  width: 150px
  flex-shrink: 0

.rules-tree__item-select
  width: 160px
  flex-shrink: 0

.roles-tree__select-dropdown .el-select-dropdown__item
  height: 18px
  padding-top: 4px
  padding-bottom: 4px
  line-height: 10px
  font-size: 10px

.rules-tree__margin-right
  margin-right: 8px
</style>

<style scoped lang="sass">
.full
  flex-grow: 1

.gender :deep(.el-checkbox-button__inner)
  width: 100px
</style>
