
<template lang="pug">
div
  .app-picture-input__container
    picture-input(
      ref="pictureInput"
      width="212"
      height="212"
      :crop="false"
      accept="image/jpeg,image/png"
      size="20"
      :hideChangeButton="true"
      :alertOnError="false"
      :customStrings="customStrings"
      @change="onChange"
      @error="onError"
    )
    .app-picture-input__cover(v-if="disabled")
</template>

<script>
const pica = require('pica/dist/pica')()
import PictureInput from 'vue-picture-input'

function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for(let i = 0; i < byteString.length; ++i) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], {type: mimeString})
}

export default {
  components: {PictureInput},

  props: {
    disabled: Boolean,
    visible: Boolean,
    image: String,
  },

  data() {
    return {
      customStrings: {
        upload: '<p>お使いのブラウザは非対応です。</p>',
        drag: '画像ファイルを<br>ドラッグ&ドロップ<br>または、クリックで<br>ファイル選択',
        fileSize: 'ファイルサイズが大きすぎます。',
        fileType: 'このファイル形式はサポートしていません。'
      },
    }
  },

  watch: {
    visible() {
      this.$nextTick(() => {
        this.$refs.pictureInput.onResize()
      })
    },

    image(value) {
      if(value !== this.$refs.pictureInput.image) {
        if(value !== null && value !== '') {
          this.$refs.pictureInput.preloadImage(dataURItoBlob(value))
        }
        else {
          this.$refs.pictureInput.removeImage()
        }
      }
    },
  },

  mounted() {
    if(this.image !== null && this.image !== '') {
      this.$refs.pictureInput.preloadImage(dataURItoBlob(this.image))
    }
  },

  methods: {
    onChange() {
      if(this.image !== this.$refs.pictureInput.image) {
        this.compressDataURI(this.$refs.pictureInput.image)
        .then(dataURI => {
          this.$refs.pictureInput.preloadImage(dataURItoBlob(dataURI))
          this.$emit('update:image', dataURI)
        })
      }
    },

    onError(error) {
      this.$alert(error.message, {type: 'error'})
    },

    compressDataURI(dataURI) {
      this.$store.commit('beginLoading', '処理中です...')
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = () => {

          // console.log('dataURL width=' + image.width + ' height=' + image.height + ' length=' + dataURI.length + ' data=' + dataURI.slice(0, 100))

          const maxWidth = 520 // 300DPI x 44mm
          const maxHeight = 449 // 300DPI x 38mm
          const maxBytes = 524288 // 512KBytes
          const quality = 0.92 // JPEG品質

          let width = image.width
          let height = image.height
          if(width < maxWidth && height < maxHeight && dataURI.length < maxBytes) {
            resolve(dataURI)
            this.$store.commit('endLoading')
            return
          }
          if(maxWidth < width) {
            height = height * maxWidth / width
            width = maxWidth
          }
          if(maxHeight < height) {
            width = width * maxHeight / height
            height = maxHeight
          }
          const canvas = document.createElement('canvas')
          canvas.width = width
          canvas.height = height

          pica.resize(image, canvas)
          .then(result => {
            const newDataURI = canvas.toDataURL('image/jpeg', quality)

            // console.log('newDataURI width=' + canvas.width + ' height=' + canvas.height + ' length=' + newDataURI.length + ' data=' + newDataURI.slice(0, 100))

            resolve(newDataURI)
            this.$store.commit('endLoading')
          })
        }
        image.src = dataURI
      })
    },
  },
}
</script>

<style lang="sass">
.preview-container
  border: solid 1px rgb(191, 203, 217)

.app-picture-input__container
  position: relative

.app-picture-input__cover
  bottom: 0
  left: 0
  position: absolute
  right: 0
  top: 0
  z-index: 20000
</style>
