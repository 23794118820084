
<template lang="pug">
.column.no-wrap.items-center.q-gutter-lg
  el-button(
    type="primary"
    @click="onTestLog"
  ) テスト: PHPログ出力
  el-button(
    type="primary"
    @click="onTestException"
  ) テスト: PHP例外発生
  el-button(
    type="primary"
    @click="onTestJavascriptException"
  ) テスト: Javascript例外発生
  el-button(
    type="primary"
    @click="onTestAjaxExceptionWithCatch"
  ) テスト: ajaxエラー例外発生
  el-button(
    type="primary"
    @click="onTestStacktrace"
  ) テスト: スタックトレース
</template>

<script>
import StackTrace from 'stacktrace-js'

export default {
  $http: true,

  methods: {
    async onTestLog() {
      await this.axiosPut('/api/test/log')
      this.$message('テスト: PHPログ出力 実行済み')
    },

    async onTestException() {
      await this.axiosPut('/api/test/exception')
      this.$message('テスト: PHP例外発生 実行済み')
    },

    onTestJavascriptException() {
      this.$message('テスト: Javascript例外発生 実行済み')
      throw new Error("######### テスト: Javascript例外発生 #########")
    },

    async onTestAjaxExceptionWithCatch() {
      this.$message('テスト: ajaxエラー例外発生')
      await this.axiosGet('/api/kanarazu404')
    },

    async onTestStacktrace() {
      const stack = await StackTrace.get()
      console.log(stack) // eslint-disable-line no-console
      this.$message('テスト: スタックトレース 実行済み')
    },
  },
}
</script>
