
// ErrorReporter
require('lib/error-reporter') // TODO: 現状 srv_env.debug_report_js を無視する(常にtrueに)

// axios
// window.axios = require('axios')
// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

// const token = document.head.querySelector('meta[name="csrf-token"]')
// if(token) {
//   window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
// } else {
//   console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
// }

// Laravel Echo
// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js')

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: 'your-pusher-key'
// })

// moment.js
window.moment = require('moment')
window.moment.locale('ja')

// Vue.js
import Vue from 'vue'
window.Vue = Vue

// Element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-default/index.css'
import locale from 'element-ui/lib/locale/lang/ja'
Vue.use(ElementUI, {locale})

// AxiosVue
import AxiosVue from 'lib/AxiosVue.js'
Vue.use(AxiosVue)
