
<template lang="pug">
div
  ItemsTopAdmin(v-if="isAdmin")
  ItemsTopUser(v-else-if="isUser")
  ItemsTopReadonly(v-else-if="isReadonly")
  ItemsTopPhoto(v-else-if="isPhoto")
</template>

<script>
import ItemsTopAdmin    from './ItemsTopAdmin'
import ItemsTopUser     from './ItemsTopUser'
import ItemsTopReadonly from './ItemsTopReadonly'
import ItemsTopPhoto    from './ItemsTopPhoto'

export default {
  components: {ItemsTopAdmin, ItemsTopUser, ItemsTopReadonly, ItemsTopPhoto},

  computed: {
    isAdmin() {
      return this.$store.state.user.role === 'admin'
    },

    isUser() {
      return this.$store.state.user.role === 'user'
    },

    isReadonly() {
      return this.$store.state.user.role === 'readonly'
    },

    isPhoto() {
      return this.$store.state.user.role === 'photo'
    },
  }
}
</script>
