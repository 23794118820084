
<template lang="pug">
.HotRowsel(
  :id="root"
  :style="HotBasic_style"
)
</template>

<script>
import Handsontable from 'handsontable'
import HotBasic from './HotBasic'

const doubleClickTimeInterval = 400

export default {
  name: 'HotRowsel',
  extends: HotBasic,

  created() {
    // settingsを上書き
    const owner = this
    for(const [key, value] of Object.entries({
      copyPaste: false,
      currentRowClassName: 'highlight',
      disableVisualSelection: ['current', 'area'],
      editor: false,
      fillHandle: false,

      // カラムヘッダのクリックの作用を抑止する
      beforeOnCellMouseDown(event, coords, TD, wt) {
        if(coords.row < 0) {
          Handsontable.dom.stopImmediatePropagation(event)

          if(this.getSettings().columnSorting) {
            console.warn('カラムヘッダのクリックを抑止しました。現状の実装ではcolumnSortingは動作しません。')
          }
        }
      },

      // 行のダブルクリックを検出
      afterOnCellMouseDown(event, coords, td) {
        if(0 <= coords.row) {
          if((coords.row === this.HotRowsel_rowIndex) &&
          (event.timeStamp - this.HotRowsel_timeStamp) < doubleClickTimeInterval) {
            this.HotRowsel_rowIndex = null
            owner.$emit('select', coords.row)
          }
          else {
            this.HotRowsel_rowIndex = coords.row
            this.HotRowsel_timeStamp = event.timeStamp
          }
        }
      },

      // キー入力のインターセプト
      beforeKeyDown(event) {
        if(this.isListening()) {
          switch(event.key) {

          // エンターキーを検出
          case 'Enter':
            Handsontable.dom.stopImmediatePropagation(event)
            owner.$emit('select', this.getSelected()[0])
            break

          // Delete,Backspaceでセルが空になってしまう機能を無効化
          case 'Delete':
          case 'Backspace':
            Handsontable.dom.stopImmediatePropagation(event)
            break
          }
        }
      },
    })) {
      if(this.settings[key] === undefined) {
        this.$set(this.settings, key, value)
      }
    }
  },
}
</script>

<style lang="sass">
.HotRowsel
  thead th.ht__highlight
    background-color: #f3f3f3 !important
  .highlight
    background-color: rgba(181, 209, 255, 0.34) !important
  .htBorders
    visibility: hidden
</style>
