
<template lang="pug">
DropGuard
  el-dialog.header-fixed(
    size="full"
    :visible.sync="visible_"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    @close="onClose"
  )
    template(slot="title")

      el-button.no-focus.right(
        :disabled="state !== true"
        @click="onCancel"
      ) 閉じる

      template(v-if="editGuard_state === null")

        el-button.no-focus.right(
          type="primary"
          icon="check"
          :loading="state === 'submitting'"
          :disabled="state !== 'submitting' && state !== true"
          @click="onSubmit"
        ) 決定

      template(v-else-if="editGuard_state === false")

        el-button.no-focus.right(
          type="info"
          @click="onEditGuardAcquire"
        ) 編集

        .edit-guard__text 編集を開始できます

      template(v-else)

        .edit-guard__text {{editGuard_state}}が編集中です

    el-form-ex(
      ref="form"
      label-width="110px"
      :model="model"
      @submit.prevent.native="onSubmit"
    )
      .form-frame
        el-row
          //- 入荷・検品
          el-col(:span="12" :md="6")
            el-form-item(prop="checked_0" label="入荷・検品" label-width="100px")
              AppProgrssCheck(
                :msg="msg"
                :value.sync="model.checked_0"
                :date.sync="model.checked_0_at"
                :user.sync="model.checked_0_by"
                disabled
              )

          //- データ記入
          el-col(:span="12" :md="6")
            el-form-item(prop="checked_1" label="データ記入" label-width="100px")
              AppProgrssCheck(
                :msg="msg"
                :value.sync="model.checked_1"
                :date.sync="model.checked_1_at"
                :user.sync="model.checked_1_by"
                disabled
              )

          //- コメント記入
          el-col(:span="12" :md="6")
            el-form-item(prop="checked_2" label="コメント記入" label-width="100px")
              AppProgrssCheck(
                :msg="msg"
                :value.sync="model.checked_2"
                :date.sync="model.checked_2_at"
                :user.sync="model.checked_2_by"
                disabled
              )

          //- 撮影
          el-col(:span="12" :md="6")
            el-form-item(prop="checked_3" label="撮影" label-width="100px")
              AppProgrssCheck(
                :msg="msg"
                :value.sync="model.checked_3"
                :date.sync="model.checked_3_at"
                :user.sync="model.checked_3_by"
                :disabled="formDisabled"
              )

          //- CSV出力済
          el-col(:span="12" :md="{span:6, push:12}")
            el-form-item(prop="exported" label="CSV出力済" label-width="100px")
              AppProgrssCheck(
                :msg="msg"
                :value.sync="model.exported"
                :date.sync="model.exported_at"
                :user.sync="model.exported_by"
                disabled
              )

          //- PDF出力済
          el-col(:span="12" :md="{span:6, push:12}")
            el-form-item(prop="printed" label="PDF出力済" label-width="100px")
              AppProgrssCheck(
                :msg="msg"
                :value.sync="model.printed"
                :date.sync="model.printed_at"
                :user.sync="model.printed_by"
                disabled
              )

      .form-no-frame
        el-row
          //- 要確認
          el-col(:span="24")
            el-form-item(prop="annotation" label="要確認")
              el-input-lazy(
                type="textarea"
                autosize
                v-model="model.annotation"
                disabled
              )

      .form-frame
        el-row
          //- 画像について
          el-col(:span="24")
            el-form-item(prop="about_image" label="画像について")
              el-input-lazy(
                v-model="model.about_image"
                disabled
              )

        el-row
          //- 画像
          el-col(:span="24" :md="19")
            el-row
              //- 性別
              el-col(:span="24" :md="12")
                el-form-item(prop="gender" label="性別")
                  el-input-lazy(
                    v-model.trim="gender_disp"
                    disabled
                  )

              //- ブランド
              el-col(:span="24" :md="12")
                el-form-item(prop="brand" label="ブランド")
                  el-input-lazy(
                    v-model.trim="model.brand"
                    disabled
                  )

            el-row
              //- 商品番号
              el-col(:span="24" :md="12")
                el-form-item(prop="code" label="商品番号")
                  el-input-lazy(
                    v-model.trim="model.code"
                    disabled
                  )

        el-row
          //- 商品名
          el-col(:span="24")
            el-form-item(prop="name" label="商品名")
              el-input-lazy(
                v-model="model.name"
                disabled
              )

        el-row
          //- 機能
          el-col(:span="24")
            el-form-item(prop="function" label="機能")
              el-input-lazy(
                v-model="model.function"
                disabled
              )

        //- カラーテーブル
        el-row
          el-col(:span="24")
            el-table(:data="model.colors" border)

              el-table-column(label="カラー名" width="110" prop="name" fixed)
              el-table-column(label="カラー" width="100" prop="code" fixed)

              el-table-column(
                v-for="(size, index) in model.sizes"
                :key="index"
                :label="size.name"
                :prop="'values.' + index"
                width="60"
              )

              el-table-column

              div(slot="empty") カラーがありません

        //- 説明文1
        el-row
          el-col(:span="24")
            el-form-item(prop="description" label="説明文1" label-width="100px")
              el-input-lazy(
                type="textarea"
                autosize
                v-model="model.description"
                disabled
              )

        //- 説明文2
        el-row
          el-col(:span="24")
            el-form-item(prop="description2" label="説明文2" label-width="100px")
              el-input-lazy(
                type="textarea"
                autosize
                v-model="model.description2"
                disabled
              )

        //- 説明文3
        el-row
          el-col(:span="24")
            el-form-item(prop="description3" label="説明文3" label-width="100px")
              el-input-lazy(
                type="textarea"
                autosize
                v-model="model.description3"
                disabled
              )

        //- 説明文4
        el-row
          el-col(:span="24")
            el-form-item(prop="description4" label="説明文4" label-width="100px")
              el-input-lazy(
                type="textarea"
                autosize
                v-model="model.description4"
                disabled
              )

        //- 説明文5
        el-row
          el-col(:span="24")
            el-form-item(prop="description5" label="説明文5" label-width="100px")
              el-input-lazy(
                type="textarea"
                autosize
                v-model="model.description5"
                disabled
              )

        //- 説明文6 (注意文)
        el-row
          el-col(:span="24")
            el-form-item(prop="description6" label="説明文6 (注意文)" label-width="100px")
              el-input-lazy(
                type="textarea"
                autosize
                v-model="model.description6"
                disabled
              )
</template>

<script>
import {mapState} from 'vuex'
import ElFormEx from 'lib/ElFormEx'
import ElInputLazy from 'lib/ElInputLazy'
import DropGuard from 'lib/DropGuard'
import AppProgrssCheck from './AppProgrssCheck'

import EditGuardMixin from '@/lib/EditGuardMixin.js'

export default {
  components: {ElFormEx, AppProgrssCheck, ElInputLazy, DropGuard},
  mixins: [EditGuardMixin],

  props: {
    visible: Boolean,
  },

  data() {
    return {
      open_id: null,

      msg: 0,

      visible_: this.visible,

      state: true,

      model: {
        id: null,

        checked_0: null,
        checked_0_at: null,
        checked_0_by: null,

        checked_1: null,
        checked_1_at: null,
        checked_1_by: null,

        checked_2: null,
        checked_2_at: null,
        checked_2_by: null,

        checked_3: null,
        checked_3_at: null,
        checked_3_by: null,

        exported: null,
        exported_at: null,
        exported_by: null,

        printed: null,
        printed_at: null,
        printed_by: null,

        about_image: null,

        picture: null,

        type_id: null,

        sinsaku_hyouki: null,

        gender: null,
        brand: null,

        code: null,
        jan: null,

        price: null,
        price_tax: null,

        name: null,
        function: null,
        catchcopy: null,
        catchcopy2: null,
        catchcopy3: null,
        material: null,
        maker_size: null,

        size_columns: [],
        sizes: [],

        rakuten_nouki: null,
        haisou_houhou: null,
        hyouji_jyunjo: null,

        remarks: {},

        colors: [],

        description: null,
        description2: null,
        description3: null,
        description4: null,
        description5: null,
        description6: null,
        category: [],
        rakudir_code: null,
        annotation: null,
      },

      sinsakuHyoukiOptions: [],
      priceLink: true,
    }
  },

  computed: {
    ...mapState({
      types:                 state => state.types.table,
      brands:                state => state.brands.table,
      categories:            state => state.categories.table,
      description_templates: state => state.description_templates.table,
      size_templates:        state => state.size_templates.table,
      rakudir:               state => state.rakudir.table,
    }),

    // フォーム全体の無効化
    formDisabled() {
      return this.editGuard_state !== null
    },

    gender_disp() {
      switch(this.model.gender) {
        case 'ladies':
          return 'レディース'
        case 'mens':
          return 'メンズ'
        case 'uni':
          return '両用'
      }
      return this.model.gender
    },
  },

  watch: {
    visible(value) {this.visible_ = value},
    visible_(value) {this.$emit('update:visible', value)},
  },

  created() {
    // 初期modelのJSON
    this.initialModelJSON = JSON.stringify(this.model)
  },

  mounted() {
    this.axiosAutoCancel()
  },

  methods: {
    // フォームを開く
    async open(item) {
      await this.openById(item.id)
    },

    async openById(item_id, init_state = false) {
      this.open_id = item_id
      this.axiosGet('/api/items/' + item_id)
      .then(async response => {

        // 編集ガード
        await this.acquireEditGuard('sheets-' + item_id, init_state)

        //
        this.visible_ = true
        this.state = true

        const model = JSON.parse(this.initialModelJSON)
        for(const key of Object.keys(model)) {
          if(response.data[key] != null) {
            model[key] = response.data[key]
          }
        }
        this.model = model

        // サブコンポーネントにフォームが開かれたことを通知する
        ++this.msg
      })
      .catch(error => {
        if(error) { // AxiosVue: nullの場合は処理不要
          if(error.response && error.response.status === 404) {
            this.$alert('この商品は削除されています。ページを更新してください。', {type: 'error'})
          }
          else {
            this.$alert(axiosErrMsg(error), {type: 'error'})
          }
        }
      })
    },

    // 決定ボタン押下
    onSubmit() {
      this.state = 'submitting'

      this.axiosPut('/api/items/' + this.model.id, this.model)
      .then(response => {
        this.$emit('change', response.data)
        this.visible_ = false
      })
      .catch(error => {
        if(error) { // AxiosVue: nullの場合は処理不要
          if(error.response && error.response.status === 404) {
            this.$alert('この商品は削除されています。ページを更新してください。', {type: 'error'})
            this.visible_ = false
          }
          else if(this.$refs.form.processLaravelError(error)) { // laravelのvalidatorエラーを処理
            this.$alert(axiosErrMsg(error), {type: 'error'}) // または通信エラー
          }
        }
        this.state = true
      })
    },

    // キャンセルボタン押下
    onCancel() {
      this.visible_ = false
    },

    // 編集権の獲得
    async onEditGuardAcquire() {
      await this.openById(this.open_id, null)
    },

    // フォームが閉じる
    async onClose() {
      this.$refs.form.clearValidation()

      // 編集ガード
      await this.terminateEditGuard()
    },
  },
}
</script>

<style lang="sass" scoped>
// DISABLEDなAppProgrssCheckを見かけ上DISABLEDにしない
.app-progress-check :deep(.el-checkbox__input.is-disabled)
  .el-checkbox__inner
    background-color: #fff
    border-color: #bfcbd9
  &.is-checked .el-checkbox__inner
    background-color: #20a0ff
    border-color: #0190fe
  &+.el-checkbox__label
    color: #1f2d3d

// DISABLEDなel-inputを見かけ上DISABLEDにしない
.el-input.is-disabled :deep(.el-input__inner),
.el-textarea.is-disabled :deep(.el-textarea__inner)
  background-color: #fff
  border-color: #bfcbd9
  color: #1f2d3d

// 上部ボタン
.el-dialog__header .el-button
  margin-bottom: 5px
  &.right
    float: right !important
    margin-left: 10px
.el-dialog__wrapper :deep(.el-dialog__body)
  padding-top: 15px

// フォーム枠
.form-frame
  border: solid 1px lightgray
  border-radius: 4px
  margin-bottom: 15px
  min-width: 522px
  padding: 25px

.form-no-frame
  border: solid 1px transparent
  border-radius: 4px
  margin-bottom: 15px
  min-width: 522px
  padding-left: 25px
  padding-right: 25px

// popover内のテーブルの折り返し禁止、選択不可、カーソル形状
.el-form-item:last-child
  margin-bottom: 0
.el-col
  margin-bottom: 22px
.el-row:last-child
  margin-bottom: -22px

// 編集ガードメッセージ
.edit-guard__text
  float: left
  margin-left: 8px
  margin-top: 8px
</style>
