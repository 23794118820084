
<template lang="pug">
div
  .database-edit__status-container
    .database-edit__status-group
      div
        template(v-if="table.length")
          template(v-if="settings_.data.length < table.length")
            |全{{table.length}}件中の{{settings_.data.length}}件
          template(v-else)
            |全{{table.length}}件
        template(v-else)
          |データがありません

    .database-edit__status-group
      el-input.database-edit__status-search(
        v-if="filterProps_"
        :value="filterText"
        size="small"
        icon="circle-cross"
        :on-icon-click="() => filterText = ''"
        @blur="filterText = $event.target.value"
        @keyup.enter.native="filterText = $event.target.value"
      )
        template(slot="prepend") 検索

      el-button.no-focus(
        size="small"
        @click="refresh"
      ) 更新

      //- CSVエクスポート機能
      el-dropdown(
        v-if="csvExport"
        trigger="click"
        @command="onClickExportDropdown"
      )
        el-button.no-focus(
          size="small"
          type="success"
        ) エクスポート&nbsp;
          i.el-icon-caret-bottom
        el-dropdown-menu(slot="dropdown")
          el-dropdown-item.right(
            command="csv-export"
          ) CSV

      el-button.no-focus(
        v-if="bulkDeletable"
        size="small"
        type="danger"
        :disabled="!selection"
        icon="delete"
        @click="onClickDelete"
      ) 削除

      el-button.no-focus(
        size="small"
        type="primary"
        icon="plus"
        @click="onClickCreate"
      ) 新規作成

  HotRowsel(
    ref="hot"
    :root="root"
    :settings="settings_"
    window-height="auto"
    @select="onSelectRow"
  )

  DialogForm(
    ref="form"
    :visible.sync="dialogVisible"
    :size="size"
    :top="top"
    :model="model"
    :rules="rules"
    :label-width="labelWidth"
    :hide-delete="editingId == null"
    @open="onFormOpen"
    @close="onFormClose"
    @delete="onFormDelete"
    @submit="onFormSubmit"
  )
    slot

  //- CSVインポート機能
  VueFullScreenFileDrop(
    v-if="csvImport"
    text="CSVをインポート"
    @drop="onDropFile"
  )
</template>

<script>
const FileSaver = require('file-saver')
import {cloneDeep, range} from 'lodash-es'
import * as Helpers from 'lib/Helpers'
import HotRowsel from 'lib/HotRowsel'
import DialogForm from 'lib/DialogForm'
import VueFullScreenFileDrop from 'vue-full-screen-file-drop'
import { openDialogImportError } from 'lib/DialogImportError.js'

export default {
  name: 'DatabaseEdit',
  components: {HotRowsel, DialogForm, VueFullScreenFileDrop},

  props: {
    module: String,

    bulkDeletable: Boolean,                   // 一括デリート対応
    filterProps: String,                      // フィルタプロパティ

    root: String,                             // hot root名
    settings: Object,                         // hot 設定
    size: {type: String, default: 'large'},   // dialog tiny/small/large/full
    top: {type: String, default: '5%'},       // dialog y位置、'15%'
    model: Object,                            // form モデル
    rules: Object,                            // form バリデータ
    labelWidth: String,                       // form ラベル幅、'200px"

    // csvインポート/エクスポート機能
    csvExport: Boolean,                        // csvエクスポート機能
    csvImport: Boolean,                        // csvインポート機能
  },

  data() {
    return {
      dialogVisible: false,     // ダイアログ表示状態

      filterText: '',
      selection: null,
      editingId: null,

      settings_: Object.assign({
        data: [],

        manualColumnResize: true,
        outsideClickDeselects: false,
        persistentState: true,
        rowHeaders: true,
        undo: false,
        wordWrap: false,

        // columnSorting: true, // 使うとメモリリークを起こす
        // sortIndicator: true, // columnSortingで使う

        // テーブルの選択状態の更新
        afterSelectionEnd: (r, c, r2, c2) => {
          this.selection = {begin: r, end: r2}
        },
        afterDeselect: () => {
          this.selection = null
        },
      }, this.settings),
    }
  },

  computed: {
    filterProps_() {
      return this.filterProps ? this.filterProps.split(',') : null
    },

    table() {
      return this.$store.state[this.module].table
    },

    filteredTable() {
      let table = this.table
      if(this.filterProps_) {
        const filterText = this.filterText.trim()
        if(filterText) {
          table = Helpers.filter(table, filterText, this.filterProps_)
        }
      }
      return table
    },
  },

  watch: {
    filteredTable(value) {
      this.settings_.data = value
    },
  },

  created() {
    this.initialModel = cloneDeep(this.model)
  },

  mounted() {
    // テーブルを更新する
    this.$nextTick(() => {
      this.refresh(true)
    })
  },

  methods: {
    // テーブルを更新する
    refresh(mounted = false) {
      this.$store.dispatch(this.module + '/index')
      .then(() => {
        // 初回のみ行を選択する
        if(mounted === true) {
          this.$nextTick(() => {
            this.$refs.hot.table.selectCell(0, 0)
          })
        }
      })
      .catch(error => {
        if(error) { // AxiosVue: nullの場合は処理不要
          this.$alert(axiosErrMsg(error), {type: 'error'})
        }
      })
    },

    // 削除ボタン押下
    onClickDelete() {
      const begin = Math.min(this.selection.begin, this.selection.end)
      const end = Math.max(this.selection.begin, this.selection.end) + 1
      this.$confirm('選択中の' + (end - begin) + '件を削除します。 よろしいですか？', '削除', {
        type: 'warning',
      })
      .then(() => {
        this.$store.dispatch(this.module + '/delete',
          range(begin, end).map(n => this.settings_.data[this.$refs.hot.table.toPhysicalRow(n)].id)
        )
        .then(() => {
          this.$refs.hot.table.deselectCell()
        })
        .catch(error => {
          if(error) { // AxiosVue: nullの場合は処理不要
            this.$alert(axiosErrMsg(error), {type: 'error'})
          }
        })
      })
      .catch(() => {})
    },

    // 新規作成ボタン押下
    onClickCreate() {
      this.editingId = null
      const model = cloneDeep(this.initialModel)
      for(const key of Object.keys(this.model)) {
        this.model[key] = model[key]
      }
      this.dialogVisible = true
    },

    // テーブルの行が選択された時
    onSelectRow(visualRowIndex) {
      const row = cloneDeep(this.settings_.data[this.$refs.hot.table.toPhysicalRow(visualRowIndex)])
      this.editingId = row.id
      const model = cloneDeep(this.initialModel)
      for(const key of Object.keys(this.model)) {
        const value = row[key]
        this.model[key] = value != null ? value : model[key]
      }
      this.dialogVisible = true
      this.$emit('select-row')
    },

    // フォームが開く時、handsontableのフォーカスを外す
    onFormOpen() {
      this.$refs.hot.table.unlisten()
    },

    // フォームが閉じる時、handsontableにフォーカスを戻す
    onFormClose() {
      this.$refs.form.clearValidation()
      this.$refs.hot.table.listen()
    },

    // フォームの削除ボタン
    onFormDelete() {
      this.$store.dispatch(this.module + '/delete', this.editingId)
      .then(() => {
        this.$refs.hot.table.deselectCell()
        this.dialogVisible = false
      })
      .catch(error => {
        if(error) { // AxiosVue: nullの場合は処理不要
          this.$alert(axiosErrMsg(error), {type: 'error'})
        }
        this.dialogVisible = true
      })
    },

    // フォームの決定ボタン
    onFormSubmit() {
      // フォームのバリデーション
      this.$refs.form.validate(valid => {
        if(!valid) {
          this.dialogVisible = true
          return
        }
        // リクエスト
        let request
        if(this.editingId === null) {
          // 新規作成
          request =
          this.$store.dispatch(this.module + '/store', this.model)
          .then(response => {
            // 行を選択する
            const i = this.filteredTable.findIndex(r => r.id === response.data.id)
            if(i !== -1) {
              this.$refs.hot.table.selectCell(this.$refs.hot.table.toVisualRow(i), 0)
            }
          })
        }
        else {
          // 更新
          request =
          this.$store.dispatch(this.module + '/update', {id: this.editingId, row: this.model})
        }
        request
        .then(() => {
          this.$emit('change')
          this.dialogVisible = false
        })
        // 送信失敗
        .catch(error => {
          if(this.$refs.form.processLaravelError(error)) { // laravelのvalidatorエラーを処理
            this.$alert(axiosErrMsg(error), {type: 'error'}) // または通信エラー
          }
          this.dialogVisible = true
        })
      })
    },

    // CSVエクスポート機能

    onClickExportDropdown(command) {
      switch(command) {
        case 'csv-export':
          this.onClickCsvExport()
          break;
      }
    },

    async onClickCsvExport() {
      const response = await this.axiosPost(`/api/${this.module}/csv_export`, undefined, {responseType: 'blob'})

      let filename = `${this.module}.csv`
      const content_disposition = response.headers['content-disposition']
      if (content_disposition) {
        const filename_raw = content_disposition.split(';').map((x) => x.trim().split('=')).find((x) => x[0] === 'filename')?.[1]
        if (filename_raw) {
          filename = decodeURI(filename_raw.replace(/^"|"$/g, ''))
        }
      }

      FileSaver.saveAs(response.data, filename)
    },

    // CSVインポート機能

    async onDropFile(formData, files) {
      if(files[0]?.name == null) { // 長さが0のfilesが送られてくることがある
        return
      }

      if(1 < files.length) {
        await this.$alert('ファイルのドロップは1度に1つまででお願いいたします。')
        return
      }

      const upload = formData.get('upload') // 未対応のオブジェクト
      if(!upload.type) {
        await this.$alert('未対応のファイル形式です')
        return
      }

      try {
        const response = await this.axiosPost(`/api/${this.module}/csv_import`, formData)

        const { line, insertCount, updateCount } = response.data

        let text = line + '行を読み込みました。'

        if (insertCount || updateCount) {
          let texts = []
          if (insertCount) {
            texts.push(insertCount + '件追加')
          }
          if (updateCount) {
            texts.push(updateCount + '件更新')
          }
          text += texts.join('、') + 'しました。'
        } else {
          text += '変更はありません。'
        }

        this.refresh(true)

        await this.$alert(text, 'csv読み込み成功', {type: 'success'})
      } catch(error) {
        if (error) { // AxiosVue: nullの場合は処理不要
          let text
          if(error.response?.status === 422) {
            const errors = error.response?.data?.errors
            if (errors != null) {
              await openDialogImportError('csvに問題があります', errors)
              return
            }
            text = '未対応のファイル形式です'
          }
          else {
            text = axiosErrMsg(error)
          }
          await this.$alert(text, 'インポート', {type: 'error'})
        }
      }
    },
  },
}
</script>

<style lang="sass">
.database-edit
  &__status-
    &container
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      align-items: center
      padding: 0 8px
    &group
      display: flex
      flex-wrap: wrap
      align-items: center
      & > *
        margin-bottom: 8px
      &:nth-child(n + 2), & > *:nth-child(n + 2)
        margin-left: 15px
    &search
      width: 300px
</style>
