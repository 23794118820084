
<template lang="pug">
div
  DatabaseEdit(
    module="categories"

    bulk-deletable
    filter-props="name"
    root="categories"
    :settings="settings"
    :model="model"

    csvImport csvExport
  )
    el-form-item(prop="name" label="カテゴリ名")
      el-input(v-model.trim="model.name" autofocus)

    el-form-item(prop="type" label="種別")
      el-select.full(
        v-model.trim="model.type"
        placeholder="選択してください"
      )
        el-option(
          v-for="n in 4"
          :key="n"
          :value="n"
          :label="n"
        )

    el-form-item(prop="category_set" label="カテゴリセット管理番号")
      el-input(v-model.trim="model.category_set")

    el-form-item(prop="breadcrumb_html" label="パンくずHTML")
      el-input(
        type="textarea"
        autosize
        v-model.trim="model.breadcrumb_html"
      )

    el-form-item(prop="banner_pc_html" label="バナーHTML(PC用)")
      el-input(
        type="textarea"
        autosize
        v-model.trim="model.banner_pc_html"
      )

    el-form-item(prop="banner_smartphone_html" label="バナーHTML(スマホ用)")
      el-input(
        type="textarea"
        autosize
        v-model.trim="model.banner_smartphone_html"
      )
</template>

<script>
import DatabaseEdit from 'lib/DatabaseEdit'

export default {
  components: {DatabaseEdit},

  data() {
    return {

      settings: {
        // 一覧テーブルのカラム
        columns: [{
          title: 'カテゴリ名',
          data: 'name',
          width: 900,
        },{
          title: '種別',
          data: 'type',
          width: 100,
        },{
          title: 'カテゴリセット管理番号',
          data: 'category_set',
          width: 100,
        },{
          title: 'パンくずHTML',
          data: 'breadcrumb_html',
          width: 300,
        },{
          title: 'バナーHTML(PC用)',
          data: 'banner_pc_html',
          width: 300,
        },{
          title: 'バナーHTML(スマホ用)',
          data: 'banner_smartphone_html',
          width: 300,
        }],
      },

      // 詳細フォームのプロパティ
      model: {
        name: '',
        type: null,
        category_set: '',
        breadcrumb_html: '',
        banner_pc_html: '',
        banner_smartphone_html: '',
      },
    }
  },

  mounted() {
    this.$store.commit('setTitle', 'マスター/カテゴリ')
  },

}
</script>

<style lang="sass" scoped>
// セレクタの幅を広げる
.el-select.full
  width: 100%
</style>
