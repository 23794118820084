
<script>
import ElForm from 'element-ui/lib/form.js'

export default {
  name: 'ElFormEx',
  extends: ElForm,

  methods: {
    clearValidation() {
      // el-form-itemのバリデーションエラー表示のみを更新する
      // el-form-itemの内部実装に依存しているので注意
      for(const item of this.fields) {
        item.validateState = ''
        item.validateMessage = ''
      }
    },

    processLaravelError(error) {
      if(error == null) {
        return false
      }
      if(error.response == null || error.response.status !== 422 || error.response.data == null) {
        return true
      }
      let process = true
      let focus = false
      if(error.response.data.errors != null) {
        const errors = error.response.data.errors
        // el-form-itemのバリデーションエラー表示のみを更新する
        // el-form-itemの内部実装に依存しているので注意
        for(const item of this.fields) {
          if(errors[item.prop]) {
            item.validateState = 'error'
            item.validateMessage = errors[item.prop][0]
            // 現バージョンのel-form-itemはrulesが全く設定されていない場合では
            // blurとchangeイベントをトリガーとしてバリデーションを「実行」し
            // 勝手に無条件に「成功」させてしまうので、それを抑止する。
            item.$off('el.form.blur')
            item.$off('el.form.change')
          }
          else {
            item.validateState = ''
            item.validateMessage = ''
          }
        }
        process = false
        focus = true
      }
      if(error.response.data.alert != null) {
        this.$alert(error.response.data.alert, {type: 'error'})
        process = false
        focus = false
      }
      if(focus) {
        this.$nextTick(() => {
          this.focusError()
        })
      }
      return process
    },

    focusError() {
      // エラー要素があればその最初の要素にフォーカスをあてる
      const el = this.$el.querySelector('.el-form-item.is-error input')
      if(el) {
        el.focus()
      }
    },
  },
}
</script>
