
<template lang="pug">
div
  el-dialog(
    :visible="visible_ !== false"
    :title="title"
    :size="size"
    :top="top"
    :close-on-click-modal="false"
    :close-on-press-escape="visible_ === true"
    :show-close="false"
    @update:visible="onUpdateVisible"
    @open="onOpen"
    @close="onClose"
  )
    el-form-ex(
      v-if="visible_ !== false"
      ref="form"
      :model="model"
      :rules="rules"
      :label-width="labelWidth"
      @submit.prevent.native="onSubmit"
    )
      slot

    span(slot="footer")
      el-button.no-focus(
        v-if="!hideDelete"
        type="danger"
        :loading="visible_ === 'deleting'"
        :disabled="visible_ !== 'deleting' && visible_ !== true"
        icon="delete"
        @click="onDelete"
      ) 削除

      el-button.no-focus(
        type="primary"
        :loading="visible_ === 'submitting'"
        :disabled="visible_ !== 'submitting' && visible_ !== true"
        icon="check"
        @click="onSubmit"
      ) 決定

      el-button.no-focus(
        :disabled="visible_ !== true"
        @click="onCancel"
      ) キャンセル
</template>

<script>
import ElFormEx from './ElFormEx'

export default {
  name: 'DialogForm',
  components: {ElFormEx},

  props: {
    visible: [Boolean, String], // false/true/'deleting'/'submitting'
    title: String,              // dialog タイトル文字列
    size: String,               // dialog tiny/small/large/full
    top: String,                // dialog y位置、'15%'
    model: Object,              // form モデル
    rules: Object,              // form バリデータ
    labelWidth: String,         // form ラベル幅、'200px"
    hideDelete: Boolean,        // 削除ボタンを隠したい場合
  },

  data() {
    return {
      visible_: this.visible,
    }
  },

  watch: {
    visible(value) {this.visible_ = value},
    visible_(value) {this.$emit('update:visible', value)},
  },

  methods: {
    onUpdateVisible(value) {
      if(!value) {
        this.visible_ = false
      }
    },

    onOpen() {
      // ダイアログを開いた時にautofocus属性を持つ入力要素にフォーカスをあてる
      // この時少し待たないとうまくいかない
      setTimeout(() => {
        const el = this.$refs.form.$el.querySelector('[autofocus]')
        if(el) {
          el.focus()
        }
      }, 1)
      this.$emit('open')
    },

    onClose() {
      this.$emit('close')
    },

    onDelete() {
      this.$confirm(
        'このデータを削除します。よろしいですか？',
        undefined, {type: 'warning'})
      .then(() => {
        this.visible_ = 'deleting'
        this.$nextTick(() => {
          this.$emit('delete')
        })
      })
      .catch(() => {})
    },

    onSubmit() {
      this.visible_ = 'submitting'
      this.$nextTick(() => {
        this.$emit('submit')
      })
    },

    onCancel() {
      this.visible_ = false
    },

    clearValidation() {
      if(this.$refs.form) {
        this.$refs.form.clearValidation()
      }
    },

    processLaravelError(error) {
      return this.$refs.form.processLaravelError(error)
    },

    validate(callback) {
      this.$refs.form.validate(valid => {
        if(!valid) {
          this.$refs.form.focusError()
        }
        callback(valid)
      })
    },
  },
}
</script>
