
<template lang="pug">
.root
  el-popover(
    :width="totalWidth"
    :placement="placement"
    @show="onShow"
  )
    .header
      el-input(
        :value="filterText"
        placeholder="検索"
        size="small"
        icon="circle-cross"
        :on-icon-click="() => filterText = ''"
        @blur="filterText = $event.target.value"
        @keyup.enter.native="filterText = $event.target.value"
      )

    .content(:style="bodyStyle")
      HotRowsel(
        ref="hot"
        :settings="settings"
        @select="selectRow"
      )

    template(slot="reference")
      slot(name="reference")
</template>

<script>
import {filter as doFilter} from 'lib/Helpers'
import HotRowsel from 'lib/HotRowsel'

export default {
  components: {HotRowsel},

  props: {
    data: Array,
    prop: {type: [String, Array], default: 'text'},
    width: {type: [String, Number, Array], default: 500},
    maxHeight: {type: String, default: '400px'},
    placement: {type: String, default: 'bottom'},
  },

  data() {
    return {
      bodyStyle: {
        maxHeight: this.maxHeight,
      },

      settings: {
        data: [],
        columns: [],
        rowHeights: 40,

        multiSelect: false,
        undo: false,
        wordWrap: false,
      },

      totalWidth: 0,
      filterText: '',
    }
  },

  computed: {
    prop_array() {
      return Array.isArray(this.prop) ? this.prop : [this.prop]
    },

    width_array() {
      return Array.isArray(this.width) ? this.width : [this.width]
    },

    filteredTable() {
      let data = this.data

      // ハードコード: keyword + text で結合してkeywordとする
      if(this.prop === 'keyword') {
        data = data.map(e => ({
          id: e.id,
          keyword: e.keyword + ' - ' + e.text,
          text: e.text,
        }))
      }

      //
      const filterText = this.filterText.trim()
      if(filterText) {
        data = doFilter(data, filterText, this.prop_array)
      }
      return data
    },
  },

  watch: {
    prop(value) {
      this.updateColumns()
    },

    width(value) {
      this.updateColumns()
    },

    filteredTable(data) {
      this.$set(this.settings, 'data', data)
      this.$refs.hot.table.render()
    },
  },

  beforeMount() {
    this.updateColumns()
  },

  mounted() {
    this.$set(this.settings, 'data', this.filteredTable)
  },

  methods: {

    // popoverを表示する
    onShow() {
      this.$refs.hot.table.render()
      this.$emit('show')
    },

    // カラム設定を作成する
    updateColumns() {
      this.settings.columns = []
      this.totalWidth = 0
      for(const [i, prop] of this.prop_array.entries()) {
        const width = this.width_array[i % this.width_array.length]
        this.settings.columns.push({
          data: prop,
          // width: width - 15,
        })
        this.totalWidth += width
      }
    },

    // 行が選択された
    selectRow(rowIndex) {
      rowIndex = this.$refs.hot.table.toPhysicalRow(rowIndex)
      this.$emit('select', this.settings.data[rowIndex])
    },
  },
}
</script>

<style lang="sass" scoped>
.root
  display: inline-block

.header
  position: relative
  z-index: 110
  border: solid 1px #dfe6ec
  padding: 5px 18px
  background-color: #eef1f6
  line-height: 30px
  font-size: 14px

.content
  margin-top: -1px
  overflow: hidden
  :deep(td)
    border-color: #dfe6ec !important
    vertical-align: middle
    font-size: 14px
</style>
