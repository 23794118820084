
import Vue from 'vue'

import axios_laravel_csrf from 'lib/axios-laravel-csrf'
window.axios = axios_laravel_csrf()

// ローディング状態通知
let loadingStateCallback = null

function setLoadingState(state) {
  if(loadingStateCallback != null) {
    loadingStateCallback(state)
  }
}

// リクエストインターセプト
axios.interceptors.request.use(
  config => {
    if(config?.disableLoadingState != true) {
      setLoadingState(true)
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// レスポンスインターセプト
axios.interceptors.response.use(
  response => {
    if(response?.config?.disableLoadingState != true) {
      setLoadingState(false)
    }
    return response
  },
  error => {
    if(error?.config?.disableLoadingState != true) {
      setLoadingState(false)
    }
    // 通信キャンセル
    if(axios.isCancel(error)) {
      console.warn('通信キャンセル')
      return Promise.reject(null)
    }
    if(error.response) {
      // 401 認証無効
      if(error.response.status === 401) {
        console.warn('401 認証無効')
        location.href = '/login'
        return Promise.reject(null)
      }
      // 503 メンテナンス中
      if(error.response.status === 503) {
        console.warn('503 メンテナンス中')
        location.reload()
        return Promise.reject(null)
      }
    }
    return Promise.reject(error)
  }
)

// リクエストメソッド
const request_methods = {}

function methodName(method) {
  return 'axios' + method.charAt(0).toUpperCase() + method.slice(1)
}

function modifyConfig(config, cancelTokenSource) {
  if(cancelTokenSource != null) {
    if(config == null) {
      config = {}
    }
    if(config.cancelToken == null) {
      config.cancelToken = cancelTokenSource.token
    }
  }
  return config
}

for(const method of ['delete', 'get', 'head', 'options']) {
  request_methods[methodName(method)] = function(url, config) {
    return axios[method](url, modifyConfig(config, this.$_AxiosVue_cancelTokenSource))
  }
}

for(const method of ['post', 'put', 'patch']) {
  request_methods[methodName(method)] = function(url, data, config) {
    return axios[method](url, data, modifyConfig(config, this.$_AxiosVue_cancelTokenSource))
  }
}

//
export default {
  install(Vue, options) {
    // キャンセル動作mixinを適用する
    Vue.mixin({

      beforeDestroy() {
        if(this.$_AxiosVue_cancelTokenSource != null) {
          this.$_AxiosVue_cancelTokenSource.cancel()
        }
      },

      methods: {
        // リクエスト自動キャンセル
        axiosAutoCancel() {
          this.$_AxiosVue_cancelTokenSource = axios.CancelToken.source()
        },

        // リクエストメソッド
        ...request_methods
      },
    })
  },

  setLoadingStateCallback(callback) {
    loadingStateCallback = callback
  },
}

// エラーメッセージ取得
window.axiosErrMsg = function(error) {
  console.error(error)
  if(error == null || error.response == null) {
    return '通信エラーが発生しました'
  }
  if(error.response.status === 422 && error.response.data && error.response.data.alert) {
    return error.response.data.alert
  }
  if(Vue.config.devtools) {
    return 'サーバーエラー status=' + error.response.status + ' content=' + JSON.stringify(error.response.data).substring(0, 1000)
  }
  if(error.response.status === 429) {
    return 'アクセス頻度が制限を超えました。1分以上待ってから、再度アクセスしてください'
  }
  return 'サーバーエラーが発生しました'
}
