
// 行の更新
function updateRow(table, id, row) {
  const i = table.findIndex(r => r.id === id)
  if(i !== -1) {
    table.splice(i, 1, row)
  }
}

// 行の削除
function deleteRows(table, ids) {
  for(const id of ids) {
    const i = table.findIndex(r => r.id === id)
    if(i !== -1) {
      table.splice(i, 1)
    }
  }
}

import {decompactRows} from 'lib/decompact-rows'

export default function(api) {
  return {
    namespaced: true,

    state() {
      return {
        table: [],
      }
    },

    mutations: {
      // テーブルの取得
      updateTable(state, table) {
        state.table = table
      },

      // 行の新規作成
      storeRow({table}, row) {
        table.push(row)
      },

      // 行の更新
      updateRow({table}, {id, row}) {
        updateRow(table, id, row)
      },

      // 行の削除
      deleteRows({table}, ids) {
        deleteRows(table, ids)
      },
    },

    actions: {
      // テーブルの取得
      index({commit}) {
        return new Promise((resolve, reject) => {
          axios.get(api)
          .then(response => {
            commit('updateTable', decompactRows(response.data))
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
        })
      },

      // 行の新規作成
      store({commit}, row) {
        return new Promise((resolve, reject) => {
          axios.post(api, row)
          .then(response => {
            commit('storeRow', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
        })
      },

      // 行の更新
      update({commit}, {id, row}) {
        return new Promise((resolve, reject) => {
          axios.put(api + '/' + id, row)
          .then(response => {
            commit('updateRow', {id, row: response.data})
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
        })
      },

      // 行の削除
      delete({commit}, ids) {
        return new Promise((resolve, reject) => {
          if(!Array.isArray(ids)) {
            ids = [ids]
          }
          let request
          if(ids.length === 1) {
            request = axios.delete(api + '/' + ids[0])
          }
          else {
            request = axios.post(api + '/bulk_destroy', ids)
          }
          request
          .then(response => {
            commit('deleteRows', ids)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
        })
      },
    },
  }
}
