
<template lang="pug">
div
  DatabaseEdit(
    module="users"

    filter-props="name,user_id"
    root="users"
    :settings="settings"
    :model="model"
    label-width="140px"
  )
    //- 詳細フォームの編集項目
    el-form-item(prop="name" label="名前")
      el-input(v-model.trim="model.name" autofocus)

    el-form-item(prop="user_id" label="ユーザーID")
      el-input(v-model.trim="model.user_id")

    el-form-item(prop="password" label="パスワード")
      el-input(v-model.trim="model.password" type="password")

    el-form-item(prop="password_confirmation" label="パスワード(確認)")
      el-input(v-model.trim="model.password_confirmation" type="password")

    el-form-item(prop="role" label="権限")
      el-select(v-model="model.role" placeholder="選択してください")
        el-option(
          v-for="option in roleOptions"
          :key="option.value"
          :value="option.value"
          :label="option.label"
        )
</template>

<script>
import DatabaseEdit from 'lib/DatabaseEdit'

export default {
  components: {DatabaseEdit},

  data() {
    return {

      settings: {
        // 一覧テーブルのカラム
        columns: [{
          title: '名前',
          data: 'name',
          width: 400,
        }, {
          title: 'ユーザーID',
          data: 'user_id',
          width: 400,
        }, {
          title: '権限',
          data: 'role',
          width: 200,
          renderer: (instance, td, row, col, prop, value, cellProperties) => {
            td.className = 'htNoWrap'
            const f = this.roleOptions.find(e => e.value === value)
            td.innerHTML = (f ? f.label : '?')
            return td
          },
        }],
      },

      // 詳細フォームのプロパティ
      model: {
        name: '',
        user_id: '',
        password: '',
        password_confirmation: '',
        role: '',
      },

      // roleセレクターオプション
      roleOptions: [
        {value: 'admin', label: '管理者'},
        {value: 'user', label: '一般'},
        {value: 'readonly', label: '印刷のみ'},
        {value: 'photo', label: '撮影用'},
      ],
    }
  },

  mounted() {
    this.$store.commit('setTitle', 'マスター/ユーザー')
  },
}
</script>
