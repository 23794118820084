
<template lang="pug">
div
  template(v-if="disabled")
    slot
  template(v-else)
    div.DropFile__container(
      @dragenter="onDragEnter()"
      @dragover.prevent="onDragOver()"
      @dragleave="onDragLeave()"
      @drop.prevent="onDrop()"
    )
      slot
      div.DropFile__overlay(v-if="dragover")
</template>

<script>
export default {
  props: {
    disabled: {default: false},
  },

  data() {
    return {
      dragover: false,
    }
  },

  methods: {
    // ファイルなどをドロップする要素が子要素を持っていても正しくホバーエフェクトを表示する方法
    // 参考: https://qiita.com/sounisi5011/items/dc4878d3e8b38101cf0b

    onDragEnter() {
      this.dragToInner = true
    },

    onDragOver() {
      this.dragToInner = false
      this.dragover = true
    },

    onDragLeave() {
      if(!this.dragToInner) {
        this.dragover = false
      }
      this.dragToInner = false
    },

    onDrop() {
      this.dragover = false
      this.$emit('drop', event.dataTransfer.files);
    },
  },
}
</script>

<style lang="sass">
.DropFile__container
  position: relative
.DropFile__overlay
  background-color: black
  bottom: 0
  left: 0
  opacity: .5
  position: absolute
  right: 0
  top: 0
</style>
