
<template lang="pug">
div
  ItemsList(
    ref="list"
    :unlisten="createFormVisible || editFormVisible"
    @selection="onUpdateSelection"
    @select="onSelectRow"
  )
    el-dropdown(
      trigger="click"
      @command="onClickExportDropdown"
    )
      el-button.no-focus(
        size="small"
        type="success"
        :disabled="selection == null"
      ) エクスポート&nbsp;
        i.el-icon-caret-bottom
      el-dropdown-menu(slot="dropdown")
        el-dropdown-item.right(
          command="pdf"
          :disabled="selection == null"
        ) 印刷

        el-dropdown-item.right(
          command="code-pdf"
          :disabled="selection == null"
        ) 品番印刷

        el-dropdown-item.right(
          command="csv"
          :disabled="selection == null"
        ) CSV

        el-dropdown-item.right(
          v-if="showArchived"
          command="unarchive"
          :disabled="selection == null"
        ) アーカイブ済を解除

        el-dropdown-item.right(
          v-else
          command="archive"
          :disabled="selection == null"
        ) アーカイブ済にする

    el-button.no-focus(
      size="small"
      type="danger"
      :disabled="selection == null"
      icon="delete"
      @click="onClickDelete"
    ) 削除

    el-button.no-focus(
      size="small"
      type="primary"
      icon="plus"
      @click="onClickCreate"
    ) 新規作成

  ItemsFormCreate(
    ref="formCreate"
    :visible.sync="createFormVisible"
    @create="onCreateItem"
  )

  ItemsFormEdit(
    ref="formEdit"
    :visible.sync="editFormVisible"
    @delete="onDeleteItem"
    @change="onChangeItem"
  )

  VueFullScreenFileDrop(
    v-if="!createFormVisible && !editFormVisible"
    text="商品情報をインポート"
    @drop="onDropFile"
  )
</template>

<script>
const FileSaver = require('file-saver')
import * as Helpers from 'lib/Helpers'

import VueFullScreenFileDrop from 'vue-full-screen-file-drop'
import 'vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css'

import ItemsList from './ItemsList'
import ItemsFormCreate from './ItemsFormCreate'
import ItemsFormEdit from './ItemsFormEdit'
import { openCsvExportConfirm } from '@/lib/CsvExportConfirm.vue'

export default {
  components: {VueFullScreenFileDrop, ItemsList, ItemsFormCreate, ItemsFormEdit},

  data() {
    return {
      selection: null,
      createFormVisible: false,
      editFormVisible: false,
    }
  },

  computed: {
    showArchived() {
      return this.$route.query.a == true
    },
  },

  mounted() {
    this.$store.commit('setTitle', '商品情報')
  },

  methods: {
    // 新規作成ボタン押下
    onClickCreate() {
      this.$refs.formCreate.open()
    },

    // 削除ボタン押下
    onClickDelete() {
      if(this.selection) {
        this.$confirm(
          '選択中の' + this.selection.length + '件の商品情報を削除します。よろしいですか？',
          undefined, {type: 'warning'})
        .then(() => {
          this.axiosPost('/api/items/bulk_destroy', this.selection)
          // 送信成功
          .then(response => {
            // this.settings.data.splice(begin, count)
            // this.$refs.hot.table.deselectCell()
            this.$refs.list.refresh() // 取り合えずリロード！
          })
          // 送信失敗
          .catch(error => {
            if(error) { // AxiosVue: nullの場合は処理不要
              this.$alert(axiosErrMsg(error), {type: 'error'})
            }
          })
        })
        .catch(() => {})
      }
    },

    // エクスポートドロップダウンコマンド
    onClickExportDropdown(command) {
      switch(command) {

      case 'pdf':
        this.onClickPDF()
        break

      case 'code-pdf':
        this.onClickCodePDF()
        break

      case 'csv':
        this.onClickCSV()
        break

      case 'archive':
        this.onClickArchive()
        break

      case 'unarchive':
        this.onClickUnarchive()
        break
      }
    },

    // PDFエクスポートボタン押下
    onClickPDF() {
      if(this.selection) {
        this.$confirm(
          '選択中の' + this.selection.length + '件の商品情報をPDFとして開きます。よろしいですか？',
          undefined, {type: 'warning'})
        .then(() => {
          Helpers.postJsonAndOpen('/pdf', this.selection)

          // チェック処理
          this.$confirm(
            '今開いた商品情報をPDF出力済にしますか？',
            undefined, {type: 'info'})
          .then(() => {
            this.axiosPost('/api/print/check', this.selection)
            .then(response => {
              this.$refs.list.refresh() // 取り合えずリロード！
            })
            .catch(error => {
              if(error) { // AxiosVue: nullの場合は処理不要
                this.$alert(axiosErrMsg(error), {type: 'error'})
              }
            })
          })
          .catch(() => {})
        })
        .catch(() => {})
      }
    },

    // 品番印刷エクスポートボタン押下
    onClickCodePDF() {
      if(this.selection) {
        this.$confirm(
          '選択中の' + this.selection.length + '件の商品番号をPDFとして開きます。よろしいですか？',
          undefined, {type: 'warning'})
        .then(() => {
          Helpers.postJsonAndOpen('/pdf/code', this.selection)
        })
        .catch(() => {})
      }
    },

    // CSVエクスポートボタン押下
    async onClickCSV() {
      if(this.selection) {
        try {
          let warnText = ''
          const warn = await this.axiosPost('/api/export/warning', this.selection)
          if (warn.data.length !== 0) {
            warnText = '警告: 商品情報にマスター/カテゴリに存在しないカテゴリが含まれています。\n' + warn.data.slice(0, 10).map((x) => x + '\n')
            if (10 < warn.data.length) warnText += `...全${warn.data.length}件\n`
            warnText += '\n'
          }

          await openCsvExportConfirm({
            text: warnText + '選択中の' + this.selection.length + '件の商品情報をCSVとしてダウンロードします。よろしいですか？',

          })

          const csv1 = await this.axiosPost('/api/export/item.csv', this.selection, {responseType: 'blob'})
          FileSaver.saveAs(csv1.data, 'item.csv')

          const csv2 = await this.axiosPost('/api/export/item-cat.csv', this.selection, {responseType: 'blob'})
          FileSaver.saveAs(csv2.data, 'item-cat.csv')

          const csv3 = await this.axiosPost('/api/export/standard.csv', this.selection, {responseType: 'blob'})
          FileSaver.saveAs(csv3.data, '標準.csv')

          await this.$confirm(
            '今ダウンロードした商品情報をCSV出力済にしますか？',
            undefined, {type: 'info'}
          )

          try {
            await this.axiosPost('/api/export/check', this.selection)
          } catch(e) {
            if(e) { // AxiosVue: nullの場合は処理不要
              this.$alert(axiosErrMsg(e), {type: 'error'})
            }
          }
          this.$refs.list.refresh() // 取り合えずリロード！
        } catch {}
      }
    },

    // アーカイブ済にする
    async onClickArchive() {
      if(this.selection) {
        try {
          await this.$confirm(
            '選択中の' + this.selection.length + '件の商品情報をアーカイブ済にします。よろしいですか？',
            undefined, {type: 'warning'}
          )
        }
        catch(e) {return}

        await this.axiosPut('/api/archive/items', this.selection)
        await this.$refs.list.refresh() // 取り合えずリロード！
      }
    },

    // アーカイブ済を解除
    async onClickUnarchive() {
      if(this.selection) {
        try {
          await this.$confirm(
            '選択中の' + this.selection.length + '件の商品情報のアーカイブ済を解除します。よろしいですか？',
            undefined, {type: 'warning'}
          )
        }
        catch(e) {return}

        await this.axiosPut('/api/unarchive/items', this.selection)
        await this.$refs.list.refresh() // 取り合えずリロード！
      }
    },

    // テーブルの行の選択状態の更新
    onUpdateSelection(selection) {
      this.selection = selection
    },

    // 商品情報がダブルクリックされた時
    onSelectRow(item) {
      this.$refs.formEdit.open(item)
    },

    // 商品情報が新規作成された
    onCreateItem(item) {
      this.$refs.list.refresh()
    },

    // 商品情報が削除された
    onDeleteItem(id) {
      this.$refs.list.delete(id)
    },

    // 商品情報が更新された
    onChangeItem(item) {
      this.$refs.list.update(item)
    },

    // 画面へのファイルドロップ、CSVインポート
    onDropFile(formData, files) {
      if(files[0]?.name == null) { // 長さが0のfilesが送られてくることがある
        return
      }

      if(1 < files.length) {
        this.$alert('ファイルのドロップは1度に1つまででお願いいたします。')
        return
      }

      const upload = formData.get('upload') // 未対応のオブジェクト
      if(!upload.type) {
        this.$alert('未対応のファイル形式です')
        return
      }

      console.log('file mime:', upload.type)

      this.axiosPost('/api/items/bulk_store', formData)
      .then(response => {
        this.$alert(
          response.data.count + '件の商品情報を読み込みました',
          'インポート', {type: 'success'})
        .then(() => {
          this.$refs.list.refresh() // 取り合えずリロード！
        })
        .catch(() => {})
      })
      .catch(error => {
        if(error) { // AxiosVue: nullの場合は処理不要
          let text
          if(error.response?.status === 422) {
            text = '未対応のファイル形式です'
          }
          else {
            text = axiosErrMsg(error)
          }
          this.$alert(text, 'インポート', {type: 'error'})
          .catch(() => {})
        }
      })
    },
  },
}
</script>
