
import Vuex from 'vuex'

import DatabaseTable from './modules/DatabaseTable'
import rakudir from './modules/Rakudir'
import preferences from './modules/Preferences'
import {decompactRows} from 'lib/decompact-rows'

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    types:                 DatabaseTable('/api/types'),
    brands:                DatabaseTable('/api/brands'),
    categories:            DatabaseTable('/api/categories'),
    description_templates: DatabaseTable('/api/description_templates'),
    size_templates:        DatabaseTable('/api/size_templates'),
    rules:                 DatabaseTable('/api/rules'),
    users:                 DatabaseTable('/api/users'),
    rakudir,
    preferences,
  },

  state() {
    return {
      loading: false,
      loadingText: null,
      loadingCount: 0,

      title: '商品情報',

      user: {},
      revision: '',
      environment: '',
    }
  },

  mutations: {
    // ローディングの開始
    beginLoading(state, loadingText) {
      state.loading = ++state.loadingCount != 0
      state.loadingText = loadingText
    },

    // ローディングの終了
    endLoading(state) {
      state.loading = --state.loadingCount != 0
    },

    // ナビゲーションバータイトルの更新
    setTitle(state, value) {
      state.title = value
    },

    // 初期データの更新
    loadFirst(state, first) {
      state.user        = first.user
      state.revision    = first.revision
      state.environment = first.environment
    },
  },

  actions: {
    // 初期データの更新
    loadFirst({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/api/first')
        .then(response => {
          const data = response.data

          commit('loadFirst',                         data)
          commit('types/updateTable',                 decompactRows(data.types))
          commit('brands/updateTable',                decompactRows(data.brands))
          commit('categories/updateTable',            decompactRows(data.categories))
          commit('description_templates/updateTable', decompactRows(data.description_templates))
          commit('size_templates/updateTable',        decompactRows(data.size_templates))
          commit('rules/updateTable',                 decompactRows(data.rules))
          commit('rakudir/updateTable',               decompactRows(data.rakudir))
          commit('preferences/updateTable',           data.preferences)

          if(data.users != null) {
            commit('users/updateTable', decompactRows(data.users))
          }

          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
      })
    },

    // 説明定形文を更新する
    async loadDescriptionTemplates({commit}) {
      const res = await axios.get('/api/description_templates', {disableLoadingState: true})
      commit('description_templates/updateTable', decompactRows(res.data))
    },

    // 自動更新ルールを更新する
    async loadRules({commit}) {
      const res = await axios.get('/api/rules', {disableLoadingState: true})
      commit('rules/updateTable', decompactRows(res.data))
    },
  },

  strict: debug,
})

// AxiosVue ローディングインジケーター
import AxiosVue from 'lib/AxiosVue.js'
AxiosVue.setLoadingStateCallback(state => {
  store.commit(state ? 'beginLoading' : 'endLoading')
})

//
export default store
