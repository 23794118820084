
<template lang="pug">
div
  slot
</template>

<script>
export default {
  mounted() {
    window.addEventListener('dragover', this.onDragPrevent);
    window.addEventListener('drop', this.onDragPrevent);
  },

  beforeDestroy() {
    window.removeEventListener('dragover', this.onDragPrevent);
    window.removeEventListener('drop', this.onDragPrevent);
  },

  methods: {
    onDragPrevent(e) {
      e.preventDefault()
    },
  },
}
</script>
