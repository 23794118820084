
<template lang="pug">
div.app-progress-check
  el-checkbox(
    v-model="check"
    :disabled="disabled"
    @change="change"
  ) {{content}}
</template>

<script>
export default {
  props: {
    msg: null,
    value: {required: true},
    date: {required: true},
    user: {required: true},
    disabled: {default: false},
  },

  data() {
    return {
      check: this.value,
    }
  },

  computed: {
    content() {
      if(this.date != null && this.user != null) {
        return moment(this.date).format('MM/DD') + ' ' + this.user
      }
      return ''
    },
  },

  watch: {
    msg() {
      this.open()
    },

    value(value) {
      this.check = value
    },
  },

  methods: {
    open() {
      //
    },

    change(value) {
      this.$emit('update:value', this.check)
      this.$emit('update:date', moment().format('YYYY-MM-DD HH:mm:ss'))
      this.$emit('update:user', this.$store.state.user.name)
    },
  },
}
</script>
