
import './bootstrap'

import Vue from 'vue'

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import App    from 'App'
import router from 'router'
import store  from 'store'

import 'sass/app.sass'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
