
<template lang="pug">
div
  ItemsList(
    ref="list"
    :unlisten="createFormVisible || editFormVisible"
    @selection="onUpdateSelection"
    @select='onSelectRow'
  )
    el-button.no-focus(
      size="small"
      type="primary"
      icon="plus"
      @click="onClickCreate"
    ) 新規作成

  ItemsFormCreate(
    ref="formCreate"
    :visible.sync="createFormVisible"
    @create="onCreateItem"
  )

  ItemsFormEdit(
    ref="formEdit"
    :visible.sync="editFormVisible"
    @change="onChangeItem"
  )
</template>

<script>
import ItemsList from './ItemsList'
import ItemsFormCreate from './ItemsFormCreate'
import ItemsFormEdit from './ItemsFormEdit'

export default {
  components: {ItemsList, ItemsFormCreate, ItemsFormEdit},

  data() {
    return {
      selection: null,
      createFormVisible: false,
      editFormVisible: false,
    }
  },

  methods: {

    // 新規作成ボタン押下
    onClickCreate() {
      this.$refs.formCreate.open()
    },

    // テーブルの行の選択状態の更新
    onUpdateSelection(selection) {
      this.selection = selection
    },

    // 商品情報がダブルクリックされた時
    onSelectRow(item) {
      this.$refs.formEdit.open(item)
    },

    // 商品情報が新規作成された
    onCreateItem(item) {
      this.$refs.list.refresh()
    },

    // 商品情報が更新された
    onChangeItem(item) {
      this.$refs.list.update(item)
    },
  },
}
</script>
