
<template lang="pug">
div
  DialogForm(
    ref="form"
    :visible.sync="visible_"
    size="large"
    :model="model"
    labelWidth="150px"
    :hideDelete="true"
    @submit="onSubmit"
    @close="onClose"
  )
    el-form-item(prop="code" label="商品コード")
      el-input(v-model.trim="model.code")

    el-form-item(prop="color_code" label="カラーコード")
      el-input(v-model.trim="model.color_code" placeholder="カンマ区切りで複数指定可能")

    el-form-item(prop="size_code" label="サイズコード")
      el-input(v-model.trim="model.size_code" placeholder="カンマ区切りで複数指定可能")

    el-form-item(prop="price" label="税抜参考上代単価")
      el-input(v-model.trim="model.price")
</template>

<script>
import DialogForm from 'lib/DialogForm'

export default {
  components: {DialogForm},

  props: {
    visible: Boolean,
  },

  data() {
    return {
      visible_: false,
      model: {
        code: '',
        color_code: '',
        size_code: '',
        price: '',
      },
    }
  },

  watch: {
    visible(value) {this.visible_ = value},
    visible_(value) {this.$emit('update:visible', value)},
  },

  created() {
    this.axiosAutoCancel()
    this.modelJsonInitial = JSON.stringify(this.model)
  },

  methods: {
    open() {

      for(const [key, value] of Object.entries(JSON.parse(this.modelJsonInitial))) {
        this.$set(this.model, key, value)
      }
      this.visible_ = true
    },

    onSubmit() {
      if(JSON.stringify(this.model) === this.modelJsonInitial) {
        this.visible_ = false
        return
      }
      this.axiosPost('/api/items', this.model)
      .then(response => {
        this.$emit('create')
        this.visible_ = false
      })
      .catch(error => {
        if(this.$refs.form.processLaravelError(error)) { // laravelのvalidatorエラーを処理
          this.$alert(axiosErrMsg(error), {type: 'error'}) // または通信エラー
        }
        this.visible_ = true
      })
    },

    onClose() {
      this.$refs.form.clearValidation()
    },
  },
}
</script>
