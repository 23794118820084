
import {mapState, mapActions} from 'vuex'
import {isArray, isString, intersection} from 'lodash-es'
import {getRuleDescriptionTemplate} from 'lib/RuleDescription'

export default {

  computed: {
    ...mapState({
      rules:       state => state.rules.table,
      preferences: state => state.preferences.table,
    }),
  },

  methods: {
    ...mapActions(['loadRules']),

    async onClickRule() {
      await this.loadRules()

      const result = []

      // 大きいサイズ (〜cmまでご用意を挿入)
      if(matchSizeName(this.preferences?.large_keywords, this.model.sizes)) {

        // サイズの項目に"身幅"があれば、その最大値を2倍して、キャッチコピーに"胸囲***cmまでご用意"と先頭に自動入力する
        const width = getSizeMax('身幅', this.model.sizes, this.model.size_columns)
        if(width !== false) {
          const text = '胸囲' + (width * 2) + 'cmまでご用意'
          if(this.model.catchcopy2 == null || !this.model.catchcopy2.includes(text)) {
            this.model.catchcopy2 = appendString(text, this.model.catchcopy2)
          }
          result.push('キャッチコピー"' + text + '"')
        }

        // サイズの項目に"ウエスト"があれば、その最大値を取得して、キャッチコピーに"ウエスト***cmまでご用意"と先頭に自動入力する
        const waist = getSizeMax('ウエスト', this.model.sizes, this.model.size_columns)
        if(waist !== false) {
          const text = 'ウエスト' + waist + 'cmまでご用意'
          if(this.model.catchcopy2 == null || !this.model.catchcopy2.includes(text)) {
            this.model.catchcopy2 = appendString(text, this.model.catchcopy2)
          }
          result.push('キャッチコピー"' + text + '"')
        }
      }

      // 大きいサイズ (「大きいサイズ有り」をチェック)
      if(matchSizeName(this.preferences?.large_keywords2, this.model.sizes)) {

        // remarks"大きいサイズ有り"にチェックを入れる
        if(this.remarkChecks.hasOwnProperty('大きいサイズ有り')) {
          this.remarkChecks['大きいサイズ有り'] = true
          result.push('"大きいサイズ有り"をチェックしました')
        }
      }

      // 小さいサイズ (「小さいサイズ有り」をチェック)
      if(matchSizeName(this.preferences?.small_keywords, this.model.sizes)) {

        // remarks"小さいサイズ有り"にチェックを入れる
        if(this.remarkChecks.hasOwnProperty('小さいサイズ有り')) {
          this.remarkChecks['小さいサイズ有り'] = true
          result.push('"小さいサイズ有り"をチェックしました')
        }
      }

      // 自動記入
      let nomatch = true
      for(const rule of this.rules) {
        const input = rule.body?.input
        if(input != null && matchRule(input, this)) {
          const match = rule

          const output = match.body?.output
          if(output != null) {

            // 説明文1〜6の自動記入
            const descriptionTemplates = getRuleDescriptionTemplate(output, this.description_templates)
            for(const [prop, template] of Object.entries(descriptionTemplates)) {
              const text = template.text.replace(/\r\n/g, "\n")
              if(this.model[prop] == null || !this.model[prop].includes(text)) {
                this.model[prop] = appendString(text, this.model[prop])
              }
            }

            // カテゴリーの自動記入
            for(const cat_id of output.category) {
              const cat = this.categories.find(x => x.id === cat_id)
              if(cat != null) {
                const typed = this.categoryTyped[cat.type]
                if(typed != null && typed.every(x => x !== cat.name)) {
                  typed.push(cat.name)
                }
              }
            }

            // 備考の記入
            if (output.remarks != null) {
              const remarks = output.remarks.split('|').map(x => x.trim()).filter(x => x !== '')
              for (const remark of remarks) {
                if (this.remarks.includes(remark)) {
                  this.remarkChecks[remark] = true
                }
              }
            }
          }

          result.push('自動記入ルール"' + match.name + '"を適用しました')
          nomatch = false
        }
      }

      if(nomatch) {
        result.push('マッチする自動記入ルールはありません')
      }

      const h = this.$createElement
      this.$msgbox({
        type: 'info',
        title: '自動記入',
        message: h('div', result.map(x => h('div', x))),
      })
    },
  },
}

// 自動記入検索
function matchRule(rule, _this, root = false) {
  const data = _this.model
  if(rule.children != null) {
    if(isArray(rule.children)) {
      switch(rule.type) {

        case 'and':
          return rule.children.length === 0 ? root
            : rule.children.every(child => matchRule(child, _this, true))

        case 'or':
          return rule.children.length === 0 ? root
            : rule.children.some(child => matchRule(child, _this, true))
      }
    }
  }
  else {
    switch(rule.type) {

      case 'type_id':     // 種別
        return matchArray(rule.type_id, data.type_id)

      case 'gender':      // 性別
        return matchArray(rule.gender, data.gender)

      case 'brand':       // ブランド
        return matchString(rule.brand, _this.brandName)

      case 'brand_exact': // ブランド完全一致
        return matchStringExact(rule.brand, _this.brandName)

      case 'name':        // 商品名
        return matchString(rule.name, data.name)

      case 'function':    // 機能
        return matchString(rule.function, data.function)

      case 'catchcopy':   // キャッチコピー
        return matchString(rule.catchcopy, data.catchcopy)
          || matchString(rule.catchcopy, data.catchcopy2)
          || matchString(rule.catchcopy, data.catchcopy3)

      case 'size_name':   // サイズ名
        return matchSizeName(rule.size_name, data.sizes)

      case 'size_value':  // サイズ値
        return matchSizeValue(rule, data.sizes, data.size_columns)
    }
  }
  return false
}

// 種別, 性別
function matchArray(rule, value) {
  return isArray(rule)
    && rule.some(x => x === value)
}

// ブランド, 商品名, 機能, キャッチコピー
function matchString(rule, text) {
  return isString(rule) && isString(text)
    && split(rule).some(x => text?.trim().includes(x))
}

// ブランド, 商品名, 機能, キャッチコピー 完全一致
function matchStringExact(rule, text) {
  return isString(rule) && isString(text)
    && split(rule).some(x => text?.trim() === x)
}

// サイズ名
function matchSizeName(rule, sizes) {
  return isString(rule) && isArray(sizes)
    && intersection(split(rule), sizes.map(x => x.name?.trim())).length !== 0
}

// サイズ値
function matchSizeValue({row, col, min, max}, sizes, columns) {
  if(!(isString(row) && isString(col) && isArray(sizes) && isArray(columns))) return false

  min = getNumber(min)
  max = getNumber(max)

  if(min == null && max == null) return false

  const indices = []
  const cols = split(col)
  for(const [i, {label}] of columns.entries()) {
    if(cols.some(x => label?.trim().includes(x))) {
      indices.push(i)
    }
  }

  if(indices.length === 0) return false

  const rows = split(row)
  for(const {name, values} of sizes) {
    if(rows.some(x => x === name?.trim())) {
      if(indices.map(x => values[x]).filter(Boolean).some(x =>
        (min == null || min <= x) && (max == null || x <= max)
      )) return true
    }
  }

  return false
}

// サイズの項目にcolが含まれる場合、その中で一番おおきい数字を取り出す
function getSizeMax(col, sizes, columns) {
  if(!(isString(col) && isArray(sizes) && isArray(columns))) return false

  const index = columns.findIndex(x => x.label?.trim() === col.trim())

  if(index === -1) return false

  let max = Number.NEGATIVE_INFINITY
  for(const {values} of sizes) {
    const value = parseFloat(values[index], 10)
    if(isFinite(value) && max < value) {
      max = value
    }
  }

  return isFinite(max) ? max : false
}

// |で分割
function split(text) {
  return text?.split('|').map(x => x.trim()).filter(Boolean)
}

// 数値を洗う
function getNumber(value) {
  value = parseFloat(value)
  return isFinite(value) ? value : null
}

// 文字列を連結する
function appendString(text0, text1) {
  if(!isString(text0) || text0 === '') return !isString(text1) ? '' : text1
  if(!isString(text1) || text1 === '') return text0
  return text0 + ' ' + text1
}
