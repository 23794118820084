<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    @close="close"
  >
    <div v-html="escapedText"></div>

    <template v-slot:footer>
      <el-button @click="close">キャンセル</el-button>
      <el-button type="primary" @click="confirm">はい</el-button>
    </template>
  </el-dialog>
</template>

<script>
const CsvExportConfirm = {
  props: {
    title: { type: String, default: '' },
    text: { type: String, default: '' },

    resolve: Function,
    reject: Function,
  },

  data() {
    return {
      visible: true,
    }
  },

  computed: {
    escapedText() {
      return escapeHTML(this.text).replaceAll('\n', '<br />')
    },
  },

  methods: {
    confirm() {
      this.resolve()
      this.destroy()
    },

    close() {
      this.reject()
      this.destroy()
    },

    destroy() {
      this.$destroy()
      this.$el.parentNode.removeChild(this.$el)
    },
  },
}
export default CsvExportConfirm

function escapeHTML(str) {
  const div = document.createElement('div');
  div.appendChild(document.createTextNode(str));
  return div.innerHTML;
}

import Vue from 'vue'

export function openCsvExportConfirm(propsData) {
  return new Promise((resolve, reject) => {
    const CsvExportConfirmConstructor = Vue.extend(CsvExportConfirm)
    const instance = new CsvExportConfirmConstructor({
      propsData: {
        ...propsData,
        resolve,
        reject,
      },
    })
    instance.$mount()
    document.body.appendChild(instance.$el)
  })
}
</script>
