import DialogImportError from './DialogImportError.vue'
import Vue from 'vue'

export function openDialogImportError(title, errors) {
  const div = document.createElement('div')
  const instance = new Vue(DialogImportError).$mount(div)
  instance.title = title
  instance.errors = errors
  document.body.appendChild(instance.$el)
}
