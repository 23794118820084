
<template lang="pug">
.dummy-input(
  :class="{'dummy-input--disabled': disabled}"
) {{value}}
</template>

<script>
export default {
  name: 'DummyInput',

  props: {
    value:    String,
    disabled: Boolean,
  },
}
</script>

<style lang="sass">
.dummy-input
  align-items: center
  box-sizing: border-box
  display: flex
  font: 400 14px Arial
  height: 100%
  padding: 3px 18px

.dummy-input--disabled
  background-color: #eef1f6
  color: #bbb
</style>
