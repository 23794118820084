
import {decompactRows} from 'lib/decompact-rows'

export default {
  namespaced: true,

  state() {
    return {
      table: [],
    }
  },

  mutations: {
    // テーブルの更新
    updateTable(state, table) {
      state.table = table
    },
  },

  actions: {
    // テーブルの更新
    index({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/api/rakudir')
        .then(response => {
          commit('updateTable', decompactRows(response.data))
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
      })
    },
  },
}
